import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import GuiList from '../../components/gui/list';

function displayImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

@withText(props => ({
    titleText: <Text id='race-class-contestants.title'>Race Contestants</Text>,
}))
@observer
class RaceClassContestantList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    viewRaceClassContestant = e => {
        const { view } = this.props;
        if (view) {
            return view(e);
        }
        const { id, title } = e.target.closest('.box-line').dataset;
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMedium } = appState;
        // console.log('viewPart', { id });
        appState.openDrawer('viewRaceClassContestant', {
            id,
            // title,
            height: drawerHeightMedium,
            editFunction: this.editMediaViaView,
            callback: () => {
                appState.toggleDrawer();
                // this.loadAll();
            },
        }, drawerLevel + 1);
    }

    render() {
        const { appState, userStore, raceStore } = this.props.stores;
        const { isDevelopment, path, apiServer } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { titleText } = this.props;
        const {
            raceClassContestants,
            classNames = '',
            hideEmpty,
            title = titleText,
            editElement,
            showCevron,

            rightBoxField = 'distance',
            rightBoxFieldValue = e => e,
            rightBoxFieldPostfix = 'km',
            rightBoxFieldPostfixField = 'startDate',
            rightBoxFieldPostfixValue = e => util.formatDate(e, { year: 'numeric', locale: 'nb-NO' }, true),
            help,
        } = this.props;

        return (<>
            <GuiList
                stores={this.props.stores}
                holdingStore={raceStore}
                classNames={classNames}
                view={this.viewRaceClassContestant}
                title={title}
                list={raceClassContestants}
                qty={e => e.bib > 0 ? e.bib : undefined}
                elTitle={e => `${e.firstname} ${e.lastname}`}
                elInfo1={e => e.birth ? util.age(e.birth) : ''}
                elInfo2={e => e.sportsClub}
                elInfo3={e => ''}
                hideEmpty={hideEmpty}
                editElement={editElement}
                showEmptyImage={false}
                showCevron={showCevron}
                rightBoxField={rightBoxField}
                rightBoxFieldValue={rightBoxFieldValue}
                rightBoxFieldPostfix={rightBoxFieldPostfix}
                imgHeight='60px'
                help={help}
            />
        </>);
    }
}

export default RaceClassContestantList;
