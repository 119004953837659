import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import md5 from 'crypto-js/md5';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import CalendarInline from '../../components/calendar/new';

function getNextQuarterHour(addMinutes = 0) {
    const now = new Date();
    let minutes = now.getMinutes();
    let quarterHours = Math.ceil(minutes / 15) * 15;
    if (quarterHours === 60) { // If it's on the hour, move to the next hour
        now.setHours(now.getHours() + 1);
        quarterHours = 0;
    }
    now.setMinutes(quarterHours);
    now.setSeconds(0);
    now.setMilliseconds(0);

    if (addMinutes) {
        now.setMinutes(now.getMinutes() + addMinutes);
    }
    return util.isoDate(now);
}

@withText(props => ({
    calendarEventNumber: <Text id='input.calendarEventNumber-help'>calendarEventNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class NewCalendarEvent extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('calendarEvent'),
        };
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id, calendarType, skipAnimal } = props;
        const { calendarEventStore } = this.props.stores;
        if (emptyFirst) {
            calendarEventStore.updateKeyValue('calendarEvent', {});
        }
        // console.log('loadAll', { id, calendarType, skipAnimal })
        if (id) {
            await calendarEventStore.load(id);
        }
        await this.loadCalendarType(props);
        this.insertAnimal();
        this.insertDates();
    }

    loadCalendarType = async (props = this.props) => {
        const { id, calendarType = 'booking', calendarId } = props;
        const { calendarStore, calendarEventStore } = this.props.stores;
        await calendarStore.load({ query: { type: calendarType } });

        const { calendars } = calendarStore;
        const calendar = calendars[0];
        calendarEventStore.updateObjectKeyValue('newCalendarEvent', 'calendarId', calendarId || calendar.calendarId);
        calendarEventStore.updateKeyValue('calendars', calendars);
    }

    insertAnimal = (props = this.props) => {
        const { animals = [], calendarType, skipAnimal } = props;
        const { calendarEventStore } = this.props.stores;

        const calendarAnimals = animals.map((animal) => ({
            md5: md5(JSON.stringify(animal)).toString(),
            id: animal.id,
            name: animal.name,
            birth: animal.birth,
            breed: animal.breed,
            species: animal.species,
        }));
        if (skipAnimal) {
            calendarEventStore.updateObjectKeyValue('newCalendarEvent', 'animals', []);
            calendarEventStore.updateObjectKeyValue('newCalendarEvent', 'title', `${calendarType === 'booking' ? 'Ny time' : 'Innkalling'}`);
        } else {
            calendarEventStore.updateObjectKeyValue('newCalendarEvent', 'animals', calendarAnimals);
            calendarEventStore.updateObjectKeyValue('newCalendarEvent', 'title', `${calendarType === 'booking' ? '' : 'Innkalling for'} ${animals.map(animal => `${animal.name}`).join(', ')}`);
        }
    }

    insertDates = (props = this.props) => {
        const { startDate = getNextQuarterHour(), endDate = getNextQuarterHour(15), calendarType, calendarId } = props;
        const { calendarEventStore } = this.props.stores;

        let oneYearFromNow;
        if (calendarType === 'notification') {
            oneYearFromNow = util.addDays(startDate, 365);
            const oneYearFromNowPluss1Hour = util.addSeconds(oneYearFromNow, 900);
            calendarEventStore.updateObjectKeyValue('newCalendarEvent', 'start', oneYearFromNow);
            calendarEventStore.updateObjectKeyValue('newCalendarEvent', 'end', oneYearFromNowPluss1Hour);
        } else {
            calendarEventStore.updateObjectKeyValue('newCalendarEvent', 'start', startDate);
            calendarEventStore.updateObjectKeyValue('newCalendarEvent', 'end', endDate);
        }
    }

    createCalendarEvent = async () => {
        const { callback = () => {}, installation } = this.props;
        const { calendarEventStore, installationStore } = this.props.stores;
        const { newCalendarEvent } = calendarEventStore;

        const response = await calendarEventStore.insert(newCalendarEvent);
        const calendarEvent = response?.data?.calendarEvent;

        calendarEventStore.updateKeyValue('newCalendarEvent', {});
        callback();
    }

    chooseItem = (e) => {
        const { id } = e.target.closest('.similar-item').dataset;
        const { calendarEventStore } = this.props.stores;
        const { foundList } = calendarEventStore;
        const item = foundList.find((item) => item.id === parseInt(id, 10));
        calendarEventStore.updateKeyValue('newCalendarEvent', {
            ...item,
        });
        calendarEventStore.resetSearch();
    }

    toggleSimilar = () => {
        const { showSimilar } = this.state;
        this.setState({
            showSimilar: !showSimilar,
        });
    }

    findAvailableTime = async () => {
        const { drawerLevel = 1 } = this.props;
		const { appState, saleStore } = this.props.stores;
		const { drawerHeightMedium } = appState;

		appState.openDrawerRight('calendar', {
			height: drawerHeightMedium,
            isNew: true,
            hideAdmin: true,
            callback: visitor => {
                appState.toggleDrawerRight(false, drawerLevel + 1);
                if (visitor) {
                    saleStore.updateObjectKeyValue('newSale', 'visitors', [visitor]);
                    setTimeout(() => {
                        this.loadAll(this.props, false);
                    }, 1000);
                }
            },
		}, drawerLevel + 2);
    }

    calendarSelectDateTime = ({ start, end, calendarId }) => {
        const { calendarEventStore } = this.props.stores;
        const { calendarEvent } = calendarEventStore;
        if (calendarEvent.id) {
            // console.log('existing calendarEvent');
            // console.log({ start, end, calendarId });
            calendarEventStore.save({
                start,
                end,
                calendarId,
            }, calendarEvent.id);
            calendarEventStore.updateField(calendarEvent.id, 'start', start);
            calendarEventStore.updateField(calendarEvent.id, 'end', end);
            calendarEventStore.updateField(calendarEvent.id, 'calendarId', calendarId);
        } else {
            calendarEventStore.updateObjectKeyValue('newCalendarEvent', 'start', start);
            calendarEventStore.updateObjectKeyValue('newCalendarEvent', 'end', end);
            calendarEventStore.updateObjectKeyValue('newCalendarEvent', 'calendarId', calendarId);
        }
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { week, isNew, drawerLevel, installation, lookupArray, startDate, id } = this.props;
        const { sections, showSimilar } = this.state;
        const { userStore, calendarEventStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { newCalendarEvent, foundList, foundListKeys } = calendarEventStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div
                    class='w-100 h-100'
                    style='
                        // max-width: 960px;
                        padding-top: 50px;
                        padding-bottom: 100px;
                    '
                >
                    <div class='font-weight-lighter px-3 mt-0 box-header'>
                        <Text id='calendarevent.title'>Kalender booking</Text>
                    </div>
                    <div class='w-100 d-flex flex-sm-row flex-column mt-0'>
                        <div class='w-100 d-flex flex-column'>
                            {showSimilar && foundList?.length > 0 && <>
                                <div class='w-100 d-flex flex-column justify-content-start'>
                                    <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                                        <div class='mx-4 font-weight-lighter px-3 py-1 text-muted' >
                                            <small class='text-uppercase'>
                                                <Text id='calendarEvent.similar'>Similar calendarEvents</Text>
                                            </small>
                                        </div>
                                        <div class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-4 d-flex flex-column px-0 overflow-auto`} style='font-size: 1.1em; border-radius: 15px; max-height: 35vh;'>
                                            {foundList.map((item, idx) => {
                                                return(<>
                                                    <div class='d-flex flex-row justify-content-between align-items-center'>
                                                        <div
                                                            class='flex-fill similar-item py-2 px-3'
                                                            data-id={item.id}
                                                            onClick={this.chooseItem}
                                                        >
                                                            {item.name}
                                                            <span class='font-weight-lighter text-muted ml-3'>{item.calendarEventNumber}</span>
                                                        </div>
                                                        {/* {item.images[0] && <>
                                                            <div>
                                                                <div class=''>
                                                                    <img src={`${item.images[0].s3MediumLink}`} class='rounded-lg img-fluid' style='max-height: 45px;' />
                                                                </div>
                                                            </div>
                                                        </>} */}
                                                    </div>
                                                </>);
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </>}

                            {/* <button type='button' class='btn btn-success btn-sm ' onClick={this.findAvailableTime}>
                                <i class='fa-solid fa-circle-info' /> <Text id='calendarEvent.findAvailableTime'>Find available time</Text>
                            </button> */}

                            <div class='w-100 d-flex flex-column justify-content-start'>
                                <div class='bg-light d-flex flex-column justify-content-start h-100 pt-0'>
                                    <FormFields
                                        stores={this.props.stores}
                                        sections={sections}
                                        holdingStore={calendarEventStore}
                                        objectName={isNew ? 'newCalendarEvent' : 'calendarEvent'}
                                        fields={['calendarId', 'title', 'body', 'start', 'end', 'animals', 'backgroundColor', 'skipReminders', 'hasArrived',
                                            'isRecurring', 'recurringStart', 'recurringEnd', 'recurringFrequency']}
                                        isNew={isNew}
                                        drawerLevel={drawerLevel}
                                        isAdmin={false}
                                        hideAdmin={true}
                                        lookupArray={lookupArray}
                                        rightHeader={isNew && foundList?.length > 0 ? <span class='text-success font-weight-bold'><i class='fa-solid fa-circle-info' /> Similar calendarEvents ({foundList.length})</span> : null}
                                        rightHeaderClick={this.toggleSimilar}
                                    />
                                </div>
                            </div>
                        </div>
                        <div class='w-100 d-flex flex-column'>
                            <CalendarInline
                                inputKey={`calendar-inline-${newCalendarEvent?.id}`}
                                key={`calendar-inline-${newCalendarEvent?.id}`}
                                stores={this.props.stores}
                                calendarSelectDateTime={this.calendarSelectDateTime}
                                startDate={startDate}
                                showPullDownHandle={true}
                                pulldownHandleId={id}
                                showViewEvent={false}
                                enableSelection={isNew ? true : false}
                            />
                        </div>
                    </div>
                </div>


            </div>

            {isNew && <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                <button type='button' class='btn btn-primary rounded-pill' onClick={this.createCalendarEvent}>
                    {isNew ? <Text id='calendarEvent.create'>Create calendarEvent</Text> : <Text id='calendarEvent.save'>Save calendarEvent</Text>}
                </button>
            </div>}
        </>);
    }
}

export default NewCalendarEvent;
