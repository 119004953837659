import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import localUtil from '../../lib/util';

import RaceList from '../../components/race/list';
import RaceDetail from '../../components/race/detail';
import RaceClassContestantList from '../../components/raceClassContestant/list';
import RaceClassContestantResults from '../../components/race/resultsListMini';

@observer
class RaceResults extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    async loadRaceClassContestants(props = this.props) {
		const { raceStore, raceClassStore, raceClassContestantStore, userStore } = props.stores;
        const { id, raceClassId } = props;

        const { isAdmin } = userStore;
        const { isRaceAdmin, isTimeTracker } = raceStore;
        const isWorkingWithResults = isAdmin || isRaceAdmin || isTimeTracker;

		await raceStore.load(id, false, {
            url: isWorkingWithResults ? null : '/api/races/public/',
        });
		await raceClassStore.load({
            url: isWorkingWithResults ? null : '/api/raceclasses/public/',
            query: {
                id: raceClassId,
                race: id,
            },
        });
        const { race } = raceStore;
// console.log({ raceClassId }, this.props);
        await raceClassContestantStore.load({
            query: {
                race: race.id,
                raceClass: raceClassId,
                addResults: true,
            },
            addData: [],
            url: isWorkingWithResults ? null : '/api/raceclasscontestants/public/',
        });

        const { user } = userStore;
        raceStore.checkAllPositions(user);
        this.setState({
            showResults: race.showResults,
        });
    }

    loadAll = (props = this.props) => {
        clearTimeout(this.reloadTimer);
        this.loadRaceClassContestants(props);
        this.reloadTimer = setTimeout(() => {
            this.loadAll(props);
        }, 1000 * 60);
    }

    stopTimer = () => {
        clearTimeout(this.reloadTimer);
    }

    toggleView = () => {
        this.setState({
            showResults: !this.state.showResults,
        });
    }

    componentDidMount() {
        // console.log('RaceResults.componentDidMount');
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.props.id || nextProps.raceClassId !== this.props.raceClassId) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.reloadTimer);
    }

    render() {
        const { id, raceClassId } = this.props;
        const { userStore, raceStore, raceClassStore, raceClassContestantStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const {  isRaceAdmin } = raceStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { race } = raceStore;
        const { raceClasses } = raceClassStore;
        const { raceClassContestants } = raceClassContestantStore;
        const { showResults = race.showResults } = this.state;

        const finalRaceClasses = raceClasses.filter(rc => !rc.title.includes('⚠️'));

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100'>

                    {(finalRaceClasses && finalRaceClasses.length > 0 && raceClassContestants && raceClassContestants.length > 0) ? <>
                        {finalRaceClasses.map((raceClass, idx) => {
                            const hasContestants = raceClassContestants.filter(rcc => rcc.raceClass === raceClass.id).length > 0;
                            if (!hasContestants) {
                                return null;
                            }

                            return (<>
                                <RaceClassContestantResults
                                    stores={this.props.stores}
                                    classNames={idx > 0 ? 'mt-3' : ''}
                                    title={`${raceClass.title} (${raceClassContestants.filter(rcc => rcc.raceClass === raceClass.id).length})`}
                                    race={race}
                                    raceClass={raceClass}
                                    raceClassId={raceClassId}
                                    raceClassContestants={raceClassContestants.filter(rcc => rcc.raceClass === raceClass.id)}
                                    // hideEmpty={}
                                    showCevron={false}
                                />
                                <div class='d-flex flex-row justify-content-center border-top mt-1'>
                                    <small>
                                        <a href={`/race/${race.id}/${raceClass.id}/results`}>
                                            <Text id='race.see-all-results'>See all results</Text> &raquo;
                                        </a>
                                    </small>
                                </div>
                            </>);
                        })}
                    </> : <>
                        <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                            <div class='w-100 d-flex flex-column px-4 py-3 box-line'>
                                <div class='w-100 d-flex flex-row justify-content-center'>
                                    <div class='box-line-add'>
                                        <i class='fa-solid fa-circle-question' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}

                </div>
            </div>
        </>);
    }
}

export default RaceResults;
