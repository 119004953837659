import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import fields from '../../../lib/fields';

import ImageScroller from '../../../components/imagescroller';
import FormFields from '../../../components/form/fields';

import RaceClassContestantViewProfile from '../../../components/raceClassContestant/viewProfile';

import localUtil from '../../../lib/util';

@observer
class RaceAdminView extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
            sections: fields.get('raceClassContestant'),
            showVippsInfo: {},
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { id } = props;
        const { raceClassContestantStore } = this.props.stores;
        raceClassContestantStore.updateKeyValue('raceClassContestant', {});
        await raceClassContestantStore.load(id);
    }

    checkSignupFeeByVipps = async () => {
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        await raceClassContestantStore.checkSignupFeeByVipps(raceClassContestant.uuidv4);
    }

    checkSignupByVipps = async () => {
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        await raceClassContestantStore.checkSignupByVipps(raceClassContestant.uuidv4);
    }

    checkRestByVipps = async () => {
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        await raceClassContestantStore.checkRestByVipps(raceClassContestant.uuidv4);
    }

    captureSignupFeeByVipps = async () => {
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        await raceClassContestantStore.captureSignupFeeByVipps(raceClassContestant.uuidv4);
    }

    captureSignupByVipps = async () => {
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        await raceClassContestantStore.captureSignupByVipps(raceClassContestant.uuidv4);
    }

    captureRestByVipps = async () => {
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        await raceClassContestantStore.captureRestByVipps(raceClassContestant.uuidv4);
    }

    cancelSignupFeeByVipps = async () => {
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        await raceClassContestantStore.cancelSignupFeeByVipps(raceClassContestant.uuidv4);
    }

    cancelSignupByVipps = async () => {
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        await raceClassContestantStore.cancelSignupByVipps(raceClassContestant.uuidv4);
    }

    cancelRestByVipps = async () => {
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        await raceClassContestantStore.cancelRestByVipps(raceClassContestant.uuidv4);
    }

    checkSignupFee = async () => {
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        if (raceClassContestant.paidSignupfee || !raceClassContestant.vippsSignupfeeReference) {
            return;
        }
        const hasPaid = await raceClassContestantStore.checkSignupFeeByVipps(raceClassContestant.uuidv4);
        setTimeout(() => {
            this.checkSignupFee();
        }, 2000);
    }

    checkSignup = async () => {
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        if (raceClassContestant.paidSignup || !raceClassContestant.vippsSignupReference) {
            return;
        }
        const hasPaid = await raceClassContestantStore.checkSignupByVipps(raceClassContestant.uuidv4);
        setTimeout(() => {
            this.checkSignup();
        }, 2000);
    }

    deleteRaceClassContestant = async () => {
        const { drawerLevel = 1, callback = () => {} } = this.props;
        const { raceClassContestantStore, appState } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        if (!confirm('Are you sure you want to delete this raceClassContestant?')) {
            return;
        }
        await raceClassContestantStore.save({
            id: raceClassContestant.id,
            offline: 1,
        });
        raceClassContestantStore.deleteElement(raceClassContestant.id);
        // callback();
        appState.toggleDrawer(drawerLevel);
    }

    sendEmail = () => {
        const { drawerLevel = 1 } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMediumLarge, apiServer } = appState;

        const { raceClassContestantStore, raceStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        const { race } = raceStore;
        const users = [raceClassContestant];

		appState.openDrawer('sendEmail', {
			height: drawerHeightMediumLarge,
            users,
            subject: ``,
            body: `Hei,

[ Sett inn melding her... ]

- - - - - - - - - - - - - - - - - - - - -
Hi,

[ Insert english message here... ]

- - - - - - - - - - - - - - - - - - - - -

Go to race: ${apiServer}/#/race/${race.id}

Med vennlig hilsen
${race.title}

            `,
            from: race.emailSender,
		}, drawerLevel + 1);
    }

    sendSMS = () => {
        const { drawerLevel = 1 } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;

        const { raceClassContestantStore, raceStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        const { race } = raceStore;
        const users = [raceClassContestant];

		appState.openDrawer('sendSms', {
			height: drawerHeightMediumLarge,
            users,
            from: race.smsSender,
		}, drawerLevel + 1);
    }

    toggleVippsInfo = e => {
        const { id } = e.target.closest('.nerd-info').dataset;
        const { showVippsInfo } = this.state;
        showVippsInfo[id] = !showVippsInfo[id];
        this.setState({ showVippsInfo });
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            page,
            artid,
            bib,
            showAnimations = true,
            drawerLevel,
        } = this.props;
        const { sessionid, showMenu, sections, showVippsInfo = {} } = this.state;
        const { appState, userStore, raceClassContestantStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { raceClassContestant, raceClassContestants } = raceClassContestantStore;

        const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    <RaceClassContestantViewProfile stores={this.props.stores} user={raceClassContestant} />

                    <div class='mx-4 font-weight-lighter mt-4 px-3 py-1 text-muted'>
                        <small class='text-uppercase'>
                            <Text id='race-class-contestants.signup-info'>About</Text>
                        </small>
                    </div>
                    <div class={`d-flex flex-column mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} text-overflow position-relative`}>
                        {[
                            'veteranRookie',
                            'qualifying',
                            'localNewsPaper',
                            'okToShareInfoWithMedia',
                            'rnb',
                            'occupation',
                            'sponsors',
                            'leadDogs',
                            'oldMerits',
                            'hobbies',
                            'funFacts',
                            'ambitions',
                            'signupfeeAmount',
                            'signuppaymentAmount',
                            'restPaymentAmount',
                            'handler1Firstname',
                            'handler1Lastname',
                            'handler1Cellphone',
                            'handler2Firstname',
                            'handler2Lastname',
                            'handler2Cellphone',
                        ].map(key => {
                            const field = fields.getField('raceClassContestant', key);
                            const displayValue = field.displayValue || (value => value);
                            return (<>
                                {user[key] && <div class='d-flex flex-row'>
                                    <span class='text-muted font-weight-lighter'>
                                        {field.icon && <><i class={field.icon} /> {field.title}</>}
                                    </span>
                                    <span class='ml-2'>{displayValue(raceClassContestant[key])}</span>
                                </div>}
                            </>);
                        })}
                        <Markdown markdown={localUtil.replaceImages(raceClassContestant.body, raceClassContestant.images, language, false)} markedOpts={localUtil.getMarkdownOptions()} />
                    </div>

                    {raceClassContestant.signupfeeAmount > 0 && <>
                        <div class='mx-4 font-weight-lighter mt-4 px-3 py-1 text-muted'>
                            <small class='text-uppercase'>
                                <Text id='race-class-contestants.signupfee-title'>Signup fee Payment</Text>
                            </small>
                        </div>
                        <div class={`d-flex flex-column mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} text-overflow position-relative`}>
                            {/* authorizedAmount: {raceClassContestant.vippsSignupfeeObject?.aggregate?.authorizedAmount?.value}<br />
                            cancelledAmount: {raceClassContestant.vippsSignupfeeObject?.aggregate?.cancelledAmount?.value}<br />
                            capturedAmount: {raceClassContestant.vippsSignupfeeObject?.aggregate?.capturedAmount?.value}<br />
                            refundedAmount: {raceClassContestant.vippsSignupfeeObject?.aggregate?.refundedAmount?.value}<br /> */}

                            <div class='d-flex justify-content-between'>
                                <div class='d-flex align-items-center'>
                                    <span class='mr-2' style='font-size: 2.0em;'>{raceClassContestant.paidSignupfee ? <>
                                        {raceClassContestant.vippsSignupfeeStatus === 'AUTHORIZED' ? <>
                                            {raceClassContestant.signupfeeAmount > raceClassContestant.vippsSignupfeeObject?.aggregate?.capturedAmount?.value ? <>
                                                <i class='fa-duotone fa-money-bill text-warning' />
                                            </> : <>
                                                <i class='fa-duotone fa-money-bill text-success' />
                                            </>}
                                        </> : <>
                                            <i class='fa-duotone fa-money-bill text-success' />
                                        </>}
                                    </> : <>
                                        <i class='fa-duotone fa-money-bill text-secondary' />
                                    </>}</span>
                                    <span class='mr-2'>{util.format(raceClassContestant.signupfeeAmount || 0, 0)}</span>
                                </div>
                                <div class='d-flex align-items-center'>
                                    {raceClassContestant.vippsSignupfeeStatus === 'AUTHORIZED' ? <>
                                        {raceClassContestant.signupfeeAmount > 0 && raceClassContestant.signupfeeAmount > raceClassContestant.vippsSignupfeeObject?.aggregate?.capturedAmount?.value ? <>
                                            <button type='button' class='btn btn-success rounded-pill' onClick={this.captureSignupFeeByVipps}>
                                                Capture
                                            </button>
                                            <button type='button' class='btn btn-danger rounded-pill ml-2' onClick={this.cancelSignupFeeByVipps}>
                                                Cancel
                                            </button>
                                            <button type='button' class='btn btn-sm btn-outline-secondary rounded-pill' onClick={this.checkSignupFeeByVipps}>
                                                Check
                                            </button>
                                        </> : <>
                                            <i class='fa-duotone fa-check text-success mr-2' /> Captured
                                        </>}
                                        <img src={'/assets/external/Mark.png'} class='ml-2' />
                                    </> : <>
                                        <button type='button' class='btn btn-sm btn-outline-danger rounded-pill' onClick={this.checkSignupFeeByVipps}>Check</button>
                                    </>}
                                </div>
                            </div>
                            <div class='d-flex flex-row justify-content-center'>
                                <small class='text-muted'>
                                    {raceClassContestant.vippsSignupfeeStatus === 'CREATED' && <>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='race-class-contestants.vipps-status'>Vipps status</Text>:</span>
                                        CREATED: User has not yet acted upon the payment
                                    </>}
                                    {raceClassContestant.vippsSignupfeeStatus === 'ABORTED' && <>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='race-class-contestants.vipps-status'>Vipps status</Text>:</span>
                                        ABORTED: User has aborted the payment before authorization
                                    </>}
                                    {raceClassContestant.vippsSignupfeeStatus === 'EXPIRED' && <>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='race-class-contestants.vipps-status'>Vipps status</Text>:</span>
                                        EXPIRED: User did not act on the payment within the payment expiration time
                                    </>}
                                    {raceClassContestant.vippsSignupfeeStatus === 'AUTHORIZED' && <>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='race-class-contestants.vipps-status'>Vipps status</Text>:</span>
                                        AUTHORIZED: User has approved the payment
                                    </>}
                                    {raceClassContestant.vippsSignupfeeStatus === 'TERMINATED' && <>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='race-class-contestants.vipps-status'>Vipps status</Text>:</span>
                                        TERMINATED: Merchant has terminated the payment via the cancelPayment endpoint
                                    </>}
                                </small>
                            </div>
                            <div class='d-flex flex-row justify-content-center'>
                                {raceClassContestant.paidSignupfee && <>
                                    <small class='text-muted'>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='input.paidDate'>Paid date</Text>:</span>
                                        <span class='mr-2'>{util.formatDate(raceClassContestant.paidSignupfee, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}</span>
                                    </small>
                                </>}
                            </div>
                            <div class='d-flex flex-row justify-content-center'>
                                {showVippsInfo.signupfee ? <>
                                    <div class='w-100 overflow-auto nerd-info' onClick={this.toggleVippsInfo} data-id={'signupfee'}>
                                        <xmp class='w-100'>{JSON.stringify(raceClassContestant.vippsSignupfeeObject, null, 4)}</xmp>
                                    </div>
                                </> : <>
                                    <button type='button' class='btn btn-sm btn-link nerd-info' onClick={this.toggleVippsInfo} data-id={'signupfee'}>
                                        Show Vipps raw nerd info
                                    </button>
                                </>}
                            </div>
                        </div>
                    </>}


                    {raceClassContestant.signuppaymentAmount > 0 && <>
                        <div class='mx-4 font-weight-lighter mt-4 px-3 py-1 text-muted'>
                            <small class='text-uppercase'>
                                <Text id='race-class-contestants.signup-title'>Signup Payment</Text>
                            </small>
                        </div>
                        <div class={`d-flex flex-column mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} text-overflow position-relative`}>
                            {/* authorizedAmount: {raceClassContestant.vippsSignupObject?.aggregate?.authorizedAmount?.value}<br />
                            cancelledAmount: {raceClassContestant.vippsSignupObject?.aggregate?.cancelledAmount?.value}<br />
                            capturedAmount: {raceClassContestant.vippsSignupObject?.aggregate?.capturedAmount?.value}<br />
                            refundedAmount: {raceClassContestant.vippsSignupObject?.aggregate?.refundedAmount?.value}<br /> */}

                            <div class='d-flex justify-content-between'>
                                <div class='d-flex align-items-center'>
                                    <span class='mr-2' style='font-size: 2.0em;'>{raceClassContestant.paidSignup ? <>
                                        {raceClassContestant.vippsSignupStatus === 'AUTHORIZED' ? <>
                                            {raceClassContestant.signuppaymentAmount > raceClassContestant.vippsSignupObject?.aggregate?.capturedAmount?.value ? <>
                                                <i class='fa-duotone fa-money-bill text-warning' />
                                            </> : <>
                                                <i class='fa-duotone fa-money-bill text-success' />
                                            </>}
                                        </> : <>
                                            <i class='fa-duotone fa-money-bill text-success' />
                                        </>}
                                    </> : <>
                                        <i class='fa-duotone fa-money-bill text-secondary' />
                                    </>}</span>
                                    {util.format(raceClassContestant.signuppaymentAmount || 0, 0)}
                                </div>
                                <div class='d-flex align-items-center'>
                                    {raceClassContestant.vippsSignupStatus === 'AUTHORIZED' ? <>
                                        {raceClassContestant.signuppaymentAmount > 0 && raceClassContestant.signuppaymentAmount > raceClassContestant.vippsSignupObject?.aggregate?.capturedAmount?.value ? <>
                                            <button type='button' class='btn btn-success rounded-pill' onClick={this.captureSignupByVipps}>
                                                Capture
                                            </button>
                                            <button type='button' class='btn btn-danger rounded-pill ml-2' onClick={this.cancelSignupByVipps}>
                                                Cancel
                                            </button>
                                            <button type='button' class='btn btn-sm btn-outline-secondary rounded-pill' onClick={this.checkSignupByVipps}>
                                                Check
                                            </button>
                                        </> : <>
                                            <i class='fa-duotone fa-check text-success mr-2' /> Captured
                                        </>}
                                        <img src={'/assets/external/Mark.png'} class='ml-2' />
                                    </> : <>
                                        <button type='button' class='btn btn-sm btn-outline-danger rounded-pill' onClick={this.checkSignupByVipps}>Check</button>
                                    </>}
                                </div>
                            </div>
                            <div class='d-flex flex-row justify-content-center'>
                                <small class='text-muted'>
                                    {raceClassContestant.vippsSignupStatus === 'CREATED' && <>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='race-class-contestants.vipps-status'>Vipps status</Text>:</span>
                                        CREATED: User has not yet acted upon the payment
                                    </>}
                                    {raceClassContestant.vippsSignupStatus === 'ABORTED' && <>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='race-class-contestants.vipps-status'>Vipps status</Text>:</span>
                                        ABORTED: User has aborted the payment before authorization
                                    </>}
                                    {raceClassContestant.vippsSignupStatus === 'EXPIRED' && <>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='race-class-contestants.vipps-status'>Vipps status</Text>:</span>
                                        EXPIRED: User did not act on the payment within the payment expiration time
                                    </>}
                                    {raceClassContestant.vippsSignupStatus === 'AUTHORIZED' && <>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='race-class-contestants.vipps-status'>Vipps status</Text>:</span>
                                        AUTHORIZED: User has approved the payment
                                    </>}
                                    {raceClassContestant.vippsSignupStatus === 'TERMINATED' && <>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='race-class-contestants.vipps-status'>Vipps status</Text>:</span>
                                        TERMINATED: Merchant has terminated the payment via the cancelPayment endpoint
                                    </>}
                                </small>
                            </div>
                            <div class='d-flex flex-row justify-content-center'>
                                {raceClassContestant.paidSignup && <>
                                    <small class='text-muted'>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='input.paidDate'>Paid date</Text>:</span>
                                        <span class='mr-2'>{util.formatDate(raceClassContestant.paidSignup, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}</span>
                                    </small>
                                </>}
                            </div>
                            <div class='d-flex flex-row justify-content-center'>
                                {showVippsInfo.signup ? <>
                                    <div class='w-100 overflow-auto nerd-info' onClick={this.toggleVippsInfo} data-id={'signup'}>
                                        <xmp class='w-100'>{JSON.stringify(raceClassContestant.vippsSignupObject, null, 4)}</xmp>
                                    </div>
                                </> : <>
                                    <button type='button' class='btn btn-sm btn-link nerd-info' onClick={this.toggleVippsInfo} data-id={'signup'}>
                                        Show Vipps raw nerd info
                                    </button>
                                </>}
                            </div>
                        </div>
                    </>}

                    {raceClassContestant.restPaymentAmount > 0 && <>
                        <div class='mx-4 font-weight-lighter mt-4 px-3 py-1 text-muted'>
                            <small class='text-uppercase'>
                                <Text id='race-class-contestants.rest-title'>Rest Payment</Text>
                            </small>
                        </div>
                        <div class={`d-flex flex-column mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} text-overflow position-relative`}>
                            {/* authorizedAmount: {raceClassContestant.vippsSignupObject?.aggregate?.authorizedAmount?.value}<br />
                            cancelledAmount: {raceClassContestant.vippsSignupObject?.aggregate?.cancelledAmount?.value}<br />
                            capturedAmount: {raceClassContestant.vippsSignupObject?.aggregate?.capturedAmount?.value}<br />
                            refundedAmount: {raceClassContestant.vippsSignupObject?.aggregate?.refundedAmount?.value}<br /> */}

                            <div class='d-flex justify-content-between'>
                                <div class='d-flex align-items-center'>
                                    <span class='mr-2' style='font-size: 2.0em;'>{raceClassContestant.paidRest ? <>
                                        {raceClassContestant.vippsRestStatus === 'AUTHORIZED' ? <>
                                            {raceClassContestant.restPaymentAmount > raceClassContestant.vippsRestObject?.aggregate?.capturedAmount?.value ? <>
                                                <i class='fa-duotone fa-money-bill text-warning' />
                                            </> : <>
                                                <i class='fa-duotone fa-money-bill text-success' />
                                            </>}
                                        </> : <>
                                            <i class='fa-duotone fa-money-bill text-success' />
                                        </>}
                                    </> : <>
                                        <i class='fa-duotone fa-money-bill text-secondary' />
                                    </>}</span>
                                    {util.format(raceClassContestant.restPaymentAmount || 0, 0)}
                                </div>
                                <div class='d-flex align-items-center'>
                                    {raceClassContestant.vippsRestStatus === 'AUTHORIZED' ? <>
                                        {raceClassContestant.restPaymentAmount > 0 && raceClassContestant.restPaymentAmount > raceClassContestant.vippsRestObject?.aggregate?.capturedAmount?.value ? <>
                                            <button type='button' class='btn btn-success rounded-pill' onClick={this.captureRestByVipps}>
                                                Capture
                                            </button>
                                            <button type='button' class='btn btn-danger rounded-pill ml-2' onClick={this.cancelRestByVipps}>
                                                Cancel
                                            </button>
                                            <button type='button' class='btn btn-sm btn-outline-secondary rounded-pill' onClick={this.checkRestByVipps}>
                                                Check
                                            </button>
                                        </> : <>
                                            <i class='fa-duotone fa-check text-success mr-2' /> Captured
                                        </>}
                                        <img src={'/assets/external/Mark.png'} class='ml-2' />
                                    </> : <>
                                        <button type='button' class='btn btn-sm btn-outline-danger rounded-pill' onClick={this.checkRestByVipps}>Check</button>
                                    </>}
                                </div>
                            </div>
                            <div class='d-flex flex-row justify-content-center'>
                                <small class='text-muted'>
                                    {raceClassContestant.vippsRestStatus === 'CREATED' && <>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='race-class-contestants.vipps-status'>Vipps status</Text>:</span>
                                        CREATED: User has not yet acted upon the payment
                                    </>}
                                    {raceClassContestant.vippsRestStatus === 'ABORTED' && <>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='race-class-contestants.vipps-status'>Vipps status</Text>:</span>
                                        ABORTED: User has aborted the payment before authorization
                                    </>}
                                    {raceClassContestant.vippsRestStatus === 'EXPIRED' && <>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='race-class-contestants.vipps-status'>Vipps status</Text>:</span>
                                        EXPIRED: User did not act on the payment within the payment expiration time
                                    </>}
                                    {raceClassContestant.vippsRestStatus === 'AUTHORIZED' && <>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='race-class-contestants.vipps-status'>Vipps status</Text>:</span>
                                        AUTHORIZED: User has approved the payment
                                    </>}
                                    {raceClassContestant.vippsRestStatus === 'TERMINATED' && <>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='race-class-contestants.vipps-status'>Vipps status</Text>:</span>
                                        TERMINATED: Merchant has terminated the payment via the cancelPayment endpoint
                                    </>}
                                </small>
                            </div>
                            <div class='d-flex flex-row justify-content-center'>
                                {raceClassContestant.paidRest && <>
                                    <small class='text-muted'>
                                        <span class='mr-2 text-muted font-weight-lighter'><Text id='input.paidDate'>Paid date</Text>:</span>
                                        <span class='mr-2'>{util.formatDate(raceClassContestant.paidRest, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}</span>
                                    </small>
                                </>}
                            </div>
                            <div class='d-flex flex-row justify-content-center'>
                                {showVippsInfo.rest ? <>
                                    <div class='w-100 overflow-auto nerd-info' onClick={this.toggleVippsInfo} data-id={'rest'}>
                                        <xmp class='w-100'>{JSON.stringify(raceClassContestant.vippsRestObject, null, 4)}</xmp>
                                    </div>
                                </> : <>
                                    <button type='button' class='btn btn-sm btn-link nerd-info' onClick={this.toggleVippsInfo} data-id={'rest'}>
                                        Show Vipps raw nerd info
                                    </button>
                                </>}
                            </div>
                        </div>
                    </>}

                    <div class='w-100 d-flex flex-column justify-content-start'>
                        <div class='bg-light d-flex flex-column justify-content-start h-100'>
                            <FormFields
                                stores={this.props.stores}
                                sections={sections}
                                fields={[
                                    'approved', 'bib', 'startTime',
                                    'paidSignupfee', 'paidSignup', 'paymentMethod', 'signupfeeAmount', 'signuppaymentAmount',
                                    'paidRest', 'restPaymentAmount',
                                    'handler1Firstname', 'handler1Lastname', 'handler1Cellphone',
                                    'handler2Firstname', 'handler2Lastname', 'handler2Cellphone',
                                    'raceClass', 'comment',
                                    'isScratched', 'isDNS',
                                    'rnb', 'veteranRookie',
                                ]}
                                holdingStore={raceClassContestantStore}
                                objectName={'raceClassContestant'}
                                isNew={false}
                                drawerLevel={drawerLevel}
                                isAdmin={false}
                            />
                        </div>
                    </div>

                    <div class='d-flex flex-wrap justify-content-center mt-5'>
                        <div class='d-flex flex-nowrap mt-1'>
                            <button type='button' class={`btn btn-sm btn-primary rounded-pill`} onClick={this.sendEmail}>
                                <i class='fa-solid fa-paper-plane mr-1' /> Send Email
                            </button>
                            <button type='button' class={`btn btn-sm btn-info rounded-pill ml-2`} onClick={this.sendSMS}>
                                <i class='fa-solid fa-mobile mr-1' /> Send SMS
                            </button>
                        </div>
                    </div>


                    <div class='w-100 d-flex flex-column justify-content-start mt-5'>
                        <div class='bg-light d-flex flex-column justify-content-start h-100 px-3'>

                            <button type='button' class='btn btn-danger rounded-pill' onClick={this.deleteRaceClassContestant}>
                                <Text id='race-class-contestants.signup-delete'>Delete signup</Text>
                            </button>

                        </div>
                    </div>


                </div>
            </div>
        </>);
    }
}

export default RaceAdminView;
