import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import GuiList from '../../components/gui/list';

function displayImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

@withText(props => ({
    titleText: <Text id='submittedapplication.yourtitle'>Your submitted applications</Text>,
    areYouSure: <Text id='submittedapplication.areyousure'>Are you sure you want to delete this application?</Text>,
}))
@observer
class SubmittedApplicationList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            filterByTags: [],
        };
    }


    viewSubmittedApplication = async e => {
        const { view } = this.props;
        if (view) {
            return view(e);
        }
        const { id, title } = e.target.closest('.box-line').dataset;
        const { appState, userStore, submittedApplicationStore } = this.props.stores;
        const { drawerLevel = 1, callback = () => {} } = this.props;
        const { drawerHeightMediumLarge } = appState;
        const { user, isAdmin } = userStore;
        const { submittedApplication = {} } = submittedApplicationStore;
// console.log('viewSubmittedApplication', submittedApplication);

        const currentSubmittedApplications = await submittedApplicationStore.load(id, false, { addData: ['tags'], skipUpdate: true });
        const currentSubmittedApplication = currentSubmittedApplications[0];

        appState.openDrawer('viewSubmittedApplication', {
            id,
            height: drawerHeightMediumLarge,
            editFunction: currentSubmittedApplication.isSubmitted ? null : this.editSubmittedApplicationView,
            callback: () => {
                appState.toggleDrawer();
                callback();
                this.loadAll();
            },
        }, drawerLevel + 1);
    }

    viewTags = obj => {
        if (obj.tags?.length === 0 && obj.loans?.length === 0 && !obj.ownedByOther && !obj.url && !obj.parents?.length > 0 && !obj.files?.length > 0) {
            return '';
        }
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        const tagClass = darkmode ? 'dark' : 'light';

        return (<>
            <div class='w-100 overflow-hidden'>
                <div class='box-line-tags d-flex flex-nowrap'>
                    {/* {obj.files?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-file' /> <span class='font-weight-lighter'> x {obj.files.length}</span>
                        </span>
                    </>} */}
                    {obj.tags?.length > 0 && obj.tags.map(tag => {
                        return (<>
                            <span
                                class={`badge badge-pill badge-${tagClass} mr-1 mt-1`}
                                onClick={this.toggleTagFilter}
                                data-tag={tag.name}
                            >#{tag.name}</span>
                        </>);
                    })}
                </div>
            </div>
        </>);
    }

    toggleTagFilter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { filterByTags: filterByTagsProps } = this.props;
        if (filterByTagsProps?.length > 0) {
            return;
        }
        const { tag } = e.target.closest('span').dataset;
        const { filterByTags } = this.state;
        if (filterByTags.includes(tag)) {
            const index = filterByTags.indexOf(tag);
            filterByTags.splice(index, 1);
        } else {
            filterByTags.push(tag);
        }
        this.setState({ filterByTags });
    }

    filterByTags = (obj) => {
        const { filterByTags, hideChildren } = this.state;
        const { filterByTags: filterByTagsProps } = this.props;
        if (hideChildren && obj.parent) {
            return false;
        }
        if (filterByTagsProps?.length > 0) {
            return obj.tags?.some(tag => filterByTagsProps.includes(tag.name));
        }
        if (filterByTags.length === 0) {
            return true;
        }
        // console.log('filterByTags', { obj, filterByTags })
        return obj.tags?.some(tag => filterByTags.includes(tag.name));
    }

    filter = () => {
        const { showTagsOnTop = true } = this.props;
        if (showTagsOnTop) {
            return '';
        }
        const { filterByTags } = this.state;
        if (filterByTags.length > 0) {
            return (<>
                {filterByTags.map(tag => {
                    return (<>
                        <span
                            class='badge badge-pill badge-secondary mr-1'
                            style='font-size: 1.0rem;'
                            onClick={this.toggleTagFilter}
                            data-tag={tag}
                        ><i class="fa-solid fa-circle-xmark"></i> {tag}</span>
                    </>);
                })}
            </>);
        }
    }

    editSubmittedApplication = e => {
        const { id, title } = e.target.closest('.box-line').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, mediasStore } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
		appState.openDrawer('editSubmittedApplication', {
            id,
			height: drawerHeightMediumLarge,
            callback: this.closeAddMedia,
		}, drawerLevel + 2);
	}

    editSubmittedApplicationView = e => {
        // return;
        const { id, title } = e.target.closest('button').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, mediasStore } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
		appState.openDrawer('editSubmittedApplication', {
            id,
			height: drawerHeightMediumLarge,
            callback: () => {
                appState.toggleDrawer(false, drawerLevel + 2);
                // this.loadAll();
            },
		}, drawerLevel + 2);
	}

    deleteSubmittedApplication = async e => {
        const { areYouSure } = this.props;
        const { id } = e.target.closest('.box-line').dataset;
        console.log('deleteSubmittedApplication', e);
        const { submittedApplicationStore } = this.props.stores;

        if (!confirm(areYouSure)) {
            return;
        }

        await submittedApplicationStore.saveField(id, 'offline', 1);
        this.loadAll();
    }

    loadAll = async () => {
        const { list, callback = () => {} } = this.props;
        if (list) {
            callback();
            return;
        }
        const { submittedApplicationStore } = this.props.stores;
        await submittedApplicationStore.load({ addData: ['tags'], limit: 5 });
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { appState, userStore, submittedApplicationStore } = this.props.stores;
        const { isDevelopment, path, apiServer } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { titleText } = this.props;
        const {
            classNames = '',
            hideEmpty,
            title = titleText,
            viewTags = this.viewTags,
            editElement,
            help,
            children,
            showTagsOnTop = true,
            list,
        } = this.props;
        const { filterByTags } = this.state;
        const { tags, submittedApplications } = submittedApplicationStore;

        const finalList = list || submittedApplications;

        return (<>
            <Localizer>
                <GuiList
                    stores={this.props.stores}
                    holdingStore={submittedApplicationStore}
                    classNames={classNames}
                    view={this.viewSubmittedApplication}
                    elIcon={e => e.isSubmitted ? 'fa-duotone fa-check text-success' : 'fa-duotone fa-pen-to-square text-secondary'}
                    title={title}
                    filter={this.filter}
                    list={finalList.filter(this.filterByTags)}
                    elTitle={e => e.title || 'No title'}
                    elInfo2={e => util.formatDate(e.createdDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                    elInfo1={e => e.isSubmitted ? <>
                        <span class='text-success'>
                            <i class='fa-duotone fa-check mr-1' />
                            <Text id='submittedapplication.submitted'>Submitted</Text>
                        </span>
                    </> : <>
                        <span class='text-danger'>
                            <i class='fa-solid fa-triangle-exclamation fa-beat-fade mr-1' />
                            <Text id='submittedapplication.draft'>Draft - Not submitted</Text>
                        </span>
                    </>}
                    // elInfo2={e => e.whoIsInTheMedia}
                    // elInfo3={e => e.whatIsInTheMedia}
                    viewTags={viewTags}
                    hideEmpty={hideEmpty}
                    editElement={this.editSubmittedApplication}
                    deleteElement={this.deleteSubmittedApplication}
                    help={help || children}
                    showEmptyImage={true}

                    rightBoxField= 'giftAmount'
                    rightBoxFieldValue={val => util.format(val, 0)}
                    rightBoxFieldPostfix={<Text id='input.amount'> Amount</Text>}
                    // rightBoxFieldPostfix
                    // rightBoxFieldPostfixField={'foobar'}
                    // rightBoxFieldPostfixValue= e => util.formatDate(e, { year: 'numeric', locale: 'nb-NO' }, true),
                    // rightBoxFieldBottomValue= e => e.totalContestants ? <><i class='fa-duotone fa-user' /> {e.totalContestants}</> : '',
                />
            </Localizer>
        </>);
    }
}

export default SubmittedApplicationList;
