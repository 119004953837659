import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class RaceClassContestantStore extends LocalModel {
    constructor() {
        super('raceClassContestant', {
            namePlural: 'raceClassContestants',
            namePluralReal: 'raceClassContestants',
            sort: 'startTime bib signupDate id',
            // sort: '-signupDate id',
            limit: 500,
            api: {
                search: {
                    url: '/api/raceclasscontestants/',
                    params: {
                        limit: 500,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/raceclasscontestants/',
                    params: {},
                },
                save: {
                    url: '/api/raceclasscontestants/',
                    params: {},
                },
                delete: {
                    url: '/api/raceclasscontestants/',
                    params: {},
                },
            },
        });
    }

    @observable hasSignedUp = false;

    @observable hasSignedUpToRace = false;

    @observable newRaceClassContestant = {};

    @observable raceClassContestant = {};

    @observable raceClassContestants = [];

    @observable adminRaceClasses = [];

    @observable raceClasses = [];

    @observable raceContestants = [];

    @observable adminRaceClassContestants = [];

    @observable raceContestant = {};

    async loadContestants({ race, raceClass, raceClassContestant, user, emptyFirst = true }) {
        if (emptyFirst) {
            this.updateKeyValue('raceClassContestant', {});
            this.updateKeyValue('hasSignedUp', false);
            this.updateKeyValue('hasSignedUpToRace', false);
        }

        const query = {
            race,
            raceClass,
            user,
            // cellphone: encodeURIComponent(cellphone),
        };
        const response = await this.load({ query, skipUpdate: true,  addData: ['raceContestants'] });
        if (Array.isArray(this.raceContestants)) {
            const raceContestant = this.raceContestants[0];
            this.updateKeyValue('raceContestant', raceContestant);
            if ((raceContestant && raceContestant.user === user)) {
                this.updateKeyValue('hasSignedUpToRace', true);
            }
        }
        if (Array.isArray(response) && response[0]) {
            const contestant = response[0];
            this.updateKeyValue('raceClassContestant', contestant);
            if ((contestant && contestant.user === user)) {
                this.updateKeyValue('hasSignedUp', true);
            }

            // Check for other raceClass signups
            return contestant;
        }
        return null;
    }

    // - - - - [ Signup Fee ] - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    async paySignupFeeByVipps(uuidv4) {
        const response = await util.fetchApi('/api/raceclasscontestants/vipps/request-signupfee-payment', {  method: 'POST' }, { uuidv4 });
        switch (response.status) {
            case 200:
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupfeeReference', response.data.reference);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupfeeRedirectUrl', response.data.redirectUrl);
                // this.updateObjectKeyValue('raceClassContestant', 'vippsSignupfeeObject', response.data.vippsSignupfeeObject);
                return true;
                break;
            default:
                return false;
                break;
        }
    }

    async checkSignupFeeByVipps(uuidv4) {
        const response = await util.fetchApi(`/api/raceclasscontestants/vipps/payment-signupfee-status/${uuidv4}`, {  method: 'GET' }, { uuidv4 });
        switch (response.status) {
            case 200:
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupfeeStatus', response.data.state);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupfeeAmountCurrency', response.data.currency);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupfeeAmountValue', response.data.value);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupfeeObject', response.data.vippsSignupfeeObject);
                this.updateObjectKeyValue('raceClassContestant', 'paidSignupfee', response.data.paidSignupfee);

                if (response.data && response.data.state === 'AUTHORIZED') {
                    return true;
                }
                return false;
                break;
            default:
                return false;
                break;
        }
    }

    async captureSignupFeeByVipps(uuidv4) {
        const response = await util.fetchApi(`/api/raceclasscontestants/vipps/payment-signupfee-capture/${uuidv4}`, {  method: 'GET' }, { uuidv4 });
        switch (response.status) {
            case 200:
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupfeeObject', response.data.vippsSignupfeeObject);
                return true;
                break;
            default:
                return false;
                break;
        }
    }


    async cancelSignupFeeByVipps(uuidv4) {
        const response = await util.fetchApi(`/api/raceclasscontestants/vipps/payment-signupfee-cancel/${uuidv4}`, {  method: 'GET' }, { uuidv4 });
        switch (response.status) {
            case 200:
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupfeeStatus', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupfeeReference', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupfeeStartDate', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupfeePspReference', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupfeeAmountCurrency', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupfeeAmountValue', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupfeeObject', null);
                this.updateObjectKeyValue('raceClassContestant', 'paidSignupfee', null);
                return true;
                break;
            default:
                return false;
                break;
        }
    }

    // - - - - [ Signup ] - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    async paySignupByVipps(uuidv4) {
        const response = await util.fetchApi('/api/raceclasscontestants/vipps/request-signup-payment', {  method: 'POST' }, { uuidv4 });
        switch (response.status) {
            case 200:
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupReference', response.data.reference);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupRedirectUrl', response.data.redirectUrl);
                // this.updateObjectKeyValue('raceClassContestant', 'vippsSignupObject', response.data.vippsSignupObject);
                return true;
                break;
            default:
                return false;
                break;
        }
    }

    async checkSignupByVipps(uuidv4) {
        const response = await util.fetchApi(`/api/raceclasscontestants/vipps/payment-signup-status/${uuidv4}`, {  method: 'GET' }, { uuidv4 });
        switch (response.status) {
            case 200:
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupStatus', response.data.state);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupAmountCurrency', response.data.currency);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupAmountValue', response.data.value);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupObject', response.data.vippsSignupObject);
                this.updateObjectKeyValue('raceClassContestant', 'paidSignup', response.data.paidSignup);

                if (response.data && response.data.state === 'AUTHORIZED') {
                    return true;
                }
                return false;
                break;
            default:
                return false;
                break;
        }
    }

    async captureSignupByVipps(uuidv4) {
        const response = await util.fetchApi(`/api/raceclasscontestants/vipps/payment-signup-capture/${uuidv4}`, {  method: 'GET' }, { uuidv4 });
        switch (response.status) {
            case 200:
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupObject', response.data.vippsSignupObject);
                return true;
                break;
            default:
                return false;
                break;
        }
    }

    async cancelSignupByVipps(uuidv4) {
        const response = await util.fetchApi(`/api/raceclasscontestants/vipps/payment-signup-cancel/${uuidv4}`, {  method: 'GET' }, { uuidv4 });
        switch (response.status) {
            case 200:
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupStatus', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupReference', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupStartDate', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupPspReference', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupAmountCurrency', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupAmountValue', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsSignupObject', null);
                this.updateObjectKeyValue('raceClassContestant', 'paidSignup', null);
                return true;
                break;
            default:
                return false;
                break;
        }
    }

    // - - - - [ Rest ] - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    async payRestByVipps(uuidv4) {
        const response = await util.fetchApi('/api/raceclasscontestants/vipps/request-rest-payment', {  method: 'POST' }, { uuidv4 });
        switch (response.status) {
            case 200:
                this.updateObjectKeyValue('raceClassContestant', 'vippsRestReference', response.data.reference);
                this.updateObjectKeyValue('raceClassContestant', 'vippsRestRedirectUrl', response.data.redirectUrl);
                // this.updateObjectKeyValue('raceClassContestant', 'vippsRestObject', response.data.vippsRestObject);
                return true;
                break;
            default:
                return false;
                break;
        }
    }

    async checkRestByVipps(uuidv4) {
        const response = await util.fetchApi(`/api/raceclasscontestants/vipps/payment-rest-status/${uuidv4}`, {  method: 'GET' }, { uuidv4 });
        switch (response.status) {
            case 200:
                this.updateObjectKeyValue('raceClassContestant', 'vippsRestStatus', response.data.state);
                this.updateObjectKeyValue('raceClassContestant', 'vippsRestAmountCurrency', response.data.currency);
                this.updateObjectKeyValue('raceClassContestant', 'vippsRestAmountValue', response.data.value);
                this.updateObjectKeyValue('raceClassContestant', 'vippsRestObject', response.data.vippsRestObject);
                this.updateObjectKeyValue('raceClassContestant', 'paidRest', response.data.paidRest);

                if (response.data && response.data.state === 'AUTHORIZED') {
                    return true;
                }
                return false;
                break;
            default:
                return false;
                break;
        }
    }

    async captureRestByVipps(uuidv4) {
        const response = await util.fetchApi(`/api/raceclasscontestants/vipps/payment-rest-capture/${uuidv4}`, {  method: 'GET' }, { uuidv4 });
        switch (response.status) {
            case 200:
                this.updateObjectKeyValue('raceClassContestant', 'vippsRestObject', response.data.vippsRestObject);
                return true;
                break;
            default:
                return false;
                break;
        }
    }

    async cancelRestByVipps(uuidv4) {
        const response = await util.fetchApi(`/api/raceclasscontestants/vipps/payment-rest-cancel/${uuidv4}`, {  method: 'GET' }, { uuidv4 });
        switch (response.status) {
            case 200:
                this.updateObjectKeyValue('raceClassContestant', 'vippsRestStatus', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsRestReference', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsRestStartDate', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsRestPspReference', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsRestAmountCurrency', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsRestAmountValue', null);
                this.updateObjectKeyValue('raceClassContestant', 'vippsRestObject', null);
                this.updateObjectKeyValue('raceClassContestant', 'paidRest', null);
                return true;
                break;
            default:
                return false;
                break;
        }
    }

    async loadPublic(opt = { query: {} }) {
        const response = await util.fetchApi(`/api/raceclasscontestants/public`, {  method: 'GET' }, { ...opt.query, limit: opt.limit || 1000 });
        switch (response.status) {
            case 200:
                this.updateKeyValue('raceClassContestants', response.data);
                return true;
                break;
            default:
                return false;
                break;
        }
    }

    @action
    calculateTimeUsed(sortedCheckpoints, raceClassId) {
        // const timeused = timeUsed(rcc, sortedCheckpoints, rcc);
        for (let i = 0, l = this.raceClassContestants.length; i < l; i += 1) {
            const rcc = this.raceClassContestants[i];
            if (rcc.raceClass !== raceClassId) {
                continue;
            }
            rcc.raceTimeused = 0;

            const startTime = rcc.startTime;
            let lastTimestamp = startTime;
            let prevRccCp = null;
            sortedCheckpoints.forEach(cp => {
                const rccCp = rcc.checkpoints?.find(e => e.checkpoint === cp.md5) || {};
                if (rccCp.timestampOut || rccCp.timestampIn) {
                    lastTimestamp = rccCp.timestampOut || rccCp.timestampIn;
                }
                if (prevRccCp && prevRccCp.timestampOut && rccCp.timestampIn) {
                    const timeused = util.dateDiff(prevRccCp.timestampOut, rccCp.timestampIn);
                    rcc.raceTimeused += timeused.seconds;
                }
                prevRccCp = rccCp;
            });

            if (startTime === lastTimestamp) {
                rcc.timeused = 0;
                continue;
            }

            const tu = util.dateDiff(startTime, lastTimestamp)
        // console.log({ startTime, lastTimestamp, seconds: tu.seconds });
            rcc.timeused = tu.seconds;
            rcc.startTime = startTime;
            rcc.endTime = lastTimestamp;
        }
    }
}

const store = new RaceClassContestantStore();
export default store;
