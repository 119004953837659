import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';

@observer
class Menu extends Component {
	constructor(props) {
        super(props);
        this.state = {};
    }

    toggleDrawer = () => {
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        appState.toggleDrawer(false, drawerLevel);
    }

    render() {
        const { menus } = this.props;
        const { appState, userStore } = this.props.stores;
        const { isCordova, opts = {}, showAdminFields } = appState;
        const { user, isAdmin, saved } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            {menus && menus.map((area, idx) => {
                return (<>
                    <div class='mx-4 font-weight-lighter px-3 py-1 text-muted' >
                        <small class='text-uppercase'>
                            {area.name}
                        </small>
                    </div>
                    <div
                        class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-4 d-flex flex-column`}
                        style={`
                            font-size: 18px !important;
                            border-radius: 15px;
                        `}
                    >
                        {area.links && area.links.map((link, idx) => {
                            if (link.hasToHaveCordova && !isCordova) {
                                return '';
                            }
                            if (!showAdminFields && link.hasToBeAdmin) {
                                return '';
                            }
                            if (link.hasToBeAdmin && !isAdmin) {
                                return '';
                            }
                            if (link.type === 'inspections' && !opts.showInspections) {
                                return '';
                            }
                            return (<>
                                <div
                                    class={`d-flex justify-content-center align-items-center position-relative pl-2 pr-3`}
                                    style={`${idx > 0 ? `border-top: 1px ${darkmode ? '#303030' : '#f0f0f0'} solid;` : ''}`}
                                >
                                    <div class='d-flex justify-content-center align-items-center' style='width: 44px; height: 44px;'>
                                        <i class={`${link.icon} ${link.hasToBeAdmin ? 'text-danger' : 'text-secondary'}`} />
                                    </div>
                                    <span class={`flex-grow-1`} style='line-height: 1.3em;'>
                                        {link.func ? <>
                                            <button
                                                onClick={link.func}
                                                class={`${darkmode ? 'text-white' : 'text-dark'} btn btn-link btn-block text-left p-0 d-flex justify-content-between align-items-center`}
                                                style='font-size: 18px !important;'
                                            >
                                                <span>{link.name}</span>
                                                {/* <i class='fa-solid fa-angle-right float-right' style='font-size: 0.9em; color: #e0e0e0;' /> */}
                                            </button>
                                        </> : <>
                                            <a
                                                href={link.href}
                                                class={`${darkmode ? 'text-white' : 'text-dark'} stretched-link`}
                                                onClick={this.toggleDrawer}
                                            >
                                                {link.name}
                                            </a>
                                        </>}
                                    </span>
                                </div>
                            </>);
                        })}
                    </div>
                    <div class='mx-4 font-weight-lighter px-3 pb-1 text-muted' >
                        <small class=''>
                            {area.help}
                        </small>
                    </div>
                </>);
            })}
        </>);
    }
}

export default Menu;
