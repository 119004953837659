import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import DyrejournalAnimalListMini from '../../components/dyrejournal/listAnimalMini';
import DyrejournalPrescriptionListMini from '../../components/dyrejournal/listPrescriptionMini';
import DyrejournalJournalListMini from '../../components/dyrejournal/listJournalMini';

import ImageScroller from '../../components/imagescroller';

@observer
class NewJournal extends Component {
	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('journal', 'Journal basics'),
            sectionsMedical: fields.get('journal', 'Journal medical'),
            sectionsClinical: fields.get('journal', 'Journal clinical'),
            sectionsPassport: fields.get('journal', 'Journal passport'),
            sectionsExtra: fields.get('journal', 'Journal extra'),
            sectionsMedia: fields.get('journal', 'Journal media'),
        };
        this.searchTimer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { userStore, saleStore, journalStore } = this.props.stores;
        const { id, isNew, animals = [] } = props;
// console.log('loadAll', { animals });
        journalStore.createNewJournal();
        // Load active journals for active animals.
        // const { newSale } = saleStore;
        // const { animals } = newSale;
        const { newJournal = {} } = journalStore;
        const animalsIds = animals.map(animal => animal.id);
        await this.loadJournals(animalsIds);
        await this.loadJournal(id, isNew);
        await journalStore.loadVeterinaries();
        if (isNew) {
            const { user } = userStore;
            if (!newJournal.veterinary) {
                journalStore.updateObjectKeyValue('newJournal', 'veterinary', user.id);
            }
        }
    }

    loadJournal = async (id, isNew) => {
        const { saleStore, journalStore, animalStore, calendarEventStore } = this.props.stores;
        const { animals = [], calendarEventId } = this.props;
        if (isNew) {
            if (calendarEventId) {
                const currentCalendarEvents = await calendarEventStore.load(calendarEventId, false, { skipUpdate: true });
                const currentCalendarEvent = currentCalendarEvents[0];
                if (currentCalendarEvent && currentCalendarEvent.title) {
                    const { newJournal } = journalStore;
                    const { chiefComplaint } = newJournal;
                    journalStore.updateObjectKeyValue('newJournal', 'chiefComplaint', `${chiefComplaint ? `${chiefComplaint} ` :  ''}${currentCalendarEvent.title} ${currentCalendarEvent.body}`);
                }
            }
            const { newSale } = saleStore;
            const { visitors } = newSale;
            journalStore.updateObjectKeyValue('newJournal', 'date', new Date());
            if (newSale.id) {
                journalStore.updateObjectKeyValue('newJournal', 'sale', newSale.id);
            }
            if (visitors.length > 0) {
                journalStore.updateObjectKeyValue('newJournal', 'visitor', visitors[0].id);
            }
            if (animals.length > 0) {
                journalStore.updateObjectKeyValue('newJournal', 'animal', animals[0].id);
            }
        } else {
            const newJournals = await journalStore.load(parseInt(id, 10), false, { skipUpdate: true });
            journalStore.updateKeyValue('newJournal', newJournals[0]);
            const newJournal = newJournals[0];
            if (newJournal) {
                const { animal } = newJournal;
                await animalStore.load(animal);
                if (newJournal.id) {
                    await this.loadJournals([animal], newJournal.id);
                }
            }
        }
    }

    loadJournals = async animalIds => {
        const { journalStore, saleStore } = this.props.stores;
        await journalStore.load({
            query: {
                animal: animalIds,
            },
        });
    }

    saveJournal = async e => {
        this.setState({
            loading: true,
        });
        const { saveSale = () => {} } = this.props;
        const { journalStore } = this.props.stores;
        const { newJournal } = journalStore;
        const hasSale = newJournal.sale;
        // if (!hasSale) {
        //     this.setState({
        //         loading: false,
        //     });
        //     return false;
        // }

        if (newJournal.id) {
            await journalStore.save(newJournal);
        } else {
            const response = await journalStore.insert(newJournal);
            // Update current newJournal object
            const journal = response?.data?.journal;
            journalStore.updateObjectKeyValue('newJournal', 'id', journal?.id);
            journalStore.updateObjectKeyValue('newJournal', 'journalNumber', journal?.journalNumber);
        }
        await saveSale({ calledFrom: 'newJournal.saveJournal' });
        this.setState({
            loading: false,
        });
        return true;
    }

    closeJournal = async () => {
        if (!confirm('Er du sikker på at du vil lukke og låse journalen?')) {
            return false;
        }

        const {
            drawerLevel = 1,
        } = this.props;
        const { journalStore, appState } = this.props.stores;
        journalStore.updateObjectKeyValue('newJournal', 'closed', new Date());
        await this.saveJournal();
        const { newJournal } = journalStore;
        const { callback = () => {} } = this.props;
        callback(newJournal);
        appState.toggleDrawerRight(false, drawerLevel);
    }

    createJournal = async () => {
        this.setState({
            loading: true,
        });
        const { callback = () => {} } = this.props;
        const { journalStore } = this.props.stores;
        await this.saveJournal();
        const { newJournal } = journalStore;
        callback(newJournal, true);
        this.setState({
            loading: false,
        });
    }

    onImageClick = e => {
        e.stopPropagation();
        e.preventDefault();
        const { image } = e.target.dataset;
        const {
            drawerLevel = 1,
        } = this.props;
        const { appState } = this.props.stores;

        appState.openDrawerRight('viewImage', {
            image,
            callback: async () => {
                // console.log('onClickEditJournal callback');
            },
        }, drawerLevel + 1);
    }

    onKeyDown = e => {
        // console.log('onKeyDown', e);
        // const { value } = e.target;
        // // Remove old timer
        // if (this.aiTimer) {
        //     clearTimeout(this.aiTimer);
        // }
        // this.aiTimer = setTimeout(() => {
        //     this.aiLookup(value);
        // }, 1000);
    }

    aiLookup = async message => {
        const { journalStore } = this.props.stores;
        await journalStore.queryAi(message);
    }


    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { userStore, journalStore, saleStore, animalStore } = this.props.stores;
        // const { animal } = animalStore;
        const {
            week,
            isNew,
            hideAdmin = true,
            drawerLevel,
            saveSale = () => {},
            saleIsLocked = false,
            animals = [],
        } = this.props;
        const { sections, sectionsClinical, sectionsMedical, sectionsPassport, sectionsMedia, sectionsExtra, loading, currentCalendarEvent } = this.state;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { newJournal = {} } = journalStore;
        const { newSale } = saleStore;
        const { prescriptions } = newJournal;
        const { journals, animals: journalAnimals } = journalStore;
        const { animal: currentAnimal } = animalStore;
        const animal = journalAnimals.find(a => a.id === newJournal.animal) || currentAnimal;

        const finalAnimals = animals.length > 0 ? animals : [animal];

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 40px; padding-bottom: 100px;'>
                    {/* <div class='position-absolute' style='top: 5px; right: 10px; height: 40px;'>
                    </div> */}
                    <div class='font-weight-lighter px-3 box-header'>
                        {isNew ? <Text id='dyrejournal.journal.new'>New Journal</Text> : <Text id='dyrejournal.journal.edit'>Edit Journal</Text>}
                        {newJournal.id && <>: {newJournal.id}</>}
                    </div>

                    <div class='font-weight-lighter text-muted mx-4 mt-5'>
                        <small class='text-uppercase ml-3'>
                            <Text id='dyrejournal.journal.animal'>Animal</Text>
                        </small>
                    </div>
                    <div class={`d-flex flex-column mx-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                        {finalAnimals.map(e => {
                            return (<>
                                <div class={`d-flex flex-row justify-content-between px-3 py-0 animal-line`}>
                                    <div class='flex-fill'>
                                        {e.name} ({e.species}/{e.breed})
                                    </div>
                                    <div class='ml-2'>
                                        {e.birth && util.age(e.birth)}
                                    </div>
                                </div>
                                {e.insuranceId && <>
                                    <div class={`d-flex flex-row justify-content-between px-3 py-0 animal-line`}>
                                        <div class='flex-fill'>
                                            {e.insuranceId} {e.insuranceCompany ? `(${e.insuranceCompany})` : ''}
                                        </div>
                                    </div>
                                </>}
                            </>);
                        })}

                    </div>

                    {(journals && journals.length > 0 && drawerLevel <= 3) &&<DyrejournalJournalListMini
                        classNames='mx-1 mt-3'
                        stores={this.props.stores}
                        journals={journals}
                        drawerLevel={drawerLevel}
                        collapse={true}
                        viewOnly={true}
                    />}

                    <div class='bg-light d-flex flex-column justify-content-start pt-3'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sections}
                            holdingStore={journalStore}
                            // objectName={isNew ? 'newJournal' : 'journal'}
                            objectName={'newJournal'}
                            isNew={newJournal.id ? false : true}
                            hideAdmin={hideAdmin}
                            drawerLevel={drawerLevel}
                            // isAdmin={true}
                            // filter={field => {
                            // }}
                        />
                    </div>

                    <div class='font-weight-lighter text-muted mx-4 mt-5'>
                        <small class='text-uppercase ml-3'>
                            <Text id='dyrejournal.journal.prescriptions'>Journal prescriptions</Text>
                        </small>
                    </div>
                    <DyrejournalPrescriptionListMini
                        classNames='mx-4'
                        stores={this.props.stores}
                        prescriptions={prescriptions}
                        saleIsLocked={saleIsLocked}
                        saveJournal={this.saveJournal}
                        drawerLevel={drawerLevel}
                        journal={newJournal.id}
                    />

                    <div class='bg-light d-flex flex-column justify-content-start mt-4'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sectionsClinical}
                            holdingStore={journalStore}
                            // objectName={isNew ? 'newJournal' : 'journal'}
                            objectName={'newJournal'}
                            isNew={newJournal.id ? false : true}
                            hideAdmin={hideAdmin}
                            drawerLevel={drawerLevel}
                            // isAdmin={true}
                            // filter={field => {
                            // }}
                        />
                    </div>

                    <div class='bg-light d-flex flex-column justify-content-start mt-4'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sectionsMedical}
                            holdingStore={journalStore}
                            // objectName={isNew ? 'newJournal' : 'journal'}
                            objectName={'newJournal'}
                            isNew={newJournal.id ? false : true}
                            hideAdmin={hideAdmin}
                            drawerLevel={drawerLevel}
                            // isAdmin={true}
                            onKeyDown={this.onKeyDown}
                            // filter={field => {
                            // }}
                        />
                    </div>

                    <div class='bg-light d-flex flex-column justify-content-start mt-4'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sectionsPassport}
                            holdingStore={journalStore}
                            // objectName={isNew ? 'newJournal' : 'journal'}
                            objectName={'newJournal'}
                            isNew={newJournal.id ? false : true}
                            hideAdmin={hideAdmin}
                            drawerLevel={drawerLevel}
                            // isAdmin={true}
                            // filter={field => {
                            // }}
                        />
                    </div>

                    {/* <div class='bg-light d-flex flex-column justify-content-start mt-4'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sectionsExtra}
                            holdingStore={journalStore}
                            // objectName={isNew ? 'newJournal' : 'journal'}
                            objectName={'newJournal'}
                            isNew={newJournal.id ? false : true}
                            hideAdmin={hideAdmin}
                            drawerLevel={drawerLevel}
                            isAdmin={true}
                            // filter={field => {
                            // }}
                        />
                    </div> */}

                    {newJournal.images && newJournal.images.length > 0 && <>
                        <div class='w-100 d-flex flex-column mt-5'>
                            <div class='px-0 box-header-info d-flex flex-column'>
                                <div class='d-flex'>
                                    {/* {JSON.stringify(part)} */}
                                    <ImageScroller
                                        images={newJournal.images}
                                        stores={this.props.stores}
                                        maxHeight={'60vh'}
                                        showImg={img => localUtil.displayImg(img, this.props, '1920x')}
                                        onImageClick={this.onImageClick}
                                    />
                                </div>
                            </div>
                        </div>
                    </>}

                    <div class='bg-light d-flex flex-column justify-content-start mt-4'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sectionsMedia}
                            holdingStore={journalStore}
                            // objectName={isNew ? 'newJournal' : 'journal'}
                            objectName={'newJournal'}
                            isNew={newJournal.id ? false : true}
                            hideAdmin={hideAdmin}
                            drawerLevel={drawerLevel}
                            // isAdmin={true}
                            // filter={field => {
                            // }}
                        />
                    </div>

                    <div class='w-100 d-flex flex-row justify-content-between mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class={`btn btn-${newJournal.id ? 'success' : 'primary'} flex-fill rounded-pill mr-3`} onClick={isNew ? this.createJournal : this.saveJournal} disabled={loading}>
                            <i class='fa-duotone fa-save mr-2' />
                            {(isNew && !newJournal.id) ? <Text id='journal.create'>Create journal</Text> : <Text id='journal.save'>Save journal</Text>}
                            {loading && <i class='fa-regular fa-spinner-third fa-spin ml-2' />}
                        </button>
                        {!isNew && <button type='button' class='btn btn-danger flex-fill ml-3 rounded-pill' onClick={this.closeJournal} disabled={loading}>
                            <i class='fa-duotone fa-lock mr-2' />
                            <Text id='journal.close'>Close journal</Text>
                        </button>}
                    </div>

                </div>
            </div>
        );
    }
}

export default NewJournal;
