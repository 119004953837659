import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class JournalStore extends LocalModel {
    constructor() {
        super('journal', {
            namePlural: 'journals',
            sort: '-date -createdDate',
            limit: 500,
            api: {
                search: {
                    url: '/api/journals/',
                    params: {
                        limit: 15,
                        sort: '-date -createdDate',
                    },
                },
                load: {
                    url: '/api/journals/',
                    params: {},
                },
                save: {
                    url: '/api/journals/',
                    params: {},
                },
                delete: {
                    url: '/api/journals/',
                    params: {},
                },
            },
        });
    }

    @observable newJournal = {};

    @observable journal = {};

    @observable journals = [];

    @observable allJournals = [];

    @observable journalsToSend = [];

    @observable veterinaries = [];

    @observable animals = [];

    @observable visitors = [];

    @observable owners = [];

    @observable sales = [];

    @observable aiResponse = {};

    createNewJournal() {
        this.updateKeyValue('newJournal', {
            members: [],
            changes: [],
            views: [],
            prescriptions: [],
            diagnoses: [],
            referrals: [],
        });
    }

    getVeterinary(id) {
        return this.veterinaries.find(vet => vet.id === id);
    }

    async loadVeterinaries() {
        const response = await util.fetchApi(`/api/users/veterinaries/`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.updateKeyValue('veterinaries', response.data);
                return response;

        }
    }

    async sendJournalEmail(ids, toEmail, includeReceipt, subject, body) {
        const idParams = ids.map(id => `id=${id}`).join('&');
        const response = await util.fetchApi(`/api/journals/email/?${idParams}&toEmail=${toEmail}&includeReceipt=${includeReceipt}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                return response;
        }
    }

    async unlock(id) {
        const response = await util.fetchApi(`/api/journals/${id}`, { method: 'PATCH' }, {
            closed: 'null',
        });
        switch (response.status) {
            case 202:
                return response;
        }
    }

    async queryAi(message) {
        const response = await util.fetchApi(`/api/ai`, { method: 'POST' }, {
            message,
        });
        switch (response.status) {
            case 200:
                this.updateKeyValue('aiResponse', response.data);
                return response;

        }
    }

}

const store = new JournalStore();
export default store;
