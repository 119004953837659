import { h, render, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { IntlProvider, Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';
import linkstate from 'linkstate';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalAnimalListMini from '../../components/dyrejournal/listAnimalMini';
import DyrejournalPrescriptionListMini from '../../components/dyrejournal/listPrescriptionMini';
import DyrejournalJournalPrint from '../../components/dyrejournal/journalPrint';

import ImageScroller from '../../components/imagescroller';

import definitionNo from '../../languages/no.json';
import definitionEn from '../../languages/en.json';

const countryMap = {
    no: definitionNo,
    en: definitionEn,
    default: definitionEn,
};

@observer
class SendJournal extends Component {
	constructor(props) {
        super(props);
        this.state = {
            toEmail: '',
            subject: '',
            body: '',
        };
        this.searchTimer = null;
    }

    onClickPrintJournal = e => {
        // console.log('onClickPrintSale', e);
        const { journals = [] } = this.props;
        const { userStore } = this.props.stores;
        const { user } = userStore;
        const { language = 'no' } = user;
        const languageDef = countryMap[language || 'default'];

        // Render Receipt component with data
        const receipt = <IntlProvider definition={languageDef}>
            <DyrejournalJournalPrint stores={this.props.stores} journals={journals} />
        </IntlProvider>;

        // Append to document
        const printDiv = document.createElement('div');
        printDiv.className = 'receipt';
        document.body.appendChild(printDiv);
        render(receipt, printDiv);
        // Trigger print dialog
        window.print();
        // Remove the appended div
        document.body.removeChild(printDiv);
        // Re-render the receipt component to reset it
        render(null, printDiv);
    }

    onClickEmailJournal = async e => {
        this.setState({
            journalSent: false,
            journalSending: true,
        });
        const { toEmail, includeReceipt, subject, body } = this.state;
        const { journals = [] } = this.props;
        const journalIds = journals.map(journal => journal.id);
        const { journalStore } = this.props.stores;
        await journalStore.sendJournalEmail(journalIds, toEmail, includeReceipt, subject, body);
        this.setState({
            journalSent: true,
            journalSending: false,
        });
    }

    loadAll = async () => {
        const { animalStore, visitorStore, journalStore, saleStore } = this.props.stores;
        const { journals } = this.props;

        // Load journals
        const journalIds = journals.map(journal => journal.id);
        const journalsToSend = await journalStore.load({
            query: {
                id: journalIds,
            },
            skipUpdate: true,
        });
        journalStore.updateKeyValue('journalsToSend', journalsToSend);

        // Load animals
        const animalIds = journals.map(journal => journal.animal);
        let animals = [];
        if (animalIds.length > 0) {
            animals = await animalStore.load({
                query: {
                    id: animalIds,
                },
                skipUpdate: true,
            });
            journalStore.updateKeyValue('animals', animals);
        }

        // Load owners
        const ownerIds = animals.map(animal => animal.owner);
        if (ownerIds.length > 0) {
            const owners = await visitorStore.load({
                query: {
                    id: ownerIds,
                },
                skipUpdate: true,
            });
            journalStore.updateKeyValue('owners', owners);
        }

        // Load sale
        const sales = await saleStore.load({
            query: {
                journal: journalIds,
            },
            skipUpdate: true,
        });
        journalStore.updateKeyValue('sales', sales);
    }

    searchInput = (e) => {
        const search = e.target.value;
        this.setState({ search, toEmail: search });

        clearTimeout(this.searchTimer);
        if (search.length >= 3) {
            this.searchTimer = setTimeout(async () => {
                this.doSearch(search);
            }, 300);
        }
    }

    async doSearch(search) {
        // return;
        const { contactStore } = this.props.stores;
        if (search) {
            // console.log('doUpdateField.saveField', id, field, value);
            const result = await contactStore.load({ query: { search }, limit: 500, skipUpdate: false });
        }
    }

    clearSearch = () => {
        const { contactStore } = this.props.stores;
        this.setState({ search: '', toEmail: '' });
        contactStore.updateKeyValue('contacts', []);
        this.doSearch('');
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.doSearch(this.state.search);
        } else if (e.key === 'Escape') {
            e.preventDefault();
            this.clearSearch();
        }
    }

    onClickContact = e => {
        const { contactStore } = this.props.stores;
        const { email } = e.target.closest('tr').dataset;
        this.setState({ search: email, toEmail: email }, () => {
            contactStore.updateKeyValue('contacts', []);
        });
    }

    onClickEditContact = e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('tr').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState, contactStore } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('editContact', {
            height: drawerHeightMedium,
            id,
            callback: () => {
                appState.toggleDrawer(false, drawerLevel + 1);
            },
        }, drawerLevel + 1);
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { journalSent, journalSending, toEmail } = this.state;
        const {
            week,
            isNew,
            hideAdmin = true,
            drawerLevel = 1,
            showJournals = true,
            journals = [],
        } = this.props;
        const { appState, userStore, animalStore, journalStore, contactStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { animals, owners, journalsToSend, sales } = journalStore;

        const { contacts } = contactStore;

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class='font-weight-lighter px-3 box-header'>
                        <Text id='dyrejournal.journal.send'>Send Journals</Text>
                    </div>

                    <div class={`w-100 px-2 py-2 px-3 mt-2 d-flex flex-row ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg`}>
                        <div class='w-20 font-weight-bold'>
                            <Text id='dyrejournal.animal-owner'>Owner</Text>
                        </div>
                        <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                            {owners.map(owner => {
                                return (<>
                                    <div class='d-flex flex-column justify-content-start'>
                                        <div class=''>
                                            {owner.firstname} {owner.lastname}
                                        </div>
                                        <div class=''>
                                            {owner.address}
                                        </div>
                                        <div class=''>
                                            {fields.getNumberFixedWidth(owner.postalcode)} {owner.place}
                                        </div>
                                        <div class=''>
                                            {owner.email}
                                        </div>
                                        <div class=''>
                                            {owner.cellphone}
                                        </div>
                                    </div>
                                </>);
                            })}
                            {/* <xmp>{JSON.stringify(owners, null, 4)}</xmp> */}
                        </div>
                    </div>

                    <div class={`w-100 px-3 py-2 mt-3 d-flex flex-row ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg`}>
                        <div class='w-20 font-weight-bold'>
                            <Text id='dyrejournal.animal-title'>Animal</Text>
                        </div>
                        <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                            {animals.map(animal => <>
                                {animal.name}, {animal.species}, {animal.breed}, {animal.birth ? `f. ${util.formatDate(animal.birth, { locale: 'nb-NO' })} (${util.age(animal.birth)})` : ''}
                            </>)}
                            {/* <xmp>{JSON.stringify(animal, null, 4)}</xmp> */}
                        </div>
                    </div>


                    {journalsToSend.length > 0 && journalsToSend.map(journal => {
                        return (<>
                            <div class={`w-100 px-3 py-2 mt-3 d-flex flex-column ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg`}>
                                <div class='d-flex flex-row justify-content-start'>
                                    <div class='w-20 font-weight-bold'>
                                        <Text id='dyrejournal.journal-number'>Journal number</Text>
                                    </div>
                                    <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                        {journal.journalNumber || journal.id}
                                    </div>
                                </div>
                                <div class='d-flex flex-row justify-content-start mt-3'>
                                    <div class='w-20 font-weight-bold'>
                                        <Text id='dyrejournal.responsible-title'>Responsible</Text>
                                    </div>
                                    <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                        {journal.veterinaryName}
                                    </div>
                                </div>
                                <div class='d-flex flex-row justify-content-start mt-3'>
                                    <div class='w-20 font-weight-bold'>
                                        <Text id='animal.journal-chiefComplaint'>chiefComplaint</Text>
                                    </div>
                                    <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                        {journal.chiefComplaint}
                                    </div>
                                </div>
                                <div class='d-flex flex-row justify-content-start mt-3'>
                                    <div class='w-20 font-weight-bold'>
                                        <Text id='animal.journal-generalCondition'>generalCondition</Text>
                                    </div>
                                    <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                        {journal.generalCondition}
                                    </div>
                                </div>
                                <div class='d-flex flex-row justify-content-start mt-3'>
                                    <div class='w-20 font-weight-bold'>
                                        <Text id='animal.journal-physicalExaminationFindings'>physicalExaminationFindings</Text>
                                    </div>
                                    <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                        {journal.physicalExaminationFindings}
                                    </div>
                                </div>
                                {journal.diagnosis && <>
                                    <div class='d-flex flex-row justify-content-start mt-3'>
                                        <div class='w-20 font-weight-bold'>
                                            <Text id='animal.journal-diagnosis'>diagnosis</Text>
                                        </div>
                                        <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                            {journal.diagnosis}
                                        </div>
                                    </div>
                                </>}
                                {journal.diagnosisCode && <>
                                    <div class='d-flex flex-row justify-content-start mt-3'>
                                        <div class='w-20 font-weight-bold'>
                                            <Text id='animal.journal-diagnosisCode'>diagnosisCode</Text>
                                        </div>
                                        <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                            {journal.diagnosisCode}
                                        </div>
                                    </div>
                                </>}
                                {journal.diagnosticTests && <>
                                    <div class='d-flex flex-row justify-content-start mt-3'>
                                        <div class='w-20 font-weight-bold'>
                                            <Text id='animal.journal-diagnosticTests'>diagnosticTests</Text>
                                        </div>
                                        <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                            {journal.diagnosticTests}
                                        </div>
                                    </div>
                                </>}
                                {journal.treatmentPlan && <>
                                    <div class='d-flex flex-row justify-content-start mt-3'>
                                        <div class='w-20 font-weight-bold'>
                                            <Text id='animal.journal-treatmentPlan'>treatmentPlan</Text>
                                        </div>
                                        <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                            {journal.treatmentPlan}
                                        </div>
                                    </div>
                                </>}
                                {journal.clientCommunication && <>
                                    <div class='d-flex flex-row justify-content-start mt-3'>
                                        <div class='w-20 font-weight-bold'>
                                            <Text id='animal.journal-clientCommunication'>clientCommunication</Text>
                                        </div>
                                        <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                            {journal.clientCommunication}
                                        </div>
                                    </div>
                                </>}
                                {journal.recommendations && <>
                                    <div class='d-flex flex-row justify-content-start mt-3'>
                                        <div class='w-20 font-weight-bold'>
                                            <Text id='animal.journal-recommendations'>recommendations</Text>
                                        </div>
                                        <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                            {journal.recommendations}
                                        </div>
                                    </div>
                                </>}
                                {journal.followUp && <>
                                    <div class='d-flex flex-row justify-content-start mt-3'>
                                        <div class='w-20 font-weight-bold'>
                                            <Text id='animal.journal-followUp'>followUp</Text>
                                        </div>
                                        <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                            {journal.followUp}
                                        </div>
                                    </div>
                                </>}
                                {journal.comment && <>
                                    <div class='d-flex flex-row justify-content-start mt-3'>
                                        <div class='w-20 font-weight-bold'>
                                            <Text id='animal.journal-comment'>comment</Text>
                                        </div>
                                        <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                            {journal.comment}
                                        </div>
                                    </div>
                                </>}
                                {journal.foodType && <>
                                    <div class='d-flex flex-row justify-content-start mt-3'>
                                        <div class='w-20 font-weight-bold'>
                                            <Text id='animal.journal-foodType'>foodType</Text>
                                        </div>
                                        <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                            {journal.foodType}
                                        </div>
                                    </div>
                                </>}

                                {journal.images && journal.images.length > 0 ? <>
                                    <div class='d-flex flex-row justify-content-start mt-3'>
                                        <div class='w-20 font-weight-bold'>
                                            <Text id='animal.journal-images'>images</Text>
                                        </div>
                                        <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                            {journal.images.map(image => <>
                                                <a href={image.original} target='_blank'>
                                                    <img src={image.s3MediumLink} style="max-width: 50%;" />
                                                </a>
                                            </>)}
                                        </div>
                                    </div>
                                </> : ''}

                                {/* <xmp>{JSON.stringify(journal, null, 4)}</xmp> */}
                            </div>
                        </>);
                    })}

                    <div class={`w-100 px-3 py-2 mt-2 d-flex flex-row`}>
                        <button
                            class={`btn btn-info ml-2 rounded-pill`}
                            onClick={this.onClickPrintJournal}
                        >
                            <i class='fa-solid fa-print' /> <Text id='dyrejournal.print-journal'>Print journal</Text>
                        </button>
                        <button
                            class={`btn ${journalSent ? 'btn-success' : 'btn-info'} ml-2 rounded-pill`}
                            onClick={this.onClickEmailJournal}
                            disabled={journalSending}
                        >
                            {journalSending ? <>
                                <i class='fa-solid fa-spinner-third fa-spin' /> <Text id='dyrejournal.email-journal-sending'>Sending email...</Text>
                            </> : <>
                                {journalSent ? <>
                                    <i class='fa-solid fa-check' /> <Text id='dyrejournal.journal-sent'>Journal sent</Text>
                                </> : <>
                                    <i class='fa-solid fa-paper-plane' /> <Text id='dyrejournal.email-journal-to-customer'>Email journal to customer</Text>
                                </>}
                            </>}
                        </button>
                    </div>

                    <div class={`w-100 px-3 py-2 mt-2 d-flex flex-column`}>
                        <div class='form-inline'>
                            <label class='sr-only'>Email</label>
                            <div class='input-group mb-2 mr-sm-2'>
                                <div class='input-group-prepend'>
                                    <div class='input-group-text rounded-pill-left'>@</div>
                                </div>
                                <input
                                    type='text'
                                    class='form-control rounded-pill-right'
                                    placeholder='Email'
                                    value={toEmail}
                                    onInput={this.searchInput}
                                    onKeyDown={this.handleKeyDown}
                                />
                            </div>
                        </div>

                        {contacts && contacts.length > 0 && <>
                            <div class='d-flex w-100'>
                                <div class='table-responsive'>
                                    <table class={`table table-sm table-hover ${darkmode ? 'table-dark' : 'table-light'}`}>
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th><Text id='input.name'>Name</Text></th>
                                                <th><Text id='input.email'>Email</Text></th>
                                                <th><Text id='input.cellphone'>Cellphone</Text></th>
                                                <th><Text id='input.description'>Description</Text></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contacts.map(contact => {
                                                return <tr
                                                    data-email={contact.email}
                                                    data-id={contact.id}
                                                    onClick={this.onClickContact}
                                                >
                                                    <td class='d-none d-sm-table-cell' onClick={this.onClickEditContact}>
                                                        <i class='fa-solid fa-pen' />
                                                    </td>
                                                    <td>{contact.name ? `${contact.name}` : `${contact.firstname} ${contact.lastname}`}</td>
                                                    <td>{contact.email}</td>
                                                    <td>{contact.cellphone}</td>
                                                    <td>{contact.description}</td>
                                                </tr>;
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>}

                        <div class='form-inline'>
                            <label class='sr-only'>Subject</label>
                            <div class='input-group mb-2 mr-sm-2'>
                                <div class='input-group-prepend'>
                                    <div class='input-group-text rounded-pill-left'>S</div>
                                </div>
                                <input type='text' class='form-control rounded-pill-right' placeholder='Subject' onInput={linkstate(this, 'subject')} />
                            </div>
                        </div>
                        <div class='form'>
                            <label class='sr-only'>Body</label>
                            <div class='input-group mb-2 mr-sm-2'>
                                <div class='input-group-prepend'>
                                    <div class='input-group-text rounded-pill-left'>B</div>
                                </div>
                                <textarea type='text' class='form-control rounded-pill-right' placeholder='Body' onInput={linkstate(this, 'body')} />
                            </div>
                        </div>

                        <button
                            type='button'
                            class={`btn ${journalSent ? 'btn-success' : 'btn-primary'} mb-2 rounded-pill`}
                            onClick={this.onClickEmailJournal}
                            disabled={journalSending}
                        >
                            {journalSending ? <>
                                <i class='fa-solid fa-spinner-third fa-spin' /> <Text id='dyrejournal.email-journal-sending'>Sending email...</Text>
                            </> : <>
                                {journalSent ? <>
                                    <i class='fa-solid fa-check' /> <Text id='dyrejournal.journal-sent'>Journal sent</Text>
                                </> : <>
                                    <i class='fa-solid fa-paper-plane' /> <Text id='dyrejournal.email-journal-to-address'>Email journal to address</Text>
                                </>}
                            </>}
                        </button>

                    </div>

                    <div class={`w-100 px-3 py-2 mt-2 d-flex flex-row`}>
                        <div class='form-check mb-2 mr-sm-2'>
                            <input
                                id='includeReceipt'
                                class='form-check-input'
                                type='checkbox'
                                onInput={linkstate(this, 'includeReceipt')}
                            />
                            <label class='form-check-label' for='includeReceipt'>
                                <Text id='dyrejournal.journal-send-include-receipts'>Include receipt/invoice</Text>
                            </label>
                        </div>
                    </div>


                    <div class='font-weight-lighter px-3 box-header mt-4'>
                        <Text id='dyrejournal.journal.related-sales'>Related sales</Text>
                    </div>

                    <div class={`w-100 px-2 py-2 px-3 mt-2 d-flex flex-row ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg`}>
                        <div class={`w-100 px-3 py-2 mt-2 d-flex flex-column`}>
                            {sales && sales.length > 0 && sales.map(sale => <>
                                <div>
                                    {util.formatDate(sale.saleDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}: {sale.title}
                                </div>
                            </>)}
                        </div>
                    </div>

                    {/* {journalSent && <div class='w-100 d-flex px-3 mt-4 justify-content-center'>
                        <div class='alert alert-success rounded-pill' role='alert'>
                            <Text id='dyrejournal.journal-sent'>Journal sent</Text>
                        </div>
                    </div>} */}

                </div>
            </div>
        );
    }
}

export default SendJournal;
