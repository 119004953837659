import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import linkstate from 'linkstate';
import md5 from 'crypto-js/md5';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class NewApplicationOffer extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('applicationOffer'),
        };
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { section, field, type, id } = this.props;
        const { applicationOfferStore } = this.props.stores;
        if (emptyFirst) {
            applicationOfferStore.updateKeyValue('applicationOffer', {});
        }
        await applicationOfferStore.load(id, false, { addData: ['tags'], query: {} });
        const { applicationOffer } = applicationOfferStore;
        if (!applicationOffer.fieldsOpts) {
            await applicationOfferStore.save({
                id,
                fieldsOpts: {
                    json: '{}',
                    md5: md5(JSON.stringify({ id, date: util.isoDate() })).toString(),
                },
            });
            await applicationOfferStore.load(id, false, { addData: ['tags'], query: {} });
        }

        const { fieldsOpts } = applicationOffer;
        const localFieldsOpts = [...fieldsOpts];
        const firstField = localFieldsOpts[0];

        let value = {};
        try {
            value = JSON.parse(firstField.json);
        } catch (e) {
            console.error(e)
        }
        // const currentField = value[`${section}.${field}`];
        this.setState({
            firstField,
            value,
            // currentField,
        });
    }

    saveSettings = async () => {
        const { section, field, type, id } = this.props;
        const { callback = () => {} } = this.props;
        const { firstField, value } = this.state;
        const { applicationOfferStore } = this.props.stores;

        // {
        //     "editFieldsOptsByMd5":" 1a9bd3f81ecb39a8153dcfb08a8dc760",
        //     "editFieldsOptsKey": "json",
        //     "editFieldsOptsValue": "{ \n    \"submittedApplication.user\": { \"help\": \"User field help from fieldOpts\" } \n}\n",
        //     "editFieldsOptsType": "textarea",
        //     "version": 407
        // }
        const response = await applicationOfferStore.save({
            id,
            editFieldsOptsByMd5: firstField.md5,
            editFieldsOptsKey: 'json',
            editFieldsOptsValue: JSON.stringify(value),
            editFieldsOptsType: 'textarea',
        });

        callback();
    }

    updateValue = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { name, value: inputValue } = e.target;
        const { section, field } = this.props;
        const { value } = this.state;
        const newValue = { ...value };
        newValue[`${section}.${field}`] = { ...newValue[`${section}.${field}`], [name]: inputValue };
        this.setState({ value: newValue });
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { section, field, type, id } = this.props;
        const { firstField, value = {} } = this.state;
        const { userStore, applicationOfferStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { applicationOffer } = applicationOfferStore;
        const { fieldsOpts = [] } = applicationOffer;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');


        // const currentFieldOpt = fieldsOpts.find(opt => opt.id === id);


        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        <Text id='applicationOffer.editSettings'>Eidt Application Offer Settings</Text>: {section}.{field}
                    </div>
                    <div class='w-100 d-flex flex-column justify-content-start'>
                        <div class='bg-light d-flex flex-column justify-content-start h-100 pt-4'>



                            {/* <xmp>{JSON.stringify({ section, field, type, id }, null, 4)}</xmp> */}
                            {/* <xmp>{JSON.stringify({ firstField }, null, 4)}</xmp> */}
                            {/* <xmp>{JSON.stringify({ value }, null, 4)}</xmp> */}

                            <div class='form-group'>
                                <label for='title'>Title</label>
                                <input
                                    type='text'
                                    class='form-control'
                                    placeholder='Title text'
                                    id='title'
                                    name='title'
                                    data-field={`${section}.${field}`}
                                    value={value[`${section}.${field}`] ? value[`${section}.${field}`].title : ''}
                                    onInput={this.updateValue}
                                />
                            </div>
                            <div class='form-group'>
                                <label for='placeholder'>Placeholder</label>
                                <input
                                    type='text'
                                    class='form-control'
                                    placeholder='Placeholder text'
                                    id='placeholder'
                                    name='placeholder'
                                    data-field={`${section}.${field}`}
                                    value={value[`${section}.${field}`] ? value[`${section}.${field}`].placeholder : ''}
                                    onInput={this.updateValue}
                                />
                            </div>
                            <div class='form-group'>
                                <label for='help'>Help</label>
                                <input
                                    type='text'
                                    class='form-control'
                                    placeholder='Help text'
                                    id='help'
                                    name='help'
                                    data-field={`${section}.${field}`}
                                    value={value[`${section}.${field}`] ? value[`${section}.${field}`].help : ''}
                                    onInput={this.updateValue}
                                />
                            </div>
                            {type === 'number' ? <>
                                <div class='form-group'>
                                    <label for='min'>Min</label>
                                    <input
                                        type='number'
                                        class='form-control'
                                        placeholder='Min value'
                                        id='min'
                                        name='min'
                                        data-field={`${section}.${field}`}
                                        value={value[`${section}.${field}`] ? value[`${section}.${field}`].min : ''}
                                        onInput={this.updateValue}
                                    />
                                </div>
                                <div class='form-group'>
                                    <label for='max'>Max</label>
                                    <input
                                        type='number'
                                        class='form-control'
                                        placeholder='Max value'
                                        id='max'
                                        name='max'
                                        data-field={`${section}.${field}`}
                                        value={value[`${section}.${field}`] ? value[`${section}.${field}`].max : ''}
                                        onInput={this.updateValue}
                                    />
                                </div>
                            </> : <></>}

                            {/* <FormFields
                                stores={this.props.stores}
                                sections={sections}
                                holdingStore={applicationOfferStore}
                                objectName={isNew ? 'newApplicationOffer' : 'applicationOffer'}
                                isNew={isNew}
                                drawerLevel={drawerLevel}
                                isAdmin={false}
                            /> */}
                        </div>
                    </div>
                    <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class='btn btn-primary rounded-pill' onClick={this.saveSettings}>
                            <Text id='applicationOffer.save-settings'>Save settings</Text>
                        </button>
                    </div>
                </div>
            </div>
        </>);
    }
}

export default NewApplicationOffer;
