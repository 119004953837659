import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class AnimalStore extends LocalModel {
    constructor() {
        super('animal', {
            namePlural: 'animals',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/animals/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/animals/',
                    params: {},
                },
                save: {
                    url: '/api/animals/',
                    params: {},
                },
                delete: {
                    url: '/api/animals/',
                    params: {},
                },
            },
        });
    }

    @observable newAnimal = {};

    @observable animal = {};

    @observable animals = [];

    @observable owners = [];

    @observable species = [];

    @observable breeds = [];

    @observable currentAnimals = [];

    @observable dyreIdApproved = false;

    @action
    setDyreIdApproved = (dyreIdApproved) => {
        this.dyreIdApproved = dyreIdApproved;
    }

    async loadSpecies() {
        this.updateKeyValue('species', []);
        const response = await util.fetchApi(`/api/animalspecies/`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.updateKeyValue('species', response.data);
                return response;
        }
    }

    async loadBreeds(speciesId) {
        this.updateKeyValue('breeds', []);
        const response = await util.fetchApi(`/api/animalbreeds/`, { method: 'GET' }, {
            speciesId
        });
        switch (response.status) {
            case 200:
                this.updateKeyValue('breeds', response.data);
                return response;
        }
    }

    async dyreIdGetChipInfo(chipId) {
        const response = await util.fetchApi(`/api/dyreid/${chipId}`, { method: 'GET' }, {
        });
        switch (response?.status) {
            case 200:
                // this.updateKeyValue('breeds', response.data);
                return response;
        }
    }

    async dyreIdGetSpeciesAndBreed() {
        const response = await util.fetchApi(`/api/dyreid/speciesandbreed`, { method: 'GET' }, {
        });
        switch (response?.status) {
            case 200:
                // this.updateKeyValue('breeds', response.data);
                return response;
        }
    }

    async dyreIdSearchOwnerPet(cellphone) {
        const response = await util.fetchApi(`/api/dyreid/searchownerpet/${cellphone}`, { method: 'GET' }, {
        });
        switch (response?.status) {
            case 200:
                // this.updateKeyValue('breeds', response.data);
                return response;
        }
    }

    async dyreIdGetOwnerPetsInfoByPhoneNumberEmail(cellphone) {
        const response = await util.fetchApi(`/api/dyreid/getownerpetsinfobyphonenumberemail/${cellphone}`, { method: 'GET' }, {
        });
        switch (response?.status) {
            case 200:
                // this.updateKeyValue('breeds', response.data);
                return response;
        }
    }

    async dyreIdRegisterPetOwnerInfoV1(animal) {
        const response = await util.fetchApi(`/api/dyreid/registerpetownerinfov1/${animal}`, { method: 'GET' }, {
        });
        switch (response?.status) {
            case 200:
                // this.updateKeyValue('breeds', response.data);
                this.setFeedback('chipId', 'ChipId is now registered with DyreID.');
                return response;
        }
    }

    async dyreIdChipValidationStatus(chipId) {
        const response = await util.fetchApi(`/api/dyreid/chipvalidationstatus/${chipId}`, { method: 'GET' }, {
        });
        switch (response?.status) {
            case 200:
                // this.updateKeyValue('breeds', response.data);
                return response;
        }
    }

}

const store = new AnimalStore();
export default store;
