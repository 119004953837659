import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';
import DyrejournalSalePrescription from '../../components/dyrejournal/salePrescription';

const fieldSorter = (fields) => (a, b) => fields.map(o => {
    let dir = 1;
    if (o[0] === '-') {
        dir = -1;
        o = o.substring(1);
    }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
}).reduce((p, n) => p ? p : n, 0);

@observer
class AnimalListMini extends Component {
	constructor(props) {
        super(props);
        this.state = {
            selectedJournals: [],
        };
    }

    onClickEditJournal = e => {
        e.stopPropagation();
        e.preventDefault();
        const { id, animal } = e.target.closest('.journalLine').dataset;
        const idInt = parseInt(id, 10);
        const animalInt = parseInt(animal, 10);

        const {
            drawerLevel = 1,
            saveSale = () => {},
            saleIsLocked = false,
        } = this.props;
		const { appState, saleStore, journalStore } = this.props.stores;
        const { newSale } = saleStore;
        const { animals } = newSale;

		appState.openDrawerRight('editJournal', {
            isNew: false,
            hideAdmin: true,
            saveSale,
            saleIsLocked,
            id: idInt,
            animals: animals.filter(a => a.id === animalInt),
            callback: async () => {
                // console.log('onClickEditJournal callback');
                const { newJournal } = journalStore;
                const { newSale } = saleStore;
                const { journals } = newSale;
                const idx = journals.findIndex(a => a.id === newJournal.id);
                if (idx > -1) {
                    saleStore.updateObjectKeyArrValue('newSale', 'journals', idx, newJournal);
                    await saveSale({ calledFrom: 'listJournalMini.onClickEditJournal.callback', fieldsToSave: ['journals'] });
                } else {
                    saleStore.updateObjectKeyValue('newSale', 'journals', [...journals, newJournal]);
                    await saveSale({ calledFrom: 'listJournalMini.onClickEditJournal.callback', fieldsToSave: ['journals'] });
                }
            },
		}, drawerLevel + 1);
    }

    onClickViewJournal = e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('.journalLine').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightLarge } = appState;

        appState.openDrawer('viewJournal', {
            hideAdmin: true,
            height: drawerHeightLarge,
            id: parseInt(id, 10),
        }, drawerLevel + 1);
    }

    selectJournal = e => {
        e.stopPropagation();
        // e.preventDefault();
        const { id } = e.target.closest('input').dataset;
        const idInt = parseInt(id, 10);
        const { selectedJournals } = this.state;
        const idx = selectedJournals.indexOf(idInt);
        if (idx > -1) {
            selectedJournals.splice(idx, 1);
        } else {
            selectedJournals.push(idInt);
        }
        this.setState({
            selectedJournals,
        });
    }

    sendJournals = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { selectedJournals } = this.state;
        const {
            journals = [],
            animals = [],
        } = this.props;
        const journalsToSend = journals.filter(j => selectedJournals.indexOf(j.id) > -1);

        const { drawerLevel = 1 } = this.props;
        const { appState, animalStore } = this.props.stores;
        const { drawerHeightLarge } = appState;
        const { animal } = animalStore;

        appState.openDrawer('sendJournal', {
            hideAdmin: true,
            height: drawerHeightLarge,
            journals: journalsToSend,
            animals: [animal],
        }, drawerLevel + 2);
    }

    // onClickPrintPrescriptoin = e => {
    //     // console.log('onClickPrintPrescriptoin', e);
    //     // Render Prescription component with data
    //     const prescription = <DyrejournalSalePrescription stores={this.props.stores} />;
    //     // Append to document
    //     const printDiv = document.createElement('div');
    //     printDiv.className = 'prescription';
    //     document.body.appendChild(printDiv);
    //     render(prescription, printDiv);
    //     // Trigger print dialog
    //     window.print();
    //     // Remove the appended div
    //     document.body.removeChild(printDiv);
    // // Re-render the receipt component to reset it
    // render(null, printDiv);
    // }

    componentDidMount() {
        const { collapse = false } = this.props;
        this.setState({
            collapsed: collapse,
            selectedJournals: [],
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.journals !== this.props.journals) {
            this.setState({
                selectedJournals: [],
            });
        }
    }

    render() {
        const { selectedJournals, collapsed = false } = this.state;
        const {
            animals = [],
            journals = [],
            classNames = '',
            showNewJournal = true,
            showNewBooking = true,
            journalsTitle,
            saleIsLocked,
            collapse = false,
            viewOnly = false,
        } = this.props;
        const { userStore, animalStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { owners } = animalStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            {journals && journals.length > 0 && <>
                <div class={`mt-2 mx-4 font-weight-lighter px-3 py-1 text-muted`}>
                    <small class='text-uppercase'>
                        <Text id='dyrejournal.animals-journals'>Animal journals</Text>
                    </small>
                </div>
                <div class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-3 d-flex flex-column py-3 px-3 box-container border`}>



                {/* <div class={`d-flex flex-column mx-3 px-2 py-3 mt-2 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}> */}

                {/* <div class='d-flex flex-column box-container mx-4 mt-2 bg-light p-3' style='border-radius: 1.0em !important;'> */}

                    <div class='d-flex overflow-hidden' style={`${collapsed ? 'max-height: 200px;' : ''}`}>
                        <div class='table-responsive'>
                            <table class='table table-striped table-sm'>
                                <thead class=''>
                                    <tr>
                                        <th scope='col'>&nbsp;</th>
                                        <th scope='col'><Text id='animal.journal-date'>Journal date</Text></th>
                                        <th scope='col' class='text-center'><Text id='animal.journal-weight'>Weight</Text></th>
                                        <th scope='col'><Text id='animal.journal-chiefComplaint'>chiefComplaint</Text></th>
                                        <th scope='col'><Text id='animal.journal-treatmentPlan'>treatmentPlan</Text></th>
                                        <th scope='col'>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {journals.sort(fieldSorter(['-date'])).map(j => {
                                        const diff = util.dateDiff(j.date);
                                        const lineSaleIsLocked = j.closed;
                                        const isSelected = selectedJournals.indexOf(j.id) > -1;
                                        return (<>
                                            <tr
                                                class='journalLine'
                                                onClick={(lineSaleIsLocked || viewOnly) ? this.onClickViewJournal : this.onClickEditJournal}
                                                data-id={j.id}
                                                data-animal={j.animal}
                                            >
                                                <td>{(lineSaleIsLocked || viewOnly) ? <i class='fas fa-eye'></i> : <i class='fas fa-edit'></i>}</td>
                                                <td>
                                                    {util.formatDate(j.date, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                                </td>
                                                <td class='text-center'>{j.weight > 0 ? `${j.weight}kg` : ''}</td>
                                                <td>
                                                    {j.chiefComplaint && <>
                                                        <Markdown markdown={j.chiefComplaint || ''} markedOpts={localUtil.getMarkdownOptions()} />
                                                    </>}
                                                </td>
                                                <td>{j.treatmentPlan}</td>
                                                <td>
                                                    <input
                                                        class="form-control-lg"
                                                        type='checkbox'
                                                        data-id={j.id}
                                                        checked={selectedJournals.indexOf(j.id) > -1}
                                                        onClick={this.selectJournal}
                                                    />
                                                </td>
                                            </tr>
                                        </>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {collapsed ? <>
                        <div class='d-flex justify-content-center'>
                            <button type='button' class='btn btn-sm btn-outline-primary rounded-pill' onClick={() => this.setState({ collapsed: false })}>
                                <i class='fas fa-chevron-down'></i>
                            </button>
                        </div>
                    </> : <>
                        <div class='d-flex justify-content-center'>
                            <button type='button' class='btn btn-sm btn-outline-primary rounded-pill' onClick={() => this.setState({ collapsed: true })}>
                                <i class='fas fa-chevron-up'></i>
                            </button>
                        </div>
                    </>}

                    {selectedJournals.length > 0 && <>
                        <button type='button' class='btn btn-primary rounded-pill' onClick={this.sendJournals}>
                            <i class='fas fa-paper-plane'></i> <Text id='animal.send-journal'>Send journal</Text>
                        </button>
                    </>}

                </div>
            </>}
        </>);
    }
}

export default AnimalListMini;
