import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

const MARKDOWN_OPTIONS = {
    pedantic: false,
    gfm: true,
    breaks: true,
    sanitize: false,
    smartLists: true,
    smartypants: true,
    xhtml: true,
    highlight: function(code, lang) {
        const hljs = require('highlight.js');
        const language = hljs.getLanguage(lang) ? lang : 'plaintext';
        return hljs.highlight(code, { language }).value;
    },
    langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
};

@observer
class Help extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            articles: [],
        };
        this.mainContainer = null;
        this.calendar = null;
    }

    loadAll = async () => {
        const { articleStore } = this.props.stores;
        const articles = await articleStore.load({
            query: {
                hostname: articleStore.currentHostname,
                category: 'help',
                all: 1,
            },
            addData: ['tags'],
            skipUpdate: true,
        });
        this.setState({
            articles,
        });
    }

    loadArticle = async id => {
        const { articleStore } = this.props.stores;
        const article = await articleStore.load(id, false, { query: {
            hostname: articleStore.currentHostname,
            category: 'help',
            all: 1,
        }, addData: ['tags'] });
        this.setState({
            currentArticle: article,
        });
    }

    viewArticle = async e => {
        e.preventDefault();
        e.stopPropagation();

        const { id } = e.target.closest('div').dataset;
        const idInt = parseInt(id, 10);
        // const { articles } = this.state;
        // const article = articles.find(a => a.id === idInt);

        // this.setState({
        //     currentArticle: article,
        // });
        const { articleStore } = this.props.stores;
        await articleStore.load(id, false, { query: {
            hostname: articleStore.currentHostname,
            category: 'help',
            all: 1,
        }, addData: ['tags'] });
        const { article } = articleStore;
        console.log('viewArticle', article)
        this.setState({
            currentArticle: article,
        });
    }

    onClickEditArticle = e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('button').dataset;
        // console.log('onClickEditContact', id, parseInt(id, 10));
        const { drawerLevel = 1 } = this.props;
		const { appState, contactStore } = this.props.stores;
		const { drawerHeightLarge } = appState;
		appState.openDrawer('editArticle', {
			height: drawerHeightLarge,
            id,
            callback: article => {
                appState.toggleDrawer(false, drawerLevel + 2);
                this.loadAll();
            },
		}, drawerLevel + 2);
    }

    onClickNewArticle = e => {
        const { drawerLevel = 1 } = this.props;
		const { appState, contactsStore } = this.props.stores;
		const { drawerHeightLarge } = appState;
        const { category } = e.target.closest('button').dataset;
		appState.openDrawer('newArticle', {
			height: drawerHeightLarge,
            isNew: true,
            category,
            callback: () => {
                this.loadAll();
            },
		}, drawerLevel + 2);
	}

    clearSearch = () => {
        this.setState({
            search: '',
        }, () => {
            this.loadAll();
        });
    }

    searchInput = (e) => {
        const search = e.target.value;
        this.setState({ search });

        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.doSearch();
        }, 700);
    }

    doSearch = async () => {
        const { search } = this.state;
		const { articleStore } = this.props.stores;
        const articles = await articleStore.load({
            query: {
                hostname: articleStore.currentHostname,
                category: 'help',
                search,
                all: 1,
            },
            skipUpdate: true,
        });
        this.setState({
            articles,
        });
    }


    viewTags = obj => {
        if (obj.tags?.length === 0 && obj.loans?.length === 0 && !obj.ownedByOther && !obj.url && !obj.parents?.length > 0 && !obj.files?.length > 0) {
            return '';
        }
        const { userStore } = this.props.stores;
        const { filter = {} } = this.state;
        const { user = {} } = userStore;
        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        let tagClass = darkmode ? 'dark' : 'light';

        return (<>
            <div class='w-100 overflow-hidden'>
                <div class='box-line-tags d-flex flex-nowrap'>
                    {obj.files?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-file' /> <span class='font-weight-lighter'> x {obj.files.length}</span>
                        </span>
                    </>}
                    {obj.tags?.length > 0 && obj.tags.map(tag => {
                        if (filter[tag.name]) {
                            tagClass = 'primary';
                        } else {
                            tagClass = darkmode ? 'dark' : 'light';
                        }
                        return (<>
                            <span
                                class={`badge badge-pill badge-${tagClass} mr-1 mt-1`}
                                onClick={this.toggleTagFilter}
                                data-tag={tag.name}
                            >#{tag.name}</span>
                        </>);
                    })}
                </div>
            </div>
        </>);
    }

    toggleTagFilter = e => {
        e.preventDefault();
        e.stopPropagation();
        const { tag } = e.target.closest('span').dataset;
        const { filter = {} } = this.state;
        if (filter[tag]) {
            delete filter[tag];
        } else {
            filter[tag] = true;
        }
        this.setState({
            filter,
        });
    }

    filterArticles = article => {
        const { filter = {} } = this.state;
        if (Object.keys(filter).length === 0) {
            return true;
        }
        let found = false;
        article.tags.forEach(tag => {
            if (filter[tag.name]) {
                found = true;
            }
        });
        return found;
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const {} = this.props;
        const { currentArticle, search, filter, articles } = this.state;
        const { appState, userStore, articleStore } = this.props.stores;
        const { user = {}, isAdmin, isMediaWorker, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        /*
            TODO: Separate logged in and not logged in help articles.
            TODO: Separate admin, veterniary and other help articles.
        */

        return (<>
            <div class={`w-100 h-100 d-flex justify-content-center p-2`}>
                <div class={`w-100 h-100 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg`} style='padding-top: 0px; padding-bottom: 0px;'>
                    <div class='w-100 h-100 d-flex flex-row'>
                        <div class='w-25 h-100 d-flex flex-column overflow-auto position-relative px-3 py-3 bg-light rounded-lg'>
                            {(isAdmin || isMediaWorker) && <>
                                <div class='position-absolute' style='top: 10px; right: 10px;'>
                                    <button
                                        type='button'
                                        class='btn btn-sm btn-outline-secondary rounded-pill'
                                        data-category={'help'}
                                        onClick={this.onClickNewArticle}
                                    >
                                        {/* <i class='fa-solid fa-list text-muted' /> <Text id='dyrejournal.product.all'>All Products</Text> */}
                                        <i class='fa-solid fa-file text-muted' /> <Text id='help.new-article'>New Article</Text>
                                    </button>
                                </div>
                            </>}
                            <div class='w-100 mt-5'>
                                <div class='input-group'>
                                    <div class='input-group-prepend'>
                                        <span class='input-group-text rounded-pill-left'>
                                            <i class='fa-solid fa-magnifying-glass'></i>
                                        </span>
                                    </div>
                                    <input
                                        class={`form-control form-control-lg ${search ? '' : 'rounded-pill-right'}`}
                                        type='text'
                                        value={search}
                                        placeholder={'search'}
                                        onInput={this.searchInput}
                                    />
                                    {search && <div class='input-group-append'>
                                        <button class='btn btn-outline-secondary rounded-pill-right' type='button' onClick={this.clearSearch}>
                                            <i class='fa-solid fa-circle-xmark'></i>
                                        </button>
                                    </div>}
                                </div>
                            </div>

                            <div class='w-100 mt-3'>
                                {this.viewTags({ tags: articleStore.tags })}
                            </div>

                            <div class='w-100 mt-3'>
                                {articles?.length && articles.filter(this.filterArticles).map(article => {
                                    return (<>
                                        <div
                                            class={`w-100 d-flex flex-row ${article.active ? '' : 'text-muted font-italic'}`}
                                            data-id={article.id}
                                            onClick={this.viewArticle}
                                        >
                                            {article.active ? '' : <i class='fa-duotone fa-eye-slash text-danger mr-2' />}
                                            {article.headingIcon ? <i class={`${article.headingIcon} mr-2`} /> : ''} {article.title}
                                        </div>
                                    </>)
                                })}

                                {articles?.length && articles.filter(this.filterArticles).length === 0 && <>
                                    <div class='w-100 d-flex flex-row justify-content-center' style='font-size: 3.0em;'>
                                        <i class='fa-duotone fa-question text-muted' />
                                    </div>
                                    <div class='w-100 d-flex flex-row justify-content-center font-italic text-muted mt-3'>
                                        <Text id='help.no-articles'>No articles found</Text>
                                    </div>
                                </>}
                            </div>
                        </div>

                        <div class='w-75 h-100 d-flex flex-column overflow-auto position-relative px-3 py-3'>
                            {currentArticle && <>
                                <div class='mt-2'>
                                    {(isAdmin || isMediaWorker) && <>
                                        <div class='position-absolute' style='top: 12px; right: 45px;'>
                                            <button
                                                type='button'
                                                class='btn btn-sm btn-outline-secondary rounded-pill'
                                                onClick={this.onClickEditArticle}
                                                data-id={currentArticle.id}
                                            >
                                                {/* <i class='fa-solid fa-list text-muted' /> <Text id='dyrejournal.product.all'>All Products</Text> */}
                                                <i class='fa-solid fa-file text-muted' /> <Text id='help.edit-article'>Edit Article</Text>
                                            </button>
                                        </div>
                                    </>}
                                    <h1>{currentArticle.title}</h1>
                                    <Markdown markdown={currentArticle.body} options={MARKDOWN_OPTIONS} />
                                </div>
                                <div class='mt-2'>
                                    {this.viewTags(currentArticle)}
                                </div>
                            </>}
                        </div>

                    </div>

                </div>
            </div>
        </>);
    }
}

export default Help;
