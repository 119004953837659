import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';
import linkstate from 'linkstate';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';

const ACCOUNT_MAP = {
    '1200': 'Aktiverte kostn. gruppe d',
    '1201': 'Aktiverte kostn. gruppe a',
    '1202': 'Aktiverte kostn. leide lokaler',
    '1460': 'Lagerbeholdning salgsvarer',
    '1480': 'Forskudd til leverandører',
    '1510': 'Kunder',
    '1511': 'Avsetning for kundetap',
    '1525': 'Annen kundefordring',
    '1530': 'Annen kundefordring2',
    '1610': 'Fordring forsikring Agria',
    '1612': 'Fordring forsikring Codan',
    '1614': 'Fordring forsikring DnB NOR',
    '1616': 'Fordring forsikring Enter',
    '1618': 'Fordring forsikring Gjensidige',
    '1620': 'Fordring forsikring IF',
    '1622': 'Fordring forsikring Jernbanepersonalets',
    '1623': 'Fordring forsikring Nemi',
    '1624': 'Fordring forsikring Sparebank1',
    '1626': 'Fordring forsikring Storebrand',
    '1628': 'Fordring forsikring Tennant',
    '1630': 'Fordring forsikring Terra',
    '1632': 'Fordring forsikring Tryg Vesta',
    '1634': 'Fordring forsikring Frende',
    '1636': 'Fordring forsikring - annet selskap',
    '1910': 'Kasse',
    '1913': 'Bank kort (fordring)',
    '1915': 'Visa (fordring)',
    '1916': 'Eurocard (fordring)',
    '1917': 'Diners (fordring)',
    '1925': 'Fording på inkassoselskap kundeinnbetalinger',
    '1935': 'Bank plasseringskonto',
    '1939': 'Bank folio',
    '1940': 'Bank skattetrekk',
    '2000': 'Aksjekapital',
    '2020': 'Overkursfond',
    '2050': 'Egenkapital fri',
    '2114': 'Skyldig konsulent avg.fritt',
    '2410': 'Leverandører',
    '2412': 'Skyldig konsulent',
    '2500': 'Skyldig skatt utsatt',
    '2510': 'Skyldig skatt betalbar',
    '2610': 'Skyldig Skattetrekk',
    '2710': 'Utgående mva',
    '2720': 'Inngående mva',
    '2730': 'Investeringsavgift',
    '2750': 'Fylkesskattesjefen',
    '2780': 'Skyldig arbeidsgiveravgift',
    '2781': 'Skyldig arbeidsg.avg feriep',
    '2782': 'Gjeld bank',
    '2800': 'Skyldig utbytte',
    '2910': 'Skyldig lønn, feriepenger etc',
    '2920': 'Skyldig Feriepenger',
    '2930': 'Gjeld til ansatte og aksjonærer',
    '2960': 'Påløpne kostnader',
    '2965': 'Annen kortsiktig gjeld',
    '3010': 'Poliklinikk',
    '3011': 'Fôrsalg',
    '3012': 'Rekvisitasalg',
    '3015': 'Varesalg avgiftsfritt',
    '3019': 'Ande salgsinntekter',
    '3030': 'Tenner',
    '3032': 'Røntgen/Ultralyd',
    '3034': 'Pelsstell',
    '3036': 'Vaksiner',
    '3038': 'Andre klinikkinntekter',
    '3040': 'Operasjon vanlig',
    '3042': 'Operasjon Spesialist',
    '3044': 'Lab eksternt',
    '3046': 'Lab internt',
    '3620': 'Leieinntekter klinikk',
    '3900': 'Andre inntekter',
    '4006': 'Varekjøp - vaksiner',
    '4008': 'Varekjøp - medisin',
    '4010': 'Varekjøp - utstyr (vet) forbr.',
    '4012': 'Varekjøp - salgsrekvisita',
    '4013': 'Varekjøp - fôr',
    '4019': 'Varekjøp - annet',
    '4090': 'Beholdningsendring varer',
    '4110': 'Varekjøp - avgiftsfritt',
    '4510': 'Andre fremmede tjenester',
    '4511': 'Konsulent',
    '4512': 'Analyser, kremering',
    '5010': 'Lønn',
    '5130': 'Lønn u.feriep.gr.lag',
    '5190': 'Feriepenger',
    '5410': 'Arbeidsgiveravgift',
    '5411': 'Arbeidsgiveravgift på feriepenger',
    '5960': 'Sosiale kostnader',
    '5990': 'Andre godtgjørelser',
    '6010': 'Ordinære avskrivinger',
    '6290': 'Leiekostnader mva-fritt',
    '6300': 'Leiekostnader mva pliktig',
    '6340': 'Strøm',
    '6540': 'Utstyr, inventer (uaktivert)',
    '6700': 'Regnskap, revisjon mv',
    '6730': 'Inkassokostnader',
    '6800': 'Kontorrekvisita, trykksaker mv',
    '6840': 'Aviser og Tidsskrifter',
    '6850': 'Faglitteratur',
    '6860': 'Kurs (inkl. reise og diett)',
    '6900': 'Telefon',
    '6940': 'Porto',
    '7100': 'Bilgodtgjørelse',
    '7140': 'Andre reise og diettkostn.',
    '7320': 'Reklame Div',
    '7322': 'Reklame Telekatalog',
    '7323': 'Reklame Internett',
    '7410': 'Kontingenter og gaver',
    '7500': 'Forsikringer',
    '7600': 'Patentkostnader',
    '7790': 'Andre driftskostnader',
    '7830': 'Tap på fordringer',
    '7832': 'Ettergitt',
    '8050': 'Renteinntekter',
    '8150': 'Renteutgifter',
    '8190': 'Andre finanskostnader',
    '8192': 'Andre ekstraordinære kostnader',
    '8600': 'Skattekostnad betalbar',
    '8620': 'Skattekostnad utsatt',
    '8800': 'Årsoppgjør',
    '8920': 'Utbytte',
    '8931': 'Privatkonto uttak kontanter',
    '8939': 'Privatkonto uttak annet',
    '8950': 'Tilførte midler',
    '3013': 'Medisinering',
    '3050': 'Varer som takst',
    '1920': 'Vipps',
    '1950': 'Ansatt rabatt',
    '1700': 'Kreditt egenandel ansatte (lønn)',
};

@withText(props => ({
    productTitle: <Text id='dyrejournal.product.search-title'>Search for product</Text>,
    searchTitle: <Text id='dyrejournal.product-title'>Search for products</Text>,
    adjustPricesTitle: <Text id='dyrejournal.product-priceadjust-title'>Adjust all prices in %</Text>,
    productGroupTitle: <Text id='dyrejournal.product-account-title'>Enter account number</Text>,
    productCategoryTitle: <Text id='dyrejournal.product-category-title'>Enter category</Text>,
}))
@observer
class AllProducts extends Component {
	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('journal'),
        };
        this.searchTimer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { productStore } = this.props.stores;
        const { productGroup, productCategory } = this.state;
        await productStore.load({ query: { category: productCategory, account: productGroup } });
    }

    onClickChooseProduct = e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('tr').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, saleStore, productStore } = this.props.stores;
        const { newSale } = saleStore;
        const { products = [] } = newSale;
        const { products: allProducts } = productStore;
        const selectedItem = allProducts.find(p => p.id === parseInt(id, 10));
        if (selectedItem) {
            const addProducts = [{ ...selectedItem, qty: 1 }];
            const addSubProducts = selectedItem?.subProducts?.map(p => {
                return {
                    qty: 1,
                    ...p,
                };
            });
            if (addSubProducts?.length > 0) {
                addProducts.push(...addSubProducts);
            }
            saleStore.updateObjectKeyValue('newSale', 'products', [...products, ...addProducts]);
            saleStore.updateObjectKeyValue('newSale', 'productAdded', true);
            appState.toggleDrawerRight(false, drawerLevel);
        }
    }

    onClickEditProduct = e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('tr').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, saleStore } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('editProduct', {
			height: drawerHeightMedium,
            hideAdmin: true,
            id,
            callback: () => {
                appState.toggleDrawer(false, drawerLevel + 1);
                // if (product) {
                    // const { newSale, productResults } = saleStore;
                    // const { products } = newSale;
                    // saleStore.updateObjectKeyValue('newSale', 'products', [...products, { ...product, qty: 1 }]);
                    // saleStore.updateObjectKeyValue('newSale', 'productAdded', true);
                    // setTimeout(() => {
                        // this.loadAll(this.props, false);
                    // }, 1000);
                // }
            },
		}, drawerLevel + 2);
    }

    onClickDeleteProduct = async e => {
        e.preventDefault();
        e.stopPropagation();

        if (!confirm('Are you sure you want to delete this product?')) {
            return;
        }

        const { id } = e.target.closest('tr').dataset;
        const { productStore } = this.props.stores;
        console.log('onClickDeleteProduct', id, parseInt(id, 10));
        await productStore.delete(parseInt(id, 10));
        setTimeout(() => {
            this.loadAll(this.props, false);
        }, 1000);
    }

    onClickNewProduct = e => {
        console.log('onClickNewProduct', e);
        const { drawerLevel = 1 } = this.props;
		const { appState, saleStore, productStore } = this.props.stores;
		const { drawerHeightMedium } = appState;

		appState.openDrawer('newProduct', {
			height: drawerHeightMedium,
            isNew: true,
            hideAdmin: true,
            callback: product => {
                appState.toggleDrawer(false, drawerLevel + 2);
                if (product) {
                    const { newSale, productResults } = saleStore;
                    const { products } = newSale;
                    saleStore.updateObjectKeyValue('newSale', 'products', [...products, { ...product, qty: 1 }]);
                    setTimeout(async () => {
                        this.loadAll(this.props, false);
                        await productStore.load();
                    }, 1000);
                }
            },
		}, drawerLevel + 2);
    }

    // searchProductCallback = selectedItem => {
    //     const { appState, saleStore, productStore } = this.props.stores;
    //     const { drawerLevel = 1 } = this.props;
    //     const { newSale } = saleStore;
    //     const { products = [] } = newSale;
    //     saleStore.updateObjectKeyValue('newSale', 'products', [...products, selectedItem]);
    //     appState.toggleDrawerRight(false, drawerLevel);
    // }

    searchInput = (e) => {
        const search = e.target.value;
        this.setState({ search });

        clearTimeout(this.searchTimer);
        if (search.length >= 3) {
            this.searchTimer = setTimeout(async () => {
                this.doSearch(search);
            }, 300);
        }
    }

    priceAdjustmentInput = (e) => {
        const priceAdjustment = e.target.value;
        this.setState({ priceAdjustment });
    }

    productGroupInput = async (e) => {
        const { productCategory } = this.state;
        const { productStore } = this.props.stores;
        const productGroup = e.target.value;
        this.setState({ productGroup });
        await productStore.load({ query: { category: productCategory, account: productGroup } });
    }

    productCategoryInput = async (e) => {
        const { productGroup } = this.state;
        const { productStore } = this.props.stores;
        const productCategory = e.target.value;
        this.setState({ productCategory });
        await productStore.load({ query: { category: productCategory, account: productGroup } });
    }

    adjustPrices = async () => {
        const { priceAdjustment, productGroup, productCategory } = this.state;
        const { productStore } = this.props.stores;

        if (!confirm(`Are you sure you want to ajust ALL prices with ${priceAdjustment}%?`)) {
            return;
        }
        const response = await productStore.updateAllPrices({ account: productGroup, category: productCategory, percent: priceAdjustment });
        this.setState({ priceAdjustment: '', priceAdjustmentDone: true });
        if (response?.status === 201) {
            setTimeout(() => {
                this.loadAll(this.props, false);
            }, 1000);
        }
    }

    clearPriceAdjustment = () => {
        this.setState({ priceAdjustment: '', productGroup: '', productCategory: '', priceAdjustmentDone: false });
    }

    async doSearch(search) {
        const { productCategory, productGroup } = this.state;
        const { productStore } = this.props.stores;
        if (search) {
            // console.log('doUpdateField.saveField', id, field, value);
            const result = await productStore.load({ query: { search, category: productCategory, account: productGroup }, limit: 500, skipUpdate: false });
        } else {
            const result = await productStore.load({ query: { category: productCategory, account: productGroup }, limit: 500, skipUpdate: false });
        }
    }

    clearSearch = () => {
        this.setState({ search: '' });
        this.doSearch('');
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.doSearch(this.state.search);
        } else if (e.key === 'Escape') {
            e.preventDefault();
            this.clearSearch();
        }
    }

    onClickToggleMultiple = () => {
        const { showEditMultiple } = this.state;
        this.setState({ showEditMultiple: !showEditMultiple });
    }

    submitCSV = async () => {
        const { csvText } = this.state;
        const { productStore } = this.props.stores;
        const response = await productStore.insertFromCSV(csvText);
        if (response?.status === 202) {
            this.setState({ csvText: '', multipleUpdated: response?.data || ''  });
            setTimeout(() => {
                this.loadAll(this.props, false);
            }, 1000);
        }
    }

    updateCSV = async () => {
        const { csvText } = this.state;
        const { productStore } = this.props.stores;
        const response = await productStore.updateFromCSV(csvText);
        if (response?.status === 202) {
            this.setState({ csvText: '', multipleUpdated: response?.data || '' });
            setTimeout(() => {
                this.loadAll(this.props, false);
            }, 1000);
        }
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const {
            productTitle,
            searchTitle,
            adjustPricesTitle,
            productGroupTitle,
            productCategoryTitle,
        } = this.props;
        const {
            search,
            priceAdjustment,
            priceAdjustmentDone,
            productGroup,
            productCategory,
            showEditMultiple,
            csvText,
            multipleUpdated = '',
        } = this.state;
        const { userStore, productStore, saleStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { products } = productStore;

        const apiServer = util.getApiServer();
        const jwtToken = util.getJwtToken();

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='padding-top: 0px; padding-bottom: 100px;'>

                    <div class='w-100 d-flex flex-sm-row flex-column'>
                        <div class='w-100 d-flex flex-column'>
                            {/* <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.all-products'>All products</Text>
                            </div> */}
                            <div class={`d-flex flex-column mx-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>


                                <div class='d-flex flex-column justify-content-between'>
                                    <div class='d-flex flex-row justify-content-between'>
                                        <div class='flex-fill input-group'>
                                            <div class='input-group-prepend'>
                                                <span class='input-group-text rounded-pill-left'>
                                                    <i class='fa-regular fa-magnifying-glass'></i>
                                                </span>
                                            </div>
                                            <input
                                                class={`form-control ${!search ? 'rounded-pill-right' : ''}`}
                                                type='text'
                                                value={search}
                                                placeholder={`${searchTitle}`}
                                                onInput={this.searchInput}
                                                onKeyDown={this.handleKeyDown}
                                            />
                                            {search && <div class='input-group-append'>
                                                <button class='btn btn-danger rounded-pill-right' type='button' onClick={this.clearSearch}>
                                                    <i class='fa-solid fa-circle-xmark'></i>
                                                </button>
                                            </div>}
                                        </div>
                                        <div class='input-group ml-2'>
                                            <select
                                                class={`form-control rounded-lg`}
                                                onInput={this.productGroupInput}
                                            >
                                                <option value=''>-- Velg konto --</option>
                                                {Object.keys(ACCOUNT_MAP).map(e => {
                                                    return (<option value={e}>{e} - {ACCOUNT_MAP[e]}</option>);
                                                })}
                                            </select>
                                        </div>
                                        <div class='input-group ml-2'>
                                            <select
                                                class=" rounded-lg form-control"
                                                onInput={this.productCategoryInput}
                                            >
                                                <option value="">-- Velge Kategori --</option>
                                                <option value="produkt">Produkt</option>
                                                <option value="honorar">Honorar</option>
                                                <option value="medisin">Medisin</option>
                                                <option value="annet">Annet</option>
                                            </select>
                                        </div>

                                        {/* <div class='d-flex flex-row ml-2'>
                                            {[0, 1, 2, 3, 4, 5].map((e, idx) => {
                                                const isFirst = idx === 0;
                                                const isLast = idx === 5;
                                                return (<button
                                                    type='button'
                                                    class={`btn btn-sm btn-${activeStatus === e ? 'primary' : 'outline-primary'} ${isFirst ? 'rounded-pill-left' : (isLast ? 'rounded-pill-right' : 'rounded-none')}`}
                                                    onClick={this.onClickStatus}
                                                    data-id={e}
                                                >
                                                    {saleStore.getSaleStatusById(e)}
                                                </button>);
                                            })}
                                        </div> */}
                                        <a
                                            href={`${apiServer}${productStore.api?.load?.url}csv?jwtToken=${jwtToken}&account=${productGroup || ''}&category=${productCategory || ''}&search=${search || ''}`}
                                            target='_blank'
                                            class={`btn btn-sm btn-outline-primary rounded-pill ml-2 pt-2`}
                                        >
                                            <nobr><i class='fa-duotone fa-download' /> <Text id='dyrejournal.download-csv'>Download CSV</Text></nobr>
                                        </a>
                                        <button
                                            class={`btn btn-${showEditMultiple ? '' : 'outline-'}secondary rounded-pill ml-2`}
                                            type='button'
                                            onClick={this.onClickToggleMultiple}
                                        >
                                            <nobr><i class='fa-duotone fa-edit' /> <Text id='dyrejournal.edit-multople'>Edit multiple</Text></nobr>
                                        </button>
                                    </div>

                                    {showEditMultiple && <div class='d-flex flex-column justify-content-between bg-warning rounded-lg mt-2 mb-5 pb-3'>
                                        <div class='w-100 d-flex flex-column justify-content-start'>
                                            <Input
                                                stores={this.props.stores}
                                                holdingStore={productStore}
                                                object={{}}
                                                type='textarea'
                                                class='form-control'
                                                placeholder={`Paste CSV here...`}
                                                help={'Copy from Excel or Google Sheets and paste here without any formatting. First row is always the header row with the same names as the fields in the table.'}
                                                onInput={linkstate(this, 'csvText')}
                                                inputValue={csvText}
                                                classNames='form-control'
                                            />
                                        </div>

                                        <div class='w-100 d-flex flex-row justify-content-center mt-3'>
                                            <div class='bg-danger text-white rounded-lg p-3' role='alert'>
                                                <div style='font-size: 1.5em;'>
                                                    <i class='fa-solid fa-triangle-exclamation' /> <Text id='dyrejournal.csv-warning'>Warning: This will update all products with info from the CSV file. Make sure you have the correct data before you proceed!</Text>
                                                </div>
                                                <div>
                                                    <ul>
                                                        <li><Text id='dyrejournal.csv-warning-1'>Id is the unique identifier of the product and has to always be the same.</Text></li>
                                                        <li><Text id='dyrejournal.csv-warning-2'>If you leave a field empty, it will not be updated.</Text></li>
                                                        <li><Text id='dyrejournal.csv-warning-3'>If you accidentially damage your product database, we will have to charge you for disaster recovery.</Text></li>
                                                    </ul>
                                                </div>
                                                <div style='font-size: 1.5em;'>
                                                    <i class='fa-solid fa-triangle-exclamation' /> <Text id='dyrejournal.csv-warning-unsure'>If you are unsure about this, please stop now!</Text>
                                                </div>
                                            </div>
                                        </div>

                                        <div class='w-100 d-flex flex-row justify-content-center mt-3'>
                                            {isAdmin && <button type='button' class='btn btn-sm btn-danger rounded-pill mx-1' onClick={this.submitCSV}>
                                                <i class='fa-duotone fa-upload mr-2' />
                                                <Text id='input.admin.multi-upload'>Insert & update from CSV</Text>
                                            </button>}
                                            <button type='button' class='btn btn-sm btn-success rounded-pill mx-1' onClick={this.updateCSV}>
                                                <i class='fa-duotone fa-pen-to-square mr-2' />
                                                <Text id='input.admin.multi-update'>Update from CSV</Text>
                                            </button>
                                        </div>

                                        {multipleUpdated && <div class='alert alert-success mt-2' role='alert'>
                                            <i class='fa-duotone fa-circle-check' /> <Text id='dyrejournal.multiple-updated'>Multiple accepted</Text><br />
                                            <Text id='dyrejournal.multiple-updated'>Updated:</Text> {multipleUpdated?.objectsForUpdate?.length || 0}<br />
                                            <Text id='dyrejournal.multiple-inserted'>Inserted:</Text> {multipleUpdated?.objectsForInsert?.length || 0}<br />
                                            {/* {JSON.stringify(multipleUpdated)} */}
                                            {/* {"objectsForUpdate":[{"id":5297,"productNumber":"100845","type":"service","category":"","name":"273 · kontroll med sedasjon (uten med)","description":"Dette er en test","qty":12,"inStock":"","account":3010,"price":0,"inPrice":"","vat":25},{"id":5261,"productNumber":"100800","type":"service","category":"","name":"496 · tannsliping av kinntenner kanin/marsvin uten sedasjon","description":"","qty":"","inStock":"","account":3010,"price":0,"inPrice":"","vat":25}]} */}
                                        </div>}

                                        {csvText && <>
                                            <div class='table-responsive'>
                                                <table class='table table-striped mt-3'>
                                                    <thead>
                                                        <tr>
                                                            {csvText.split('\n')[0].split('\t').map(e => {
                                                                return (<th>{e}</th>);
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {csvText.split('\n').slice(1).map(e => {
                                                            return (<tr>
                                                                {e.split('\t').map(e => {
                                                                    return (<td>{e}</td>);
                                                                })}
                                                            </tr>);
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>}
                                    </div>}


                                    <div class='d-flex flex-row justify-content-between'>
                                        <div class='input-group mt-1'>
                                            <div class='input-group-prepend'>
                                                <span class='input-group-text rounded-pill-left'>
                                                    <i class='fa-regular fa-chart-line-up' />
                                                </span>
                                            </div>
                                            <input
                                                class={`form-control ${!priceAdjustment && !productGroup ? 'rounded-pill-right' : ''}`}
                                                type='text'
                                                value={priceAdjustment}
                                                placeholder={`${adjustPricesTitle}`}
                                                onInput={this.priceAdjustmentInput}
                                            />
                                            {(productGroup || priceAdjustment) && <div class='input-group-append'>
                                                {priceAdjustment && <>
                                                    <button class='btn btn-primary rounded-none' type='button' onClick={this.adjustPrices}>
                                                        <i class='fa-solid fa-circle-check' /> <Text id='dyrejournal.adjust-prices'>Adjust all prices</Text>
                                                    </button>
                                                </>}
                                                <button class='btn btn-danger rounded-pill-right' type='button' onClick={this.clearPriceAdjustment}>
                                                    <i class='fa-solid fa-circle-xmark'></i>
                                                </button>
                                            </div>}
                                        </div>

                                        {priceAdjustmentDone ? <>
                                            <div class='alert alert-success mt-3' role='alert'>
                                                <i class='fa-solid fa-circle-check' /> <Text id='dyrejournal.adjust-prices-done'>All prices adjusted.</Text>
                                            </div>
                                        </> : <></>}

                                    </div>
                                </div>

                                <div class={`table-responsive ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                    <table class='table table-striped mb-0'>
                                        <thead>
                                            <tr>
                                                <th class='d-none d-sm-table-cell w-5'>&nbsp;</th>
                                                <th class='d-none d-sm-table-cell w-5'><Text id='dyrejournal.id'>Id</Text></th>
                                                <th class='d-none d-sm-table-cell w-5'><Text id='dyrejournal.inStock'>inStock</Text></th>
                                                <th><Text id='dyrejournal.product.name'>Name</Text></th>
                                                <th><Text id='dyrejournal.product.category'>Category</Text></th>
                                                <th><Text id='dyrejournal.product.account'>Account</Text></th>
                                                <th class='text-center w-5'><Text id='dyrejournal.product.vat'>Vat</Text></th>
                                                <th class='text-center w-10'><Text id='dyrejournal.product.price'>Price</Text></th>
                                                <th class='text-center w-10'><Text id='dyrejournal.product.out-price'>Out Price</Text></th>
                                                <th class='d-none d-sm-table-cell w-5'>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(products && products.length > 0) && products.map(e => {
                                                return (<>
                                                    <tr onClick={this.onClickChooseProduct} data-id={e.id}>
                                                        <td class='d-none d-sm-table-cell' onClick={this.onClickEditProduct} data-id={e.id}>
                                                            <i class='fa-solid fa-pen' />
                                                        </td>
                                                        <td class='d-none d-sm-table-cell'>{e.id}</td>
                                                        <td class='d-none d-sm-table-cell text-center'>{e.inStock}</td>
                                                        <td>{e.name}</td>
                                                        <td>{e.category}</td>
                                                        <td>{e.account} <span class='font-weight-lighter'>({ACCOUNT_MAP[e.account]})</span></td>
                                                        <td class='text-right'>
                                                            {util.format(e.vat, 0, ',', ' ')}%
                                                        </td>
                                                        <td class='text-right'>
                                                            {util.format(e.price, 2, ',', ' ')}
                                                        </td>
                                                        <td class='text-right'>
                                                            {saleStore.lineTotal(e, true, 1)}
                                                        </td>
                                                        <td class='d-none d-sm-table-cell' onClick={this.onClickDeleteProduct} data-id={e.id}>
                                                            <i class='fa-solid fa-trash text-danger' />
                                                        </td>
                                                    </tr>
                                                </>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default AllProducts;
