import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class VisitorStore extends LocalModel {
    constructor() {
        super('visitor', {
            namePlural: 'visitors',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/visitors/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/visitors/',
                    params: {},
                },
                save: {
                    url: '/api/visitors/',
                    params: {},
                },
                delete: {
                    url: '/api/visitors/',
                    params: {},
                },
            },
        });
    }

    @observable newVisitor = {};

    @observable visitor = {};

    @observable visitors = [];

    @observable currentVisitors = [];

    @observable searchResult = [];

    async searchVisitors({ search }) {
        const response = await util.fetchApi(`/api/visitors/`, { publish: true, method: 'GET' }, { search });
        console.log('searchVisitors', response);
        switch (response.status) {
            case 200:
                this.updateKeyValue('searchResult', response.data);
                return response;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

}

const store = new VisitorStore();
export default store;
