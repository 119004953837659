import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import ImageScroller from '../../components/imagescroller';

import VideoStreamingPlayer from '../../routes/videostreaming/player';
import YourSubmittedApplicationList from '../../components/submittedapplication/yourList';
import GuiInfoBox from '../../components/gui/infoBox';
import FormFields from '../../components/form/fields';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

function partImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3LargeLink}`;
    return imgSrc;
}

@withText(props => ({
    giftAmountText: <Text id='submittedApplication.giftamount'>Gift amount</Text>,
}))
@observer
class SubmittedApplicationViewAdmin extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
            sections: fields.getSections('submittedApplication'),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { id } = props;
        const { submittedApplicationStore, applicationOfferStore } = this.props.stores;
        if (id) {
            submittedApplicationStore.updateKeyValue('submittedApplication', {});
            await submittedApplicationStore.load(id, false, { addData: ['tags'] });

            submittedApplicationStore.updateKeyValue('appOffer', {});

            const { submittedApplication } = submittedApplicationStore;
            if (submittedApplication.applicationOffer) {
                const appOffers = await applicationOfferStore.load(submittedApplication.applicationOffer, false, { skipUpdate: true });
                const appOffer = appOffers[0];
                submittedApplicationStore.updateKeyValue('appOffer', appOffer);

                // submittedApplicationStore.updateKeyValue('municipalities', [{ name: 'Oslo' }]);
                // submittedApplicationStore.updateKeyValue('categories', [{ name: 'Idrett' }]);
                try {
                    if (appOffer.municipalities) {
                        submittedApplicationStore.updateKeyValue('municipalities', JSON.parse(appOffer.municipalities));
                    }
                    if (appOffer.categories) {
                        submittedApplicationStore.updateKeyValue('categories', JSON.parse(appOffer.categories));
                    }
                    if (appOffer.areas) {
                        submittedApplicationStore.updateKeyValue('areas', JSON.parse(appOffer.areas));
                    }
                    if (appOffer.targetGroups) {
                        submittedApplicationStore.updateKeyValue('targetGroups', JSON.parse(appOffer.targetGroups));
                    }
                    submittedApplicationStore.updateKeyValue('hideFields', appOffer.hideFields);
                    submittedApplicationStore.updateKeyValue('hideSections', appOffer.hideSections);
                    // console.log('appOffer', appOffer)
                } catch (err) {
                    console.log('err', err);
                }
            }
        }
    }

    viewTags = obj => {
        if (obj.tags?.length === 0 && obj.loans?.length === 0 && !obj.ownedByOther && !obj.url && !obj.parents?.length > 0 && !obj.files?.length > 0) {
            return '';
        }
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        const tagClass = darkmode ? 'dark' : 'light';

        return (<>
            <div class='w-100 overflow-hidden'>
                <div class='box-line-tags d-flex flex-nowrap'>
                    {obj.files?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-file' /> <span class='font-weight-lighter'> x {obj.files.length}</span>
                        </span>
                    </>}
                    {obj.tags?.length > 0 && obj.tags.map(tag => {
                        return (<>
                            <span
                                class={`badge badge-pill badge-${tagClass} mr-1 mt-1`}
                                onClick={this.toggleTagFilter}
                                data-tag={tag.name}
                            >#{tag.name}</span>
                        </>);
                    })}
                </div>
            </div>
        </>);
    }

    writeApplication = () => {
		const { id, drawerLevel } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
		appState.openDrawer('newSubmittedApplication', {
			height: drawerHeightMediumLarge,
            isNew: true,
            submittedApplicationId: id,
            callback: () => {
                appState.toggleDrawer(drawerLevel + 1);
            }
		}, drawerLevel + 1);
	}

    submitApplication = async e => {
        const { id } = this.props;
        const { submittedApplicationStore } = this.props.stores;
        const { submittedApplication } = submittedApplicationStore;
        //    async saveField(id, field, value, updateMemory, opt = {}) {
        await submittedApplicationStore.saveField(id, 'isSubmitted', true, true, { event: e });
    }

     editSubmittedApplicationView = e => {
        // return;
        const { id, title } = e.target.closest('button').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState, mediasStore } = this.props.stores;
        const { drawerHeightMediumLarge } = appState;
        appState.openDrawer('editSubmittedApplication', {
            id,
            height: drawerHeightMediumLarge,
            callback: () => {
                appState.toggleDrawer(false, drawerLevel + 2);
                // this.loadAll();
            },
        }, drawerLevel + 2);
    }

    gotoLogin = () => {
        route('/login');
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            page,
            artid,
            bib,
            showAnimations = true,
            giftAmountText,
            drawerLevel = 1,
        } = this.props;
        const { sessionid, showMenu, submittedApplications, sections } = this.state;
        const { appState, userStore, submittedApplicationStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { submittedApplication, hideFields, hideSections, appOffer } = submittedApplicationStore;

        // const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		// const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

        // const sectionTitle = fields.getSection('submittedApplication', 'submittedApplication');
        // const sectionOrg = fields.getSection('submittedApplication', 'organization basic');
        // const sectionMember = fields.getSection('submittedApplication', 'member info');
        // const sectionEconomy = fields.getSection('submittedApplication', 'economy info');
        // const sectionContactPerson = fields.getSection('submittedApplication', 'contactperson basic');
        // const sectionApplication = fields.getSection('submittedApplication', 'application basic');

        // if (!user.isSoknadsguideadmin) {
        //     return (<>
        //         <div class='w-100 d-flex justify-content-center'>
        //             <div class='w-100 d-flex justify-content-center'>
        //                 <div class='alert alert-danger' role='alert'>
        //                     <i class='fa-duotone fa-circle-xmark mr-2' />
        //                     <Text id='submittedApplication.not-authorized'>Not authorized</Text>
        //                 </div>
        //             </div>
        //         </div>
        //     </>);
        // }

        if (!appOffer?.id) {
            // Add centered loading spinner
            return (<>
                <div class='w-100 d-flex justify-content-center'>
                    <div class='w-100 d-flex justify-content-center'>
                        <div class='spinner-border' role='status'>
                            <span class='sr-only'>Loading...</span>
                        </div>
                    </div>
                </div>
            </>);
        }

        let isReadyToSubmit = true;
        const apiServer = util.getApiServer();

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 60px; padding-bottom: 100px;'>

                    <div class={`d-flex justify-content-start flex-row w-100`}>
                        <div class='flex-fill'>
                            {/* <h5 class='ml-3 mb-3'>{submittedApplication.title}</h5> */}
                        </div>
                        {/* <div class='' style='margin-right: 65px;'>
                            <span onClick={this.editSubmittedApplication} class={`text-primary ${linkTextClass}`} style={`${linkTextStyle}`}>
                                <i class={`fa-regular fa-pen ml-3 mr-1`} />
                            </span>
                        </div> */}
                    </div>

                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class='d-flex flex-column mx-3'>
                                {/* {JSON.stringify(part)} */}
                                <ImageScroller
                                    images={submittedApplication.images}
                                    stores={this.props.stores}
                                    showImg={img => partImg(img, this.props, '1920x')}
                                    maxHeight={'50vh'}
                                />
                            </div>

                            {/* <div class={`d-flex flex-column mx-3 mt-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                <div>
                                    <h3 class='font-weight-light'>{submittedApplication.title}</h3>
                                </div>
                                <div class='font-weight-lighter'>
                                    {util.formatDate(submittedApplication.createdDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                </div>
                            </div> */}


                            {sections && sections.filter(s => {
                                if (hideSections && hideSections.length > 0) {
                                    return !hideSections.some(e => e.key === s.key);
                                }
                                return true;
                            }).map((section, idx) => {
                                const fields = section.fields.filter(f => {
                                    if (hideFields && hideFields.length > 0) {
                                        return !hideFields.some(e => e.name === f.name);
                                    }
                                    return true;
                                });
                                const hasAllFields = section.hasAllRequiredFields ? section.hasAllRequiredFields(submittedApplication, fields) : true;

                                isReadyToSubmit = isReadyToSubmit && hasAllFields;
                                return (<>
                                    {/* <xmp>{JSON.stringify(section, null, 4)}</xmp> */}
                                    {/* <xmp>hasAllFields: {JSON.stringify(hasAllFields, null, 4)}</xmp> */}
                                    {section.displayCollapsedInfo ? <>
                                        <div class={`font-weight-lighter px-3 mt-3 box-header`}>
                                            {hasAllFields ? <>
                                                <i class='fa-duotone fa-circle-check text-success mr-2'></i>
                                            </> : <>
                                                <i class='fa-duotone fa-circle-xmark text-danger mr-2'></i>
                                            </>}
                                            {section.name}
                                        </div>
                                        <div class={`d-flex flex-column mx-3 mt-0 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                            {section.displayCollapsedInfo ? section.displayCollapsedInfo(submittedApplication, hideFields) : 'na'}
                                        </div>
                                    </> : <>
                                    </>}
                                </>);
                            })}

                            {submittedApplication.files?.length > 0 && <>
                                <div class={`font-weight-lighter px-3 mt-3 box-header`}>
                                    <Text id='submittedApplication.attachments'>Attachments</Text>
                                </div>
                                <div class='d-flex flex-column mt-3 mx-3'>
                                    {submittedApplication.files?.length > 0 && submittedApplication.files.map(file => {
                                        const poster = file.s3LinkStreamPreview1;
                                        const downloadLink = file.s3Link.replace('/original', '/original-video');
                                        // console.log({ file });
                                        if (file.s3LinkStream) {
                                            return (<>
                                                <div class='video-container rounded-lg mt-3'>
                                                    <VideoStreamingPlayer
                                                        key={file.s3LinkStream}
                                                        videoStreamUrl={file.s3LinkStream}
                                                        stores={this.props.stores}
                                                        poster={poster}
                                                    />
                                                </div>
                                                <div class='d-flex flex-row justify-content-center'>
                                                    <a href={downloadLink} target='_blank' rel='noopener noreferrer'>{file.name} ({localUtil.formatFileSize(file.size)}) <i class="fa-duotone fa-download"></i></a>
                                                    <a href={`${apiServer}/#/submittedapplication/video/${submittedApplication.uuidv4}/${file.md5}`} target='_blank' rel='noopener noreferrer' class='ml-3'>Open player <i class="fa-duotone fa-link"></i></a>
                                                </div>
                                            </>);
                                        }
                                        if (file.s3Link.match(/\.(m4v|m4a|mp4)$/i)) {
                                            return (<>
                                                <div className='video-container rounded-lg'>
                                                    <video controls className='video-player'>
                                                        <source src={file.s3Link} type='video/mp4' />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>
                                            </>);
                                        }
                                        if (file.s3Link.match(/\.(mov)$/i)) {
                                            return (<>
                                                <div className='video-container rounded-lg'>
                                                    <video controls className='video-player'>
                                                        <source src={file.s3Link} type='video/quicktime' />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>
                                                <div class='video-info d-flex flex-row justify-content-center'>
                                                    <span class='text-muted'>size:</span> {localUtil.formatFileSize(file.size)}
                                                    <span class='text-muted ml-3 mr-1'>encoding:</span> {file.encoding}
                                                    <span class='text-muted ml-3 mr-1'>mimetype:</span> {file.mimetype}
                                                    <span class='text-muted ml-3 mr-1'>ext:</span> {file.ext}
                                                    <span class='text-muted ml-3 mr-1'>s3Link:</span> <a href={file.s3Link} target='_blank' rel='noopener noreferrer'>download</a>
                                                </div>
                                            </>);
                                        }
                                        return (<>
                                            <div class='d-flex flex-row justify-content-center'>
                                                <a href={file.s3Link} target='_blank' rel='noopener noreferrer'>{file.name} ({localUtil.formatFileSize(file.size)}) <i class="fa-duotone fa-download"></i></a>
                                                <a href={`${apiServer}/#/submittedapplication/video/${submittedApplication.uuidv4}/${file.md5}`} target='_blank' rel='noopener noreferrer' class='ml-3'>Open player <i class="fa-duotone fa-link"></i></a>
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </>}

                            {submittedApplication.tags && submittedApplication.tags.length > 0 && <div class={`d-flex flex-column mx-3 mt-4 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                {this.viewTags(submittedApplication)}
                            </div>}
                        </div>
                    </div>

                    <div class='w-100 d-flex flex-column'>
                        <div class={`font-weight-lighter px-3 mt-3 box-header`}>
                            <Text id='submittedApplication.admin'>Admin</Text>
                        </div>
                        <div class={`d-flex flex-column mx-3 mt-1 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                            <FormFields
                                stores={this.props.stores}
                                sections={sections}
                                fields={['adminStatus', 'adminComment', 'adminScore']}
                                skipSurroundingDivs={true}
                                sectionsNotCollapsed={true}
                                // isAdmin={true}
                                hideAdmin={false}
                                hideSections={hideSections}
                                hideFields={hideFields}
                                showRequiredFieldsInfo={true}
                                holdingStore={submittedApplicationStore}
                                objectName={'submittedApplication'}
                                isNew={false}
                                drawerLevel={drawerLevel}
                                hideFilters={true}
                            />
                        </div>
                    </div>





                    {/* <SimpleBlog stores={this.props.stores} {...this.props} /> */}
                </div>
            </div>
        </>);
    }
}

export default SubmittedApplicationViewAdmin;
