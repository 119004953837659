import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

import DyrejournalJournalListMini from '../../components/dyrejournal/listJournalMini';

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
    searchTitle: <Text id='input.search-for-visitor'>Search for visitor</Text>,
}))
@observer
class NewAnimal extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('animal'),
        };
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { animalStore } = this.props.stores;
        const { id, isNew } = props;
        animalStore.updateKeyValue('animal', {});
        if (!isNew) {
            await animalStore.load(id);
        }
        const { animal } = animalStore;
        await this.loadJournals(id);
        await this.loadSpecies();
        await this.loadBreeds(animal?.speciesId);
    }

    loadSpecies = async () => {
        const { animalStore } = this.props.stores;
        await animalStore.loadSpecies();
    }

    loadBreeds = async speciesId => {
        const { animalStore } = this.props.stores;
        await animalStore.loadBreeds(speciesId);
    }

    loadJournals = async animalIds => {
        const { journalStore, saleStore } = this.props.stores;
        journalStore.updateKeyValue('journals', []);
        if (animalIds) {
            const response = await journalStore.load({
                query: {
                    animal: animalIds,
                },
            });
        }
    }

    createAnimal = async () => {
        this.setState({
            loading: true,
        });
        const { callback = () => {} } = this.props;
        const { animalStore } = this.props.stores;
        const { newAnimal } = animalStore;
        const response = await animalStore.insert(newAnimal);
        const animal = response?.data?.animal;
        animalStore.updateKeyValue('newAnimal', {});
        if (response.status === 201) {
            callback(animal);
        }
        this.setState({
            loading: false,
        });
    }

    dyreIdGetChipInfo = async () => {
        const { animalStore } = this.props.stores;
        const { animal } = animalStore;
        const { chipId } = animal;
        const response = await animalStore.dyreIdGetChipInfo(chipId);
        const { data } = response;
        console.log({ data });
    }

    searchInput = (e) => {
        const search = e.target.value;
        this.setState({ search });

        clearTimeout(this.searchTimer);
        if (search.length >= 3) {
            this.searchTimer = setTimeout(async () => {
                this.doSearch(search);
            }, 300);
        }
    }

    async doSearch(search) {
        const { visitorStore } = this.props.stores;
        if (search) {
            // console.log('doUpdateField.saveField', id, field, value);
            const result = await visitorStore.searchVisitors({ search });
        } else {
            visitorStore.localUpdateField('searchResult', []);
        }
    }

    clearSearch = () => {
        this.setState({ search: '' });
        this.doSearch('');
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.doSearch(this.state.search);
        } else if (e.key === 'Escape') {
            e.preventDefault();
            this.clearSearch();
        }
    }

    changeOwnerOfAnimal = async e => {
        const { id } = e.target.closest('button').dataset;
        const { animalStore, visitorStore } = this.props.stores;
        const { animal } = animalStore;
        const { searchResult } = visitorStore;
        const visitor = searchResult.find(visitor => visitor.id === parseInt(id, 10));
        if (!confirm(`Change owner to ${localUtil.displayName(visitor)}?`)) {
            return;
        }
        // console.log('Changing owner of animal to visitor:')
        // console.log({ visitor })
        await animalStore.save({
            id: animal.id,
            owner: visitor.id,
        });

        this.setState({
            search: '',
            ownerChanged: true,
            newOwner: visitor,
        });
        // await conventionStore.newParticipant(visitor);
        await this.loadAll();
    }

    componentDidMount() {
        const { id, isNew, visitorId } = this.props;
        const { animalStore } = this.props.stores;
        if (isNew) {
            animalStore.updateObjectKeyValue('newAnimal', 'owner', visitorId);
        }
        this.loadAll();
    }

    render() {
        const {
            searchTitle,
        } = this.props;
        const {
            search,
            ownerChanged,
            newOwner,
        } = this.state;
        const { week, isNew, hideAdmin, drawerLevel, ownerName } = this.props;
        const { sections, loading } = this.state;
        const { userStore, animalStore, journalStore, visitorStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { searchResult } = visitorStore;
        const { newAnimal, animal } = animalStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { journals } = journalStore;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        {isNew ? <Text id='animal.new'>New animal</Text> : <Text id='animal.edit'>Edit animal</Text>}
                    </div>
{/* newAnimal:
<xmp>{JSON.stringify(newAnimal, null, 4)}</xmp> */}

                    {isNew ? <>
                        <div class={`mt-2 mx-4 font-weight-lighter px-3 py-1 text-muted`}>
                            <small class='text-uppercase'>
                                <Text id='form.owner'>Owner</Text>
                            </small>
                        </div>
                        <div class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-3 d-flex flex-wrap py-3 px-3 box-container`}>
                            {ownerName}
                        </div>
                    </> : <>
                        {/* <DyrejournalJournalListMini
                            classNames='mx-1'
                            stores={this.props.stores}
                            journals={journals}
                            drawerLevel={drawerLevel}
                            collapse={true}
                        /> */}
                    </>}

                    {/* {isAdmin && animal && animal.chipId && <>
                        <button
                            type='button'
                            class={`btn btn-info rounded-pill`}
                            onClick={this.dyreIdGetChipInfo}
                        >
                            Admin: <Text id='animal.getChipInfo'>Get chip info</Text>
                        </button>
                    </>} */}

                    {/* {isAdmin && <>
                        <button
                            type='button'
                            class={`btn btn-info rounded-pill`}
                            onClick={() => animalStore.dyreIdSearchOwnerPet('90169741')}
                        >
                            Admin: <Text id='animal.dyreIdSearchOwnerPet'>dyreIdSearchOwnerPet</Text>
                        </button>
                    </>} */}

                    <div class='bg-light d-flex flex-column justify-content-start pt-3'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sections}
                            holdingStore={animalStore}
                            objectName={isNew ? 'newAnimal' : 'animal'}
                            isNew={isNew}
                            drawerLevel={drawerLevel}
                            hideAdmin={hideAdmin}
                        />
                    </div>
                    {isNew ? <>
                        <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                            <button type='button' class='btn btn-primary rounded-pill' onClick={this.createAnimal} disabled={loading}>
                                {isNew ? <Text id='animal.create'>Create animal</Text> : <Text id='animal.save'>Save animal</Text>}
                                {loading && <i class='fa-solid fa-spinner-third fa-spin ml-2' />}
                            </button>
                        </div>
                    </> : <>
                        <div class={`mt-3 mx-4 font-weight-lighter px-3 py-1 text-muted`}>
                            <small class='text-uppercase'>
                                <Text id='form.change-owner'>Change Owner</Text>
                            </small>
                        </div>
                        <div class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-3 d-flex flex-wrap py-3 px-3 box-container`}>

                            {ownerChanged && newOwner ? <>
                                <div class='d-flex flex-wrap justify-content-center w-100'>
                                    <div class='alert alert-success rounded-lg w-100'>
                                        <Text id='animal.owner-changed'>Owner changed to</Text>: {localUtil.displayName(newOwner)}
                                    </div>
                                </div>
                            </> : <>
                                <div class='flex-fill input-group'>
                                    <div class='input-group-prepend'>
                                        <span class='input-group-text rounded-pill-left'>
                                            <i class='fa-regular fa-magnifying-glass'></i>
                                        </span>
                                    </div>
                                    <input
                                        class={`form-control ${!search ? 'rounded-pill-right' : ''}`}
                                        type='text'
                                        value={search}
                                        placeholder={`${searchTitle}`}
                                        onInput={this.searchInput}
                                        onKeyDown={this.handleKeyDown}
                                    />
                                    {search && <div class='input-group-append'>
                                        <button class='btn btn-danger rounded-pill-right' type='button' onClick={this.clearSearch}>
                                            <i class='fa-solid fa-circle-xmark'></i>
                                        </button>
                                    </div>}
                                </div>
                                {searchResult && searchResult.length ? <>
                                    <div class='d-flex flex-wrap justify-content-center'>
                                        {searchResult.map(user => {
                                            return (<>
                                                <button
                                                    class={`btn btn-sm btn-outline-primary rounded-pill mx-1 my-1`}
                                                    onClick={this.changeOwnerOfAnimal}
                                                    data-id={user.id}
                                                >
                                                    <i class='fa-duotone fa-plus' /> {localUtil.displayName(user)} {user.cellphone}
                                                </button>
                                            </>);
                                        })}
                                    </div>
                                </> : <></>}
                            </>}

                        </div>
                    </>}
                </div>
            </div>
        </>);
    }
}

export default NewAnimal;
