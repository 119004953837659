module.exports = {
    version: '1.0.0',
    debug: true,
    logLevel: 'debug', // debug, verbose, info
    name: 'Raskepoter', // Name of the app
    emailSender: 'post@raskepoter.app',
    development: {
        email: 'sorenso@gmail.com',
    },
    websocket: {
        port: 1333,
    },
    app: {
        port: 8888,
        logFile: '/tmp/access.log',
        protocol: 'http',
        domain: '0.0.0.0:8181',
    },
    jwt: {
        secret: 'This IS the JwT secret for use when signing tokens in your app! :)',
        secretSimpleBlog: 'This is the JWT secret',
        expiry: {
            loginToken: '2y',
            fingerprintToken: '1d',
        },
    },
    urls: {
        prod: {
            imageS3Bucket: 'raskepoter-images-original',
            imageS3BucketResized: 'raskepoter-images-resized',
            logFileS3Bucket: 'raskepoter-logs',
            imageServer: 'https://raskepoter.app',
            fileServer: 'https://raskepoter.app/original',
            logServer: 'https://raskepoter.app/logs',
            screenshotServer: 'https://test.raskepoter.app',

            videoS3Bucket: 'web-video-original',
            videoS3BucketStream: 'web-video-resized',
            videoServer: 'https://raskepoter.app',
        },
        dev: {
            imageS3Bucket: 'raskepoter-images-original',
            imageS3BucketResized: 'raskepoter-images-resized',
            logFileS3Bucket: 'raskepoter-logs',
            imageServer: 'https://raskepoter.app',
            fileServer: 'https://raskepoter.app/original',
            logServer: 'https://raskepoter.app/logs',
            screenshotServer: 'https://test.raskepoter.app',

            videoS3Bucket: 'web-video-original',
            videoS3BucketStream: 'web-video-resized',
            videoServer: 'https://raskepoter.app',
        },
    },
    links: {
        prod: {
            login: 'https://raskepoter.app/#/link/login/',
            verify: 'https://raskepoter.app/#/link/verify/',
            unlock: 'https://raskepoter.app/#/link/unlock/',
        },
        dev: {
            login: 'http://localhost:8080/#/link/login/',
            verify: 'http://localhost:8080/#/link/verify/',
            unlock: 'http://localhost:8080/#/link/unlock/',
        },
    },
    texts: {
        registerSubject: 'Welcome!',
        register: `Hi,

        Thank you so much for using signing up to The Raskepoter app.

        I hope you will be satisfied with the app and welcome your feedback anytime.

        Confirm you email by clicking this link:
        <a href="{{serverUrl}}{{verifyEmailToken}}">Confirm email: {{email}}</a>

        Best regards,
        Raskepoter`,

        loginLinkSubject: 'Magical loginlink to The Raskepoter app!',
        loginLink: `Hi,

        You have requested to reset you password.

        <h5>Please use this link to login:</>
        <a href="{{serverUrl}}{{loginToken}}">Login to The Raskepoter app</a>

        <strong>Or</strong> you can use this login code. It's only valid for 10 minutes:
        <h1 style='letter-spacing: 5px;'>{{loginCode}}</h1>

        If you haven't requested to reset you password, just delete this email and forget about it.

        Best regards,
        Raskepoter`,

        loginTokenSubject: 'Login token to The Raskepoter app!',
        loginToken: `Hi,

        You have requested a login token to Raskepoter.

        <strong>Login code</strong>. It's only valid for 10 minutes:
        <h1 style='letter-spacing: 5px;'>{{loginCode}}</h1>

        If you haven't requested this code, just delete this email and forget about it.

        Best regards,
        Raskepoter`,

        resendVerifySubject: 'Verify you email at Raskepoter',
        resendVerify: `Hi,

        You have requested a new email verification link. If this is not done by you no action
        is needed. Just delete this email and forget all about it.

        Please confirm your email address by clicking this link:
        <a href="{{serverUrl}}{{verifyEmailToken}}">Confirm my email address</a>

        Best regards,
        Raskepoter`,

        unlockSubject: 'Unlock you account at Raskepoter!',
        unlock: `Hi,

        You have requested an unlock link for you account. If this is not done by
        you no action is needed. Just delete this email and forget all about it.

        Please unlock your account by clicking this link:
        <a href="{{serverUrl}}{{verifyEmailToken}}">Unlock my account</a>

        Best regards,
        Raskepoter`,
        passwordChangeSubject: 'Your password has been changed at Raskepoter!',
        passwordChange: `Hi,

        Your password has been changed at Raskepoter.

        Timestamp: {{timestamp}}
        IP address: {{ip}}
        Useragent: {{useragent}}

        Best regards,
        Raskepoter`,
    },
    server: {
        protocol: 'http',
        domain: 'localhost',
        topDomain: 'localhost',
        port: 8888,
        logFile: '/tmp/access.log'
    },
    useDataDog: true,
    mongo: {
        dbName: 'raskepoter',
        url: 'mongodb://localhost:27017/raskepoter?safe=true&auto_reconnect=true&poolSize=20&reconnectTries=20&reconnectInterval=5000',
    },
    strava: {
        client_id: '54008',
        client_secret: '3714655d425dce76b1581da39fb57140ae2bf636',
        oauth_url: 'https://www.strava.com/oauth/authorize',
        oauth_token_exchange_server: 'www.strava.com',
        oauth_token_exchange_path: '/api/v3/oauth/token',
        redirect_uri: 'http://localhost:8888/api/strava/token-exchange',
        back_to_app: 'http://localhost:8080/#/users',
    },
    garmin: {
        client_key: '9fad447e-1e03-4e87-8982-02dce900e7ec',
        client_secret: '1aBuuKGGCwPBsOcv6eoloHb2ZNqeyspEkMp',
        // Step 1: get request token
        oauth_request_token_hostname: 'connectapi.garmin.com',
        oauth_request_token_path: '/oauth-service/oauth/request_token',
        redirect_uri: 'http://localhost:8888/api/garmin/token-exchange',
        // Step 2: Send user to Garmin to confirm.
        oauth_confirm_url: 'https://connect.garmin.com/oauthConfirm',
        // Step 3: Get access tokens.
        oauth_token_exchange_hostname: 'connectapi.garmin.com',
        oauth_token_exchange_path: '/oauth-service/oauth/access_token',
        // Step 4: Redirect back to app
        back_to_app: 'http://localhost:8080/#/users',
    },
    vipps: {
        login: {
            clientId: 'a360d43b-2d8c-4ee5-94ec-3c427c301d6e',
            clientSecret: 'y448Q~fHBY4Q22B3i2NPrkyh3mvZAiqli0pDrbYu',
            subscriptionKey1: '5ea8040f00f74c3ea9f66b2f60afade6',
            redirectUri: 'http://localhost:8888/api/users/vipps/tokenexchange',
            vippsAuthorizeUrl: 'https://apitest.vipps.no/access-management-1.0/access/oauth2/auth',
            vippsTokenUrl: 'https://apitest.vipps.no/access-management-1.0/access/oauth2/token',
            vippsUserInfoUrl: 'https://apitest.vipps.no/vipps-userinfo-api/userinfo',
        },
        // name: 'femundlopet',
        // version: '1.0.0',
        // plugin_name: 'racesignup',
        // plugin_version: '1.0.0',
        // merchant_serial_number: '307311',
        // client_id: '388701a6-c028-445a-966b-48b28c582d89',
        // client_secret: 'i4l8Q~uX5jy6hk6YzUSIjMNXLSwMNA3zvuSxebn9',
        // subscription_key_1: '1161f720b408456f9b15c79c752621a8',
        // subscription_key_2: '12692c58fdbe4d70a945bd848422fa79',
        // useTestMode: true,
    },
    paypal: {
        hostname: 'api-m.sandbox.paypal.com',
        clientId: 'AZEZb2oFErSwsV8MZQ2E1YN2i5khwsXKFgGepbLNs3CEJb54bpT5juuMwJmt6pejVOwLZafzVYaOFYAU',
        clientSecret: 'EK6OuOefYVhyw74JdLhqdJoRmoDn9J4WjicNDs4QRHcAe0o3E01uOayo58bl1xqPIPjxCm3-5YBaUSMO',
        returnUrl: 'http://localhost:8888/api/paypal/return',
    },
    openai: {
        OPENAI_API_KEY: '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',
    },
    dyreid: {
        url: 'https://services.staging.dyreidentitet.no/API.svc?wsdl',
        API_KEY: 'C666FAD5-B918-4586-A246-D0670633A2DA',
        API_SECRET: 'vdw6@9yn2',

        REST_SERVER: 'https://services.staging.dyreidentitet.no/API.svc',
    },
    google: {
        type: "service_account",
        project_id: "api-project-669437446558",
        private_key_id: "c9f0fba5c75e33f2672fcd2a57c500a346247637",
        private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDD9GIG/yhuPKQS\njzU90+8+QFn5ncw/xOfkjREMdI8W5Eg3tJAOEmIrJae/qwecEr2j1uXFTqyjUXXA\nmH9Ot6P7X52mVdYaYXACtqAydflqKawBoA+AuN2abgMwPwldybIEIBzMeRa33BT/\nlcYpo4hARc8b5mutnEGrxBziqlSgzbktpBAquqSDmDqdsqPRgMLeCH4/8gMvrPoM\nf1dof+1ihU532D/Hec+hhcs8DZ1Or13IlZ5Xjy7eJiAw3z7JxEM53lJ81H1ukZzt\n7tu+KjwslNqFiletRgRHRuU0IbUgJh5u4XLkdXcKUWKp9wFS7RKALSQx70ZcG8ez\nAVA2zp4xAgMBAAECggEASGM0+0SzGNYJ/lks3+Q00xhOpKmoVD5XM9Tw4VsFqP03\nuGxZcvlTjoDgkSuLKhq6N5YhV1wS5lonV7h9/wRT7X6K7HF1IFWyXS49y6ZNNuyi\nAA+y6mhgZLYTM3V17lGeE2wRd2+b9tu0Xh5kD+m7YZLoSXQ7fuzkTaGq0gj+LlBq\nDW0pJsxZ8ZrFb+Oz/ZsZK8UZlEfionpRs6GukHujXgMNGznZ6Wm0hVVvibv3ypkC\nMsWBGFFnufCj43rn13nFcN2wBA3oTpuMJfvBzpD3sEweMMofr7PChEziAWdBkO3a\nRNDlXeqLdoZQAjIB4mnnsjF1M/C5UzVV+BGgoTcbKQKBgQD/9OO9hB+bRTbLgRlM\nHE25XPnIxGb0+ayhmrAFM4xPZGMXbpXJjPzMKUOYYPRcoHdZZcwHYYC+JcVPlZct\n02FMmE7K6hrVksK1hyaJg+7j70hgfHZ9qbWlIuK6/UPqHJtt79fbZvc50OZu4V5x\nrax8CjjwpaSj8U3VtEDAfGDrEwKBgQDD/OOHVOTV9aTi1AGKnnl+uEs+AFbEa7U5\nkY9YcZ+5NtlTf7N3W2uWi4vZUqJMbtmfuO11ZpTGFOXnINMUw94O0WhE38pWfufE\nLGShYdAvAhB+uIU4+LiCP2drIvPXJqdwPZ0Oi+ViyyHAntlbzn5hwhjAgfjRykLG\n8Yntk06WKwKBgC0r3N1faeyPJIJ5wCsse5MRE4wZDUqCnbLPh+0DEeoY/1vtSkYN\nubeWOLW1tqp6L1X5ny5WSNBF/jJzZgDSY0OPRaiN9Dpo8i/zMBvoysozOoS8snSu\n9L22EGmLtOamMdAPGftAirULZv9+ipppWh1x+GaP9SRxu5jRfPP7f2c9AoGBAL7/\n7MnN3Tn7LFb2oA30xZjOiF2DPQwtn67cAaQDD9MT9hZe5IrUqHRNyNvhoVP1z9bO\nBXUdpK2qXieyoKOjAvzT7db5dhp3kO1pCiUQyy+UxniI4SSVmFMu8V3C8XpjLFjB\nYxdzFPkD1//9jvzer+Ibo72wrMnfYNa7PF0w6yyDAoGALsbBtBD+ssI2nqFnoNmC\nez5xp7Dkl5pSO207PZYVNweRHXC8AsjzCZhrsaPfAvqiIifTrEQwvdQlXNDhP3WO\nCyBVXsOxsapfNMRkgzD6nIezJt8kfU4tvytVqZOv8kPX5FYKt0BVGTn80IAbfjw1\ng4MUAkuj8t6qu/PWOZCUvJw=\n-----END PRIVATE KEY-----\n",
        client_email: "the-musher-v2@api-project-669437446558.iam.gserviceaccount.com",
        client_id: "102719918610154704032",
        auth_uri: "https://accounts.google.com/o/oauth2/auth",
        token_uri: "https://oauth2.googleapis.com/token",
        auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
        client_x509_cert_url: "https://www.googleapis.com/robot/v1/metadata/x509/the-musher-v2%40api-project-669437446558.iam.gserviceaccount.com",
    },
};
