import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class SalePrescription extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    // loadAll = async (props = this.props, emptyFirst = true) => {
    //     const { animalStore, visitorStore } = this.props.stores;
    //     const { prescription } = this.props;
    //     const { animal } = prescription;
    //     const currentAnimals = await animalStore.load({ query: { id: animal }, skipUpdate: true });
    //     const currentAnimal = currentAnimals[0];
    //     const currentVisitors = await visitorStore.load({ query: { id: currentAnimal.owner }, skipUpdate: true });
    //     const currentVisitor = currentVisitors[0];
    //     this.setState({ currentAnimal, currentVisitor });
    // }

    componentDidMount() {
        const { prescription } = this.props;
        // this.loadAll();
    }

    render() {
        const { week, isNew, drawerLevel, prescription, currentAnimal = {}, currentVisitor = {} } = this.props;
        const { sections } = this.state;
        const { customerStore, userStore, saleStore, journalStore } = this.props.stores;
        const { customer } = userStore;
        const { user, isAdmin } = userStore;

        const { veterinaries } = journalStore;

        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const {
            saleTotal = 0,
            saleTax = 0,
            newSale = {},
            animalJournals = [],
        } = saleStore;
        // console.log({ prescription, veterinaries })
        // const { journals = [] } = newSale;
        // const saleIsLocked = !!newSale.paymentMethod;
        // console.log({ animalJournals })
        // console.log(animalJournals[0].veterinaryName)
        let veterinaryName = '';
        let veterinaryHpr = '';
        let veterinaryCode = '';
        if (veterinaries && veterinaries.length > 0) {
            const vet = veterinaries.find(vet => vet.id === parseInt(prescription.veterinary, 10));
            if (vet) {
                veterinaryName = `${vet.firstname} ${vet.lastname}`;
                veterinaryHpr = `${vet.hprNumber}`;
                if (vet.code) {
                    veterinaryCode = `${vet.code}`;
                }
            }
        }
        if (veterinaryHpr === '' && animalJournals && animalJournals.length > 0) {
            veterinaryName = animalJournals[0].veterinaryName;
            veterinaryHpr = animalJournals[0].veterinaryHpr;
        }

        return (<>
            <div class='printPrescription'>
                <div class='printPrescription d-flex flex-column'>
                    <div class='d-flex flex-row justify-content-between'>
                        <div class='text-center d-flex align-items-center'>
                            {customer.images && customer.images.length > 0 && <img src={localUtil.displayImg(customer.images[0], this.props, '220x')} class='img-fluid' style='max-height: 100px;' />}
                        </div>
                        <div class='d-flex align-items-center'>
                            <h3 class='font-weight-lighter'>{customer.name}</h3>
                        </div>
                    </div>
                    <div class='d-flex flex-column'>
                        <div class='text-center d-flex align-items-end justify-content-end'>
                            <h5>
                                <Text id='dyrejournal.prescription-title'>Prescription</Text>
                            </h5>
                        </div>
                    </div>
                    <div class='d-flex flex-row justify-content-between mt-3'>
                        <div class='d-flex flex-column'>
                            <div class='d-flex flex-row'>
                                <div class='font-weight-light'>
                                    <Text id='dyrejournal.customer-name'>Customer</Text>:
                                </div>
                                <div class='d-flex flex-column ml-2'>
                                    <div>
                                        {currentVisitor.firstname} {currentVisitor.lastname}
                                    </div>
                                    <div>
                                        {currentVisitor.address}
                                    </div>
                                    <div>
                                        {fields.getNumberFixedWidth(currentVisitor.postalcode)} {currentVisitor.postalplace || currentVisitor.place}
                                    </div>
                                    <div>
                                        {currentVisitor.birth ? <>
                                            f.{util.isoDate(currentVisitor.birth, false, false, true)}
                                        </> : ''}
                                    </div>
                                </div>
                            </div>
                            {/* <div class='d-flex flex-row'>
                                <div class='font-weight-light'>
                                    <Text id='dyrejournal.customer-id'>Customer ID</Text>:
                                </div>
                                <div class='ml-2'>
                                    {newSale.visitor}
                                </div>
                            </div> */}
                        </div>
                        <div class='d-flex flex-column align-items-end'>
                            <div class='d-flex flex-row'><Text id='dyrejournal.date'>Date:</Text> {util.formatDate(prescription.date || newSale.saleDate, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}</div>
                            {/* <div class='d-flex flex-row'><Text id='dyrejournal.prescription-id'>Prescription ID:</Text> {prescription.id}</div> */}
                            <div class='d-flex flex-row'><Text id='dyrejournal.our-ref'>Our ref:</Text> {veterinaryName}, ID: {veterinaryHpr}</div>
                            {veterinaryCode ? <><div class='d-flex flex-row'><Text id='dyrejournal.our-ref-code'>Our ref code:</Text> {veterinaryCode}</div></> : ''}
                            <div class='d-flex flex-row'><Text id='dyrejournal.prescription-id'>Prescription-ID:</Text> {prescription.id}</div>
                        </div>
                    </div>

                    <div class='d-flex flex-column mt-5'>
                        <div class='d-flex flex-row justify-content-between'>
                            <div class=''>
                                <span>{currentVisitor.firstname} {currentVisitor.lastname}</span>
                                <span class='ml-1'><Text id='dyrejournal.owners'>'s</Text></span>
                                <span class='ml-1'>{currentAnimal.species} {currentAnimal.name}</span>:
                            </div>
                            {/* <div class={`d-flex flex-row justify-content-between px-3 py-1`}>
                                {prescription.date && <div class='flex-fill'>
                                    {util.formatDate(prescription.date, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                </div>}
                                {prescription.endDate && <div class='ml-2'>
                                    {util.formatDate(prescription.endDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                </div>}
                            </div> */}
                        </div>
                    </div>

                    <div class='d-flex flex-column mt-3'>
                        {prescription.drugs && prescription.drugs.length > 0 && <>
                            {prescription.drugs.map(drug => <>
                                <div class='d-flex flex-column mx-0 mt-2'>
                                    <div class={`d-flex flex-row justify-content-between px-3 py-1`}>
                                        <div class='text-muted'>
                                            <Text id='dyrejournal.prescription.drug'>Rp.</Text>
                                        </div>
                                        <div class='flex-fill d-flex flex-column ml-2'>
                                            <span class='font-weight-bold'>
                                                {drug.navnFormStyrke} ({drug.pakningsinfoMengde} {drug.pakningsinfoEnhetPakningV})
                                            </span><br />
                                            <small>
                                                {drug.bruksomradeEtikettText || drug.usageText}<br />
                                                {drug.diagnose || drug.diagnoseText}<br />
                                            </small>
                                            <small class='text-muted'>
                                                {drug.preparattypeDn && <>Type: {drug.preparattypeDn}</>}
                                                {drug.atcDn && <>, <span class='font-weight-lighter'>ATC:</span> {drug.atcDn}</>}
                                                {drug.reseptgruppeV && <>, <span class='font-weight-lighter'>Grp:</span> {drug.reseptgruppeV}</>}
                                                {drug.smakDn && <>, <span class='font-weight-lighter'>Smak:</span> {drug.smakDn}</>}
                                                {drug.administrasjonsveiDn && <>, <span class='font-weight-lighter'>Adm:</span> {drug.administrasjonsveiDn}</>}
                                            </small>
                                        </div>
                                    </div>
                                    <div class={`d-flex flex-row justify-content-between px-3 py-1`}>
                                        <div class='text-muted'>
                                            <Text id='dyrejournal.prescription.dose'>Dssn.</Text>
                                        </div>
                                        <div class='flex-fill ml-2'>
                                            {drug.dosage && <>
                                                {/* {drug.dosage} */}
                                                <Markdown markdown={drug.dosage} markedOpts={localUtil.getMarkdownOptions()} />
                                            </>}
                                            {drug.frequency && <>
                                                {/* {drug.frequency}<br /> */}
                                                Frekvens:
                                                <Markdown markdown={drug.frequency} markedOpts={localUtil.getMarkdownOptions()} />
                                            </>}
                                            <small class='text-muted'>
                                                {drug.forhandsregelInntakDn && <>
                                                    {/* Merk: {drug.forhandsregelInntakDn} */}
                                                    Merk:
                                                    <Markdown markdown={drug.forhandsregelInntakDn} markedOpts={localUtil.getMarkdownOptions()} />
                                                </>}
                                            </small>
                                            {/* {drug.additionalNotes && <>{drug.additionalNotes}</>} */}
                                            {drug.description && <>
                                                <small class='text-muted'>
                                                    {/* {drug.description} */}
                                                    <Markdown markdown={drug.description} markedOpts={localUtil.getMarkdownOptions()} />
                                                </small>
                                            </>}
                                            {drug.additionalNotes && <>
                                                <small class='text-muted'>
                                                    {/* {drug.description} */}
                                                    <Markdown markdown={drug.additionalNotes} markedOpts={localUtil.getMarkdownOptions()} />
                                                </small>
                                            </>}
                                        </div>
                                    </div>
                                    {drug.reit && <div class={`d-flex flex-row justify-content-between px-3 py-1`}>
                                        <div class='text-muted'>
                                            <Text id='dyrejournal.prescription.reit'>Reit</Text>
                                        </div>
                                        <div class='flex-fill ml-2'>
                                            {drug.reit}
                                        </div>
                                    </div>}

                                    {drug.reseptgyldighetVarighet && <div class={`d-flex flex-row justify-content-between px-3 py-1`}>
                                        <div class='text-muted font-weight-bold'>
                                            <Text id='dyrejournal.prescription-valid-until'>Valid Until</Text>:
                                        </div>
                                        <div class='flex-fill ml-2 font-weight-bold'>
                                            {util.formatDate(util.addSeconds(new Date(), util.parseISO8601ToSec(drug.reseptgyldighetVarighet)), { locale: 'nb-NO' })}
                                        </div>
                                    </div>}
                                    {/* {drug.preparatomtaleavsnittLenke && <div class={`d-flex flex-row justify-content-between px-3 py-1`}>
                                        <div class='text-muted'>
                                            <Text id='dyrejournal.prescription.about'>About</Text>
                                        </div>
                                        <div class='flex-fill ml-2'>
                                            <a href={drug.preparatomtaleavsnittLenke} native target='_blank'>{drug.preparatomtaleavsnittAvsnittoverskriftDn}</a>
                                        </div>
                                    </div>} */}
                                </div>
                            </>)}
                        </>}
                    </div>

                    <div class='row mt-5 pt-5'>
                        <div class='col-12 border-top'>
                            <Text id='dyrejournal.signature'>Signature</Text>: {veterinaryName}
                        </div>
                    </div>

                    <div class='d-flex flex-row justify-content-between mt-5'>
                        <div class='d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.business-register'>The Business Register</Text></div>
                                <div>{customer.orgNumber}</div>
                                <div>{customer.name}</div>
                                {customer.orgNumber2 && <div>{customer.orgNumber}</div>}
                                {customer.name2 && <div>{customer.name}</div>}
                            </small>
                        </div>
                        <div class='d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.address'>Address</Text></div>
                                <div>{customer.address}</div>
                                <div>{customer.postalcode} {customer.place}</div>
                            </small>
                        </div>
                        <div class='d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.phone-fax'>Phone/Fax</Text></div>
                                <div>{customer.phone}</div>
                                <div>{customer.fax}</div>
                            </small>
                        </div>
                        <div class='d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.internett'>Internet</Text></div>
                                <div>{customer.url}</div>
                                <div>{customer.email}</div>
                            </small>
                        </div>
                    </div>

                    {/* <div class='row'>
                        <div class='col-12 text-right font-weight-bold'>
                            <Text id='dyrejournal.total'>Total</Text>: {saleStore.sumTotal(newSale.products)}
                        </div>
                        <div class='col-12 text-right'>
                            <Text id='dyrejournal.of-which-vat'>Of which VAT</Text>: {saleStore.sumVat(newSale.products)}
                        </div>
                        <div class='col-12 text-right'>
                            <Text id='dyrejournal.paid-with'>Paid with</Text>: <i class={saleStore.getPaymentMethodIcon(newSale.paymentMethod)} /> {newSale.paymentMethod}
                        </div>
                        <div class='col-12 text-right'>
                            <Text id='dyrejournal.paid-date'>Paid date</Text>: {util.formatDate(newSale.paidDate, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}
                        </div>
                    </div> */}
                </div>

            </div>
        </>);
    }
}

export default SalePrescription;
