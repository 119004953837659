import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class RaceStore extends LocalModel {
    constructor() {
        super('race', {
            namePlural: 'races',
            namePluralReal: 'races',
            sort: 'startDate',
            limit: 500,
            api: {
                search: {
                    url: '/api/races/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/races/',
                    params: {},
                },
                save: {
                    url: '/api/races/',
                    params: {},
                },
                delete: {
                    url: '/api/races/',
                    params: {},
                },
            },
        });
    }

    @observable newRace = {};

    @observable race = {};

    @observable races = [];

    @observable myRaces = [];

    @observable raceClassList = [];

    @observable raceAdmins = [];

    @observable isRaceAdmin = false;

    @observable isMediaWorker = false;

    @observable isTimeTracker = false;

    @observable hasStarted = false;

    @action
    checkRace = () => {
        if (new Date(this.race?.startDate) < new Date()) {
            this.hasStarted = true;
        } else {
            this.hasStarted = false;
        }
    }

    @action
    checkRaceAdmin = (user) => {
        try {
            if (this.race?.raceAdmins?.length && user?.cellphone) {
                const foundUser = this.race.raceAdmins.find((ra) => ra.cellphone === user.cellphone);
                if (foundUser?.cellphone) {
                    this.isRaceAdmin = true;
                    return true;
                }
            }
            return false;
        } catch(e) {
            this.reportError(e);
            return false;
        }
    }

    @action
    checkMediaWorker = (user) => {
        try {
            if (this.race?.mediaWorkers?.length && user?.cellphone) {
                const foundUser = this.race.mediaWorkers.find((ra) => ra.cellphone === user.cellphone);
                if (foundUser?.cellphone) {
                    this.isMediaWorker = true;
                    return true;
                }
            }
            return false;
        } catch(e) {
            this.reportError(e);
            return false;
        }
    }

    @action
    checkTimetracker = (user) => {
        try {
            if (this.race?.timeTrackers?.length && user?.cellphone) {
                const foundUser = this.race.timeTrackers.find((ra) => ra.cellphone === user.cellphone);
                if (foundUser?.cellphone) {
                    this.isTimeTracker = true;
                    return true;
                }
            }
            return false;
        } catch(e) {
            this.reportError(e);
            return false;
        }
    }

    @action
    checkTdLeader = (user) => {
        try {
            if (this.race?.tdLeaders?.length && user?.cellphone) {
                const foundUser = this.race.tdLeaders.find((ra) => ra.cellphone === user.cellphone);
                if (foundUser?.cellphone) {
                    this.isTdLeader = true;
                    return true;
                }
            }
            return false;
        } catch(e) {
            this.reportError(e);
            return false;
        }
    }

    @action
    checkRaceLeader = (user) => {
        try {
            if (this.race?.raceLeaders?.length && user?.cellphone) {
                const foundUser = this.race.raceLeaders.find((ra) => ra.cellphone === user.cellphone);
                if (foundUser?.cellphone) {
                    this.isRaceLeader = true;
                    return true;
                }
            }
            return false;
        } catch(e) {
            this.reportError(e);
            return false;
        }
    }

    checkAllPositions = (user) => {
        this.checkRaceAdmin(user);
        this.checkMediaWorker(user);
        this.checkTimetracker(user);
        this.checkTdLeader(user);
        this.checkRaceLeader(user);
    }

    loadMyRaces = async (props = this.props) => {
        const response = await util.fetchApi(`/api/races/myraces`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.updateKeyValue('myRaces', response.data);
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async loadPublic(opt = { query: {} }, updateObject) {
        const response = await util.fetchApi(`/api/races/public`, {  method: 'GET' }, { ...opt.query, limit: opt.limit || 1000 });
        switch (response.status) {
            case 200:
                this.updateKeyValue('races', response.data);
                if (updateObject) {
                    this.updateKeyValue('race', response.data[0]);
                }
                return true;
                break;
            default:
                return false;
                break;
        }
    }
}

const store = new RaceStore();
export default store;
