import { h, render, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { IntlProvider, Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import Pagination from '../../components/gui/pagination';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';
import DyrejournalAllSalesPrint from '../../components/dyrejournal/allVisitsPrint';
import DyrejournalAllSalesTable from '../../components/dyrejournal/allVisitsTable';

const LIMIT = 100;

import definitionNo from '../../languages/no.json';
import definitionEn from '../../languages/en.json';

const countryMap = {
    no: definitionNo,
    en: definitionEn,
    default: definitionEn,
};

@withText(props => ({
    saleTitle: <Text id='dyrejournal.sale-title'>Search for sale</Text>,
    searchTitle: <Text id='dyrejournal.search-title'>Search for sale</Text>,
    deleteAreYouSure: <Text id='dyrejournal.sale-delete-are-you-sure'>Are you sure you want to delete this sale?</Text>,
}))
@observer
class AllVisits extends Component {
	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('journal'),
            currentPage: 1,
            formatting: true,
        };
        this.searchTimer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { currentPage, showDeleted, cutoffDate, cutoffDateEnd, activeStatus } = this.state;
        const { saleStore, journalStore } = this.props.stores;
        await saleStore.load({
            query: {
                showDeleted,
                cutoffDate,
                cutoffDateEnd,
                status: activeStatus,
            },
            offset: (currentPage - 1) * LIMIT,
            limit: LIMIT,
            addData: ['totalSales'],
        });
        await this.loadAllSalePayments();
    }

    loadJournals = async (animalIds, sale, journalIds) => {
        const { journalStore, saleStore } = this.props.stores;
        const response = await journalStore.load({
            query: {
                id: journalIds,
                // $or: [
                //     {
                //         animal: animalIds,
                //         sale,
                //     },
                //     {
                //         id: { $in: journalIds },
                //     },
                // ],
            },
            skipUpdate: true,
            addData: ['totalSales'],
        });
        saleStore.updateKeyValue('animalJournals', response);
    }

    loadAnimals = async visitorIds => {
        const { animalStore, saleStore } = this.props.stores;
        const response = await animalStore.load({
            query: {
                owner: visitorIds,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('visitorAnimals', response);

        await animalStore.load({
            query: {
                owner: visitorIds,
            },
        });
    }

    loadSales = async visitorIds => {
        if (!util.isArray(visitorIds) || visitorIds.length === 0) {
            return false;
        }
        const { saleStore } = this.props.stores;
        const response = await saleStore.load({
            query: {
                visitor: visitorIds,
                today: 1,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('currentVisitorSales', response);
    }

    loadSalePayments = async saleId => {
        if (!util.isNumber(saleId)) {
            return false;
        }
        const { salePaymentStore, saleStore } = this.props.stores;
        const response = await salePaymentStore.load({
            query: {
                sale: saleId,
            },
            skipUpdate: true,
            limit: 5000,
        });
        saleStore.updateKeyValue('currentPayments', response);
    }

    loadAllSalePayments = async () => {
        const { salePaymentStore, saleStore } = this.props.stores;
        const { sales } = saleStore;
        const saleIds = sales.map(e => e.id);
        if (saleIds.length === 0) {
            return false;
        }
        const response = await salePaymentStore.load({
            query: {
                sale: saleIds,
            },
            skipUpdate: true,
            limit: 5000,
        });
        saleStore.updateKeyValue('allPayments', response);
    }

    onClickPrintSales = async e => {
        const { activeStatus, formatting } = this.state;
        const { userStore } = this.props.stores;
        const { id } = e.target.closest('button').dataset;

        // console.log('onClickPrintSale', e);
        const { user } = userStore;
        const { language = 'no' } = user;
        const languageDef = countryMap[language || 'default'];

        // Render Receipt component with data
        const receipt = <IntlProvider definition={languageDef}>
            <DyrejournalAllSalesPrint
                stores={this.props.stores}
                activeStatus={activeStatus}
                formatting={formatting}
            />
        </IntlProvider>;

        // Append to document
        const printDiv = document.createElement('div');
        printDiv.className = 'receipt';
        document.body.appendChild(printDiv);
        render(receipt, printDiv);

        // Trigger print dialog
        window.print();

        // Remove the appended div
        document.body.removeChild(printDiv);
        // Re-render the receipt component to reset it
        render(null, printDiv);
    }

    onClickToggleFormatting = e => {
        const { formatting } = this.state;
        this.setState({ formatting: !formatting });
    }

    onClickDeleteVisit = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { deleteAreYouSure } = this.props;
        const { id } = e.target.closest('button').dataset;
        if (confirm(deleteAreYouSure)) {
            const { saleStore } = this.props.stores;
            const response = await saleStore.deleteSale(parseInt(id, 10));
            this.loadAll();
        }
    }

    onClickViewVisit = async e => {
        const { id } = e.target.closest('tr').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, saleStore } = this.props.stores;
        saleStore.createNewSale({ skipAnimalJournals: true });

        const newSale = await saleStore.load(parseInt(id, 10), false, { skipUpdate: true });
        if (id) {
            await this.loadSalePayments(parseInt(id, 10));
        }
        if (newSale[0].visitors) {
            const visitorIds = newSale[0].visitors.map(e => e.id);
            // console.log('onClickViewVisit', visitorIds)
            await this.loadAnimals(visitorIds);
            await this.loadSales(visitorIds);
        }

        if (newSale[0].animals) {
            const journalIds = newSale[0].journals.map(e => e.id);
            const animalIds = newSale[0].animals.map(e => e.id);
            await this.loadJournals(animalIds, newSale[0].id, journalIds);
        }
        saleStore.updateKeyValue('newSale', newSale[0]);

        appState.appContainerScrollTop();
        appState.toggleDrawerRight(false, drawerLevel);
    }

    searchInput = (e) => {
        const search = e.target.value;
        this.setState({ search });

        clearTimeout(this.searchTimer);
        if (search.length >= 3) {
            this.searchTimer = setTimeout(async () => {
                this.doSearch(search);
            }, 300);
        }
    }

    async doSearch(search) {
        const { currentPage, showDeleted, cutoffDate, cutoffDateEnd } = this.state;
        const { saleStore } = this.props.stores;
        if (search) {
            // console.log('doUpdateField.saveField', id, field, value);
            const result = await saleStore.load({
                query: {
                    search,
                    showDeleted,
                    cutoffDate,
                    cutoffDateEnd,
                },
                limit: LIMIT,
                skipUpdate: false,
                addData: ['totalSales'],
            });
            await this.loadAllSalePayments();
        }
    }

    onClickStatus = async e => {
        const { id } = e.target.closest('button').dataset;
        const idInt = parseInt(id, 10);
        const { search, activeStatus, cutoffDate, cutoffDateEnd } = this.state;
        this.setState({ activeStatus: activeStatus === idInt ? null : idInt });
        const { saleStore } = this.props.stores;
        if (activeStatus === idInt) {
            const result = await saleStore.load({ query: {
                search,
                cutoffDate,
                cutoffDateEnd,
            }, limit: LIMIT, skipUpdate: false, addData: ['totalSales'] });
        } else {
            const result = await saleStore.load({ query: {
                status: parseInt(id, 10),
                search,
                cutoffDate,
                cutoffDateEnd,
            }, limit: LIMIT, skipUpdate: false, addData: ['totalSales'] });
        }
        await this.loadAllSalePayments();
    }

    clearSearch = () => {
        this.setState({ search: '' }, () => {
            this.loadAll();
        });
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.doSearch(this.state.search);
        } else if (e.key === 'Escape') {
            e.preventDefault();
            this.clearSearch();
        }
    }

    setPage = (page) => {
        this.setState({
            currentPage: page,
        }, () => {
            this.loadAll();
        });
    }

    toggleShowDeleted = async e => {
        const { checked } = e.target;
        this.setState({
            showDeleted: checked,
        }, () => {
            this.loadAll();
        });
    }

    changeCutoffDate = async e => {
        const { value: cutoffDate } = e.target;
        this.setState({ cutoffDate }, () => {
            this.loadAll();
        });
    }

    changeCutoffDateEnd = async e => {
        const { value: cutoffDateEnd } = e.target;
        this.setState({ cutoffDateEnd }, () => {
            this.loadAll();
        });
    }

    onClickGoToToday = e => {
        this.setState({
            cutoffDate: util.isoDate(new Date(), false, false, true),
        }, () => {
            this.loadAll();
        });
    }

    clearCutoffDate = e => {
        this.setState({ cutoffDate: null }, () => {
            this.loadAll();
        });
    }

    clearCutoffDateEnd = e => {
        this.setState({ cutoffDateEnd: null }, () => {
            this.loadAll();
        });
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { search, activeStatus, currentPage = 0, cutoffDate, cutoffDateEnd, formatting = true } = this.state;
        const {
            saleTitle,
            searchTitle,
        } = this.props;
        const { userStore, saleStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { language = 'no' } = user;

        const { sales, allPayments, searchResults, totalSales } = saleStore;
        const finalSales = searchResults && searchResults.length > 0 ? searchResults : sales;

        const apiServer = util.getApiServer();
        const jwtToken = util.getJwtToken();

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='padding-top: 0px; padding-bottom: 100px;'>

                    <div class='w-100 d-flex flex-sm-row flex-column'>
                        <div class='w-100 d-flex flex-column'>
                            {/* <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.visits-today'>Visits today</Text>
                            </div> */}
                            <div class={`d-flex flex-column mx-3 px-2 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <div class='d-flex flex-row justify-content-between'>
                                    <div class='input-group'>
                                        <div class='input-group-prepend'>
                                            <span class='input-group-text rounded-pill-left'>
                                                <i class='fa-regular fa-magnifying-glass'></i>
                                            </span>
                                        </div>
                                        <input
                                            class={`form-control ${!search ? 'rounded-pill-right' : ''}`}
                                            type='text'
                                            value={search}
                                            placeholder={`${searchTitle}`}
                                            onInput={this.searchInput}
                                            onKeyDown={this.handleKeyDown}
                                        />
                                        {search && <div class='input-group-append'>
                                            <button class='btn btn-danger rounded-pill-right' type='button' onClick={this.clearSearch}>
                                                <i class='fa-solid fa-circle-xmark'></i>
                                            </button>
                                        </div>}
                                    </div>
                                    <div class='d-flex flex-row ml-2'>
                                        {[0, 1, 2, 3, 4, 5].map((e, idx) => {
                                            const isFirst = idx === 0;
                                            const isLast = idx === 5;
                                            const saleStatus = saleStore.getSaleStatusById(e);
                                            const saleStatusIcon = saleStore.getSaleStatusIcon(saleStatus);
                                            return (<button
                                                type='button'
                                                class={`btn btn-sm btn-${activeStatus === e ? 'primary' : 'outline-primary'} ${isFirst ? 'rounded-pill-left' : (isLast ? 'rounded-pill-right' : 'rounded-none')}`}
                                                onClick={this.onClickStatus}
                                                data-id={e}
                                            >
                                                <nobr>
                                                    <i class={saleStatusIcon} /> {util.ucfirst(saleStore.getSaleStatusById(e, language))}
                                                </nobr>
                                            </button>);
                                        })}

                                        <button
                                            type='button'
                                            class={`btn btn-sm btn-${activeStatus === 99 ? 'info' : 'outline-info'} rounded-pill ml-2`}
                                            onClick={this.onClickStatus}
                                            data-id={99}
                                        >
                                            <nobr>
                                                <i class='' /> <Text id='dyrejournal.debitor-list'>Debitor list</Text>
                                            </nobr>
                                        </button>

                                    </div>
                                </div>
                                <div class='d-flex flex-row justify-content-end mt-1'>
                                    <div class='input-group'>
                                        <div class='input-group-prepend'>
                                            <span class='input-group-text rounded-pill-left'>
                                                <i class='fa-regular fa-timer mr-2'></i> Fra
                                            </span>
                                        </div>
                                        <input
                                            class={`form-control rounded-${cutoffDate ? 'none' : 'pill-right'}`}
                                            type='date'
                                            onInput={this.changeCutoffDate}
                                            value={cutoffDate}
                                        />
                                        {cutoffDate && <div class='input-group-append'>
                                            <button class='btn btn btn-danger rounded-pill-right' type='button' onClick={this.clearCutoffDate}>
                                                <i class='fa-solid fa-circle-xmark'></i>
                                            </button>
                                        </div>}
                                    </div>
                                    <div class='input-group ml-2'>
                                        <div class='input-group-prepend'>
                                            <span class='input-group-text rounded-pill-left'>
                                                <i class='fa-regular fa-timer mr-2'></i> Til
                                            </span>
                                        </div>
                                        <input
                                            class={`form-control rounded-${cutoffDateEnd ? 'none' : 'pill-right'}`}
                                            type='date'
                                            onInput={this.changeCutoffDateEnd}
                                            value={cutoffDateEnd}
                                        />
                                        {cutoffDateEnd && <div class='input-group-append'>
                                            <button class='btn btn-sm btn-danger rounded-pill-right' type='button' onClick={this.clearCutoffDateEnd}>
                                                <i class='fa-solid fa-circle-xmark'></i>
                                            </button>
                                        </div>}
                                    </div>
                                    {/* <button
                                        type='button'
                                        class='btn btn-sm btn-outline-primary rounded-pill ml-2'
                                        onClick={this.onClickGoToToday}
                                    >
                                        <nobr><Text id='dyrejournal.goto-today'>Goto today</Text></nobr>
                                    </button> */}
                                    <button
                                        type='button'
                                        class='btn btn-sm btn-outline-primary rounded-pill ml-2'
                                        onClick={this.onClickPrintSales}
                                    >
                                        <nobr><i class='fa-duotone fa-print' /> <Text id='dyrejournal.print-sales'>Print sales</Text></nobr>
                                    </button>
                                    <button
                                        type='button'
                                        class={`btn btn-sm btn${formatting ? '' : '-outline'}-primary rounded-pill ml-2`}
                                        onClick={this.onClickToggleFormatting}
                                    >
                                        <nobr><i class='fa-duotone fa-list' /> <Text id='dyrejournal.toggle-formatting'>Formatting</Text></nobr>
                                    </button>
                                    <a
                                        href={`${apiServer}${saleStore.api?.load?.url}csv?jwtToken=${jwtToken}&status=${activeStatus || ''}&cutoffDate=${cutoffDate || ''}&cutoffDateEnd=${cutoffDateEnd || ''}&search=${search || ''}`}
                                        target='_blank'
                                        class={`btn btn-sm btn-outline-primary rounded-pill ml-2 pt-2`}
                                    >
                                        <nobr><i class='fa-duotone fa-download' /> <Text id='dyrejournal.download-csv'>Download CSV</Text></nobr>
                                    </a>
                                </div>
                                <div class='d-flex flex-row justify-content-end mt-1'>
                                    <div class='form-check form-check-inline'>
                                        <nobr>
                                            <input type='checkbox' class='form-check-input form-input-sm' id='showDeleted' onInput={this.toggleShowDeleted} />
                                            <label class='form-check-label' for='showDeleted'><Text id='dyrejournal.show-deleted'>Show deleted</Text></label>
                                        </nobr>
                                    </div>
                                </div>


                                {activeStatus === 2 && <>
                                    <div class='alert alert-secondary mt-2' role='alert'>
                                        <Text id='dyrejournal.all-unpaid'>Showing all unpaid sales. If you want to copy/paste into Excel. Remove the formatting with the "Formatting" button in the top right corner.</Text>
                                    </div>
                                </>}
                                {activeStatus === 0 && <>
                                    <div class='alert alert-secondary mt-2' role='alert'>
                                        <Text id='dyrejournal.all-inprogress'>Showing all sales in progress. No payment method chosen yet.</Text>
                                    </div>
                                </>}
                                {activeStatus === 1 && <>
                                    <div class='alert alert-secondary mt-2' role='alert'>
                                        <Text id='dyrejournal.all-paid'>Showing all paid sales.</Text>
                                    </div>
                                </>}
                                {activeStatus === 3 && <>
                                    <div class='alert alert-secondary mt-2' role='alert'>
                                        <Text id='dyrejournal.all-partial'>Showing all sales with payment method partial.</Text>
                                    </div>
                                </>}
                                {activeStatus === 4 && <>
                                    <div class='alert alert-secondary mt-2' role='alert'>
                                        <Text id='dyrejournal.all-invoice'>Showing all sales with payment method invoice.</Text>
                                    </div>
                                </>}
                                {activeStatus === 5 && <>
                                    <div class='alert alert-secondary mt-2' role='alert'>
                                        <Text id='dyrejournal.all-account'>Showing all sales with payment method account.</Text>
                                    </div>
                                </>}


                                <DyrejournalAllSalesTable
                                    stores={this.props.stores}
                                    formatting={formatting}
                                    showDelete={true}
                                    activeStatus={activeStatus}
                                    callback={() => this.loadAll()}
                                />

                            </div>
                        </div>
                        {/* <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.journals-today'>Journals today</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <DyrejournalSaleSearch
                                    stores={this.props.stores}
                                    key={'journals'}
                                    title={journalTitle}
                                    holdingStore={journalStore}
                                    searchResultList={'journals'}
                                    searchResultsLine={(e, idx, selectedIndex) => {
                                        return(<>
                                            <div class={`d-flex flex-row justify-content-between px-3 py-1 ${idx > 0 ? 'border-top' : ''} ${idx === selectedIndex ? 'bg-secondary' : ''}`}>
                                                <div>{e.id}</div>
                                                <div>{util.formatDate(e.saleDate, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}</div>
                                                <div class='flex-fill'>{e.title}</div>
                                                <div>{e.status}</div>
                                            </div>
                                        </>);
                                    }}
                                    callback={this.searchVisitorCallback}
                                />
                                <div class={`table-responsive ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                    <table class='table table-striped mb-0'>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th class='d-none d-sm-table-cell'><Text id='dyrejournal.id'>Id</Text></th>
                                                <th><Text id='dyrejournal.date'>Date</Text></th>
                                                <th><Text id='dyrejournal.title'>Title</Text></th>
                                                <th><Text id='dyrejournal.status'>Status</Text></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(journals && journals.length > 0) && journals.map(e => <>
                                                <tr>
                                                    <td onClick={this.onClickEditSale} data-id={e.id}><i class='fa-solid fa-pen' /></td>
                                                    <td class='d-none d-sm-table-cell'>{e.id}</td>
                                                    <td>
                                                        <nobr>{util.formatDate(e.saleDate, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}</nobr>
                                                    </td>
                                                    <td>{e.title || 'no title'}</td>
                                                    <td>{e.status}</td>
                                                </tr>
                                            </>)}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div> */}
                    </div>

                    {activeStatus === 99 ? <></> : <>
                        <Pagination total={totalSales} limit={LIMIT} current={currentPage} onPageChange={this.setPage} stores={this.props.stores} />
                    </>}

                </div>
            </div>
        );
    }
}

export default AllVisits;
