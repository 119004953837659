import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import GuiList from '../../components/gui/list';
import GuiListTicker from '../../components/gui/listTicker';
import GuiListLarge from '../../components/gui/listLarge';
import GuiListFrontpage from '../../components/gui/listFrontpage';

const MARKDOWN_OPTIONS = {
    pedantic: false,
    gfm: true,
    breaks: true,
    sanitize: false,
    smartLists: true,
    smartypants: true,
    xhtml: true,
highlight: function(code, lang) {
    const hljs = require('highlight.js');
    const language = hljs.getLanguage(lang) ? lang : 'plaintext';
    return hljs.highlight(code, { language }).value;
  },
langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
};

function displayImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

function mergeArrays(array1, array2) {
    const resultArray = array1.slice(); // Create a copy of array1 to avoid mutating the original

    for (let i = 0; i < array2.length; i++) {
        if (i < array1.length) {
            resultArray[i] = array2[i];
        } else {
            resultArray.push(array2[i]); // Add any additional items from array2 not present in array1
        }
    }

    return resultArray;
}

@withText(props => ({
    titleText: <Text id='article.title'>Article</Text>,
}))
@observer
class ArticleList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            limit: 50,
            offset: 0,
            localList: [],
        };
    }

    viewArticle = e => {
        const { view, showHeader } = this.props;
        if (view) {
            return view(e);
        }
        const { id, title } = e.target.closest('.box-line').dataset;
        const { appState, userStore } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMediumLarge } = appState;
        const { isAdmin } = userStore;

        // console.log('viewArticle', { id });
        appState.openDrawer('viewArticle', {
            id,
            title : showHeader ? title : undefined,
            height: drawerHeightMediumLarge,
            editFunction: isAdmin ? this.editArticle : undefined,
            // callback: () => {
            //     appState.toggleDrawer();
            //     // this.loadAll();
            // },
        }, drawerLevel + 1);
    }

    editArticle = e => {
        const { id, title } = e.target.closest('button').dataset;
        console.log('editArticle', { id, title });
		const { drawerLevel = 1 } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
		appState.openDrawer('editArticle', {
			height: drawerHeightMediumLarge,
			id,
		}, drawerLevel + 2);
	}

    onClickNewArticle = e => {
        const { drawerLevel = 1 } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
        const { category } = e.target.closest('button').dataset;

		appState.openDrawer('newArticle', {
			height: drawerHeightMediumLarge,
            isNew: true,
            category,
            callback: () => {
                this.loadAll(this.props, false, false);
            },
		}, drawerLevel + 2);
	}

    onClickViewAllArticles = e => {
        const { drawerLevel = 1 } = this.props;
		const { appState, contactsStore } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
        const { category } = e.target.closest('button').dataset;

		appState.openDrawer('adminListArticle', {
			height: drawerHeightMediumLarge,
            skipUpdate: true,
            category,
            callback: () => {
                this.loadAll(this.props, false, false);
            },
		}, drawerLevel + 2);
	}

    viewTags = obj => {
        if (obj.tags?.length === 0 && obj.loans?.length === 0 && !obj.ownedByOther && !obj.url && !obj.parents?.length > 0 && !obj.files?.length > 0) {
            return '';
        }
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        const tagClass = darkmode ? 'dark' : 'light';

        return (<>
            <div class='w-100 overflow-auto'>
                <div class='box-line-tags d-flex flex-nowrap'>
                    {obj.ownedByOther && <>
                        <span class={`badge badge-pill badge-danger mr-1 mt-1`}>
                            <span class='font-weight-lighter'>Owner:</span> {obj.ownerFirstname} {obj.ownerLastname}
                        </span>
                    </>}
                    {obj.parents?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-code-branch' />
                        </span>
                    </>}
                    {obj.url && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-link' />
                        </span>
                    </>}
                    {/* {obj.files?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-file' /> <span class='font-weight-lighter'> x {obj.files.length}</span>
                        </span>
                    </>} */}
                    {obj.tags?.length > 0 && obj.tags.slice(0,3).map(tag => {
                        return (<>
                            <span
                                class={`badge badge-pill badge-${tagClass} mr-1 mt-1`}
                                onClick={this.toggleTagFilter}
                                data-tag={tag.name}
                            >#{tag.name}</span>
                        </>);
                    })}
                    {obj.loans?.length > 0 && obj.loans.map(loan => {
                        if (loan.endDate) {
                            return '';
                        }
                        const endDate = loan.endDate ? new Date(loan.endDate) : new Date();
                        const {
                            seconds,
                            minutes,
                            hours,
                            days,
                            weeks,
                            months,
                            years,
                        } = util.dateDiff(loan.startDate, endDate);
                        // console.log('viewTags', obj.startDate, { obj, seconds, minutes, hours, days, weeks, months, years })

                        return (<>
                            <span class={`badge badge-pill badge-${loan.endDate ? 'success' : 'warning'} mr-1 mt-1`}>
                                Loaned out <span class='font-weight-lighter'>({days > 0 ? <>{days}d</> : <>{hours}h</>})</span>
                            </span>

                            {/* <div class='tag'>{JSON.stringify(loan)}</div> */}
                        </>);
                    })}
                </div>
            </div>
        </>);
    }

    onClickMenu = e => {
        const { id } = e.target.closest('.list-menu').dataset;
        const idInt = parseInt(id, 10);
        const { articleStore } = this.props.stores;
        const { articles } = articleStore;
        const article = articles.find(n => n.id === idInt);

        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightSmall } = appState;
        // console.log('viewPart', { id });
        appState.openDrawer('articleMenu', {
            id,
            height: drawerHeightSmall,
            article,
            // callback: () => {
            //     appState.toggleDrawer();
            //     // this.loadAll();
            // },
        }, drawerLevel + 1);
    }

    loadAll = async (props = this.props, append, emptyFirst = true) => {
        const { limit, offset } = this.state;
        const { raceId, tag, skipUpdate, limit: propsLimit, category } = props;
        const { articleStore, raceStore, userStore } = this.props.stores;
        const { isAdmin } = userStore;
        const { isRaceAdmin, isMediaWorker } = raceStore;
        const isWorkingWithMedia = isAdmin || isRaceAdmin || isMediaWorker;

        if (emptyFirst && !skipUpdate) {
            articleStore.updateKeyValue('articles', {});
        }

        clearTimeout(this.reloadTimer);
        const query = {
            hostname: articleStore.currentHostname,
        };
        if (category) {
            query.category = category;
        }
        const localList = await articleStore.load({
            query,
            limit: propsLimit || limit,
            offset,
            skipUpdate: skipUpdate,
        }, append);
// console.log('loadAll', { raceId, tag, skipUpdate, limit, propsLimit, offset, localList })
        if (localList && localList.length > 0) {
            this.setState({ localList });
            // articleStore.updateKeyValue('articlesAlt', localList);
        }

        if (!skipUpdate && !append && offset === 0) {
            this.reloadTimer = setTimeout(() => {
                this.loadAll(props, false, false);
            }, 1000 * 60);
        } else {
            clearTimeout(this.reloadTimer);
        }
    }

    setLoadMore = () => {
        const { skipUpdate } = this.props;
        if (skipUpdate) {
            return false;
        }
        const { articleStore } = this.props.stores;
        articleStore.setLoadMore(() => {
            // console.log('load more');
            clearTimeout(this.reloadTimer);
            let { limit, offset } = this.state;
            offset += limit;
            this.setState({ offset }, () => {
                this.loadAll(this.props, true, false);
            });
        });

        articleStore.setRefreshPage(() => {
            // console.log('refresh page');
            clearTimeout(this.reloadTimer);
            let { limit, offset } = this.state;
            offset = 0;
            this.setState({ offset }, () => {
                this.loadAll(this.props, false, false);
            });
        });
    }

    unsetLoadMore = () => {
        const { skipUpdate } = this.props;
        if (skipUpdate) {
            return false;
        }
        const { articleStore } = this.props.stores;
        articleStore.setLoadMore(() => {});
        articleStore.setRefreshPage(() => {});
    }

    componentDidMount() {
        this.loadAll();
        this.setLoadMore();
    }

    componentWillUnmount() {
        clearTimeout(this.reloadTimer);
        this.unsetLoadMore();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.category !== this.props.category || nextProps.offset !== this.props.offset) {
            if (nextProps.offset) {
                this.setState({ offset: nextProps.offset });
            }
            this.loadAll(nextProps);
        }
    }

    render() {
        const { appState, userStore, articleStore } = this.props.stores;
        const { isDevelopment, path, apiServer, currentCustomer = {} } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const {
            articleContainerClasses  = [],
            articleListClasses  = [],
            articleListImageWidth  = [],
            articleListImageLocations  = [],
        } = currentCustomer || {};

        const { articles } = articleStore;
        const { titleText, showHeader, skipUpdate, category } = this.props;
        const {
            classNames = '',
            hideEmpty,
            title = titleText,
            editElement,
            help,
            children,
            largeList,
            frontpageList,
            tickerList,
            articles: list,
            raceId,
            tag,
            label,
            labelBackgroundClass = 'bg-dark',
            labelClass = 'text-white',
        } = this.props;

        const { localList } = this.state;
        const finalList = skipUpdate ? localList : list || articles;

        if (tickerList) {
            return (<>
                <div class='d-flex flex-row w-100 position-relative'>
                    {label && finalList?.length > 0 && <>
                        <div
                            class={`${labelBackgroundClass} rounded position-absolute`}
                            style={`
                                bottom: 0;
                                left: 0;
                                width: 1.2rem !important;
                                min-width: 1.2rem !important;
                                height: calc(100% - 0.25rem) !important;
                                border-radius: 0.25rem 0 0 0.25rem !important;
                            `}
                        >
                            <div
                                class={`position-absolute ${labelClass} px-2`}
                                style={`
                                    bottom: -0.6rem;
                                    left: 0.3rem;
                                    font-size: 0.6rem;
                                    line-height: 0.6rem;
                                    transform: rotate(-90deg);
                                    transform-origin: top left;
                                    text-transform: uppercase;
                                `}>
                                {label}
                            </div>
                            &nbsp;
                        </div>
                    </>}
                    {isAdmin && <>
                        <div class='position-absolute' style='top: 10px; right: 10px; z-index: 1000;'>
                            <button
                                type='button'
                                class='btn btn-sm btn-primary rounded-pill mr-1'
                                data-category={category}
                                onClick={this.onClickViewAllArticles}
                            >
                                {/* <i class='fa-solid fa-list text-muted' /> <Text id='dyrejournal.product.all'>All Products</Text> */}
                                <i class='fa-solid fa-list' />
                            </button>
                            <button
                                type='button'
                                class='btn btn-sm btn-primary rounded-pill'
                                data-category={category}
                                onClick={this.onClickNewArticle}
                            >
                                {/* <i class='fa-solid fa-list text-muted' /> <Text id='dyrejournal.product.all'>All Products</Text> */}
                                <i class='fa-solid fa-plus' />
                            </button>
                        </div>
                    </>}
                    <GuiListTicker
                        key={`article-list-ticker-${tag}-${category}-${currentCustomer?.id}`}
                        classNames={classNames}
                        stores={this.props.stores}
                        holdingStore={articleStore}
                        view={this.viewArticle}
                        title={showHeader ? title : undefined}
                        list={finalList}
                        // list={[]}
                        elTitle={e => e.title}
                        elLink={e => `/article/${encodeURIComponent(e.title).toLocaleLowerCase()}/${e.uuidv4}`}
                        // elDescription={e => e.ingress}
                        elInfo1={e => util.formatDistance(e.publishDate, new Date(), { textAgo: 'siden' })}
                        elInfo2={e => ''}
                        elInfo3={e => ''}
                        hideEmpty={hideEmpty}
                        editElement={editElement}
                        help={help || children}
                        showFilterBox={false}
                        maxHeight={'60px'}
                        minHeight={'60px'}
                        imgHeight={'60px'}
                        viewTags={this.viewTags}
                        showEmptyImage={false}
                    />
                </div>
            </>);
        }

        if (frontpageList) {
            return (<>
                <div class='d-flex w-100 position-relative'>
                    {isAdmin && <>
                        <div class='position-absolute' style='top: 50px; right: 10px; z-index: 1000;'>
                            <button
                                type='button'
                                class='btn btn-sm btn-primary rounded-pill mr-1'
                                data-category={category}
                                onClick={this.onClickViewAllArticles}
                            >
                                {/* <i class='fa-solid fa-list text-muted' /> <Text id='dyrejournal.product.all'>All Products</Text> */}
                                <i class='fa-solid fa-list' />
                            </button>
                            <button
                                type='button'
                                class='btn btn-sm btn-primary rounded-pill'
                                data-category={category}
                                onClick={this.onClickNewArticle}
                            >
                                {/* <i class='fa-solid fa-list text-muted' /> <Text id='dyrejournal.product.all'>All Products</Text> */}
                                <i class='fa-solid fa-plus' />
                            </button>
                        </div>
                    </>}
                    <GuiListFrontpage
                        key={`article-list-large-${tag}-${category}-${currentCustomer?.id}`}
                        classNames={classNames}
                        stores={this.props.stores}
                        holdingStore={articleStore}
                        view={this.viewArticle}
                        title={showHeader ? title : undefined}
                        showPadding={false}
                        list={finalList}
                        // list={[]}
                        elHeader={e => e.heading ? <>
                            <span
                                class='mb-0 mt-0 py-1 px-3'
                                style={`font-size: clamp(0.7rem, 2vw, 1.2rem); font-weight: 600; line-height: 1.2em; text-transform: uppercase;`}
                            >
                                {e.headingIcon ? <i class={`${e.headingIcon} mr-2`} /> : ''}
                                {e.heading}
                            </span>
                        </> : <></>}
                        elTitle={e => <>
                            <span class='mb-0 mt-2 py-0'>{e.title}</span>
                        </>}
                        elLink={e => `/article/${encodeURIComponent(e.title).toLocaleLowerCase()}/${e.uuidv4}`}
                        elDescription={e => <>
                            {e.ingress && <div class='font-weight-normal mt-2'><Markdown markdown={e.ingress} markedOpts={MARKDOWN_OPTIONS} /></div>}
                            {/* {e.body && <div class='font-weight-normal mt-3 mb-2'><Markdown markdown={e.body} markedOpts={MARKDOWN_OPTIONS} /></div>} */}
                        </>}
                        elInfo1={e => ''}
                        elInfo2={e => ''}
                        elInfo3={e => ''}
                        hideEmpty={hideEmpty}
                        editElement={editElement}
                        help={help || children}
                        showFilterBox={false}
                        maxHeight={'40vh'}
                        // imageContainerStyles={'aspect-ratio: 4 / 3'}
                        imageStyles={'aspect-ratio: 4 / 3'}
                        // showMenu={this.onClickMenu}
                        viewTags={this.viewTags}
                        // loadMore={articleStore.loadMore}
                        listWrapperClassnames={[
                            'rounded-lg-md',
                            'rounded-md',
                            'rounded-md',
                            'rounded-md',
                            'rounded-md',
                            'rounded-md',
                            'rounded-md',
                            'rounded-md',
                            'rounded-md',
                            'rounded-md',
                        ]}
                        listContainerClassnames={mergeArrays([
                            'w-100',
                            'w-50 px-2',
                            'w-50 px-2',
                            'w-50 px-2',
                            'w-50 px-2',
                            'w-50 px-2',
                            'w-50 px-2',
                            'w-50 px-2',
                            'w-50 px-2',
                            'w-50 px-2',

                            'w-50 px-2',
                            'w-50 px-2',
                            'w-50 px-2',
                            'w-50 px-2',
                        ], articleContainerClasses.map(e => e.class))}
                        listClasses={mergeArrays([
                            'flex-column',
                            'flex-column',
                            'flex-column',
                            'flex-column',
                            'flex-column',
                            'flex-row',
                            'flex-row',
                            'flex-row',
                            'flex-row',
                            'flex-row',

                            'flex-row',
                            'flex-row',
                            'flex-row',
                            'flex-row',
                        ], articleListClasses.map(e => e.class))}
                        listStyles={[]}
                        listTitleStyles={[
                            'font-size: clamp(2rem, 4vw, 4rem);   font-weight: 700; line-height: 1.2em;',
                            'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                            'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                            'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                            'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                            'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                            'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                            'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                            'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                            'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',

                            'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                            'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                            'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                            'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        ]}
                        listMaxImageWidth={mergeArrays([
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                            '40%',
                            '40%',
                            '40%',
                            '40%',
                            '40%',

                            '40%',
                            '40%',
                            '40%',
                            '40%',
                        ], articleListImageWidth.map(e => e.width))}
                        listImageLocations={mergeArrays([
                            'top',
                            'top',
                            'top',
                            'top',
                            'top',
                            'left',
                            'right',
                            'right',
                            'left',
                            'right',

                            'left',
                            'right',
                            'left',
                            'right',
                        ], articleListImageLocations.map(e => e.location))}
                    />
                </div>
            </>);
        }

        return (<>
            <div class='d-flex w-100 position-relative'>
                {isAdmin && <>
                    <div class='position-absolute' style='top: 10px; right: 10px; z-index: 1000;'>
                        <button
                            type='button'
                            class='btn btn-sm btn-primary rounded-pill mr-1'
                            data-category={category}
                            onClick={this.onClickViewAllArticles}
                        >
                            {/* <i class='fa-solid fa-list text-muted' /> <Text id='dyrejournal.product.all'>All Products</Text> */}
                            <i class='fa-solid fa-list' />
                        </button>
                        <button
                            type='button'
                            class='btn btn-sm btn-primary rounded-pill'
                            data-category={category}
                            onClick={this.onClickNewArticle}
                        >
                            {/* <i class='fa-solid fa-list text-muted' /> <Text id='dyrejournal.product.all'>All Products</Text> */}
                            <i class='fa-solid fa-plus' />
                        </button>
                    </div>
                </>}
                <GuiList
                    key={`article-list-${tag}-${category}-${currentCustomer?.id}`}
                    classNames={classNames}
                    stores={this.props.stores}
                    holdingStore={articleStore}
                    view={this.viewArticle}
                    title={showHeader ? title : undefined}
                    list={finalList}
                    elTitle={e => e.title}
                    elLink={e => `/article/${encodeURIComponent(e.title).toLocaleLowerCase()}/${e.uuidv4}`}
                    elInfo1={e => util.isoDate(e.publishDate)}
                    elInfo2={e => ''}
                    elInfo3={e => ''}
                    hideEmpty={hideEmpty}
                    editElement={editElement}
                    help={help || children}
                    showFilterBox={false}
                    maxHeight={'90vh'}
                    viewTags={this.viewTags}
                />
            </div>
        </>);
    }
}

export default ArticleList;
