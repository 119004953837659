import { h, render, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { IntlProvider, Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';
import linkstate from 'linkstate';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalAnimalListMini from '../../components/dyrejournal/listAnimalMini';
import DyrejournalPrescriptionListMini from '../../components/dyrejournal/listPrescriptionMini';
import DyrejournalJournalPrint from '../../components/dyrejournal/journalPrint';
import DyrejournalSaleReceipt from '../../components/dyrejournal/saleReceipt';

import ImageScroller from '../../components/imagescroller';

import definitionNo from '../../languages/no.json';
import definitionEn from '../../languages/en.json';

const countryMap = {
    no: definitionNo,
    en: definitionEn,
    default: definitionEn,
};

@observer
class SendInvoice extends Component {
	constructor(props) {
        super(props);
        this.state = {
            to: '',
            subject: '',
            body: '',
        };
        this.searchTimer = null;
    }

    onClickEmailInvoice = async e => {
        const { newSale, callback = () => {} } = this.props;
        const { to, subject, body } = this.state;
        this.setState({
            invoiceSent: false,
            invoiceSending: true,
        });
        const { saleStore } = this.props.stores;
        const response = await saleStore.emailReminder({ sale: newSale, to, subject, body });
        callback(response);

        this.setState({
            invoiceSent: true,
            invoiceSending: false,
        });
    }

    render() {
        const { invoiceSent, invoiceSending } = this.state;
        const {
            newSale,
        } = this.props;
        const { appState, userStore, animalStore, saleStore } = this.props.stores;
        const { user, isAdmin, customer } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { visitors } = newSale;

        const dueDate = new Date(newSale.dueDate || newSale.saleDate || new Date());
        if (customer.invoiceDueDays > 0 && !newSale.dueDate) {
            dueDate.setDate(dueDate.getDate() + customer.invoiceDueDays);
        }

        const { days: daysOverDue } = util.dateDiff(dueDate, new Date());
        const subject = `${daysOverDue > 7 ? 'Purring!' : ''} Faktura for salg #${newSale.saleNumber || newSale.id} - ${util.isoDate(newSale.saleDate)}`

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class='font-weight-lighter px-3 box-header'>
                        <Text id='dyreinvoice.invoice-send'>Send Invoice</Text>
                    </div>

                    <div class={`w-100 px-3 py-2 mt-2 d-flex flex-column`}>
                        <div class='form'>
                            <label class='sr-only'>Email</label>
                            <div class='input-group mb-2 mr-sm-2'>
                                <div class='input-group-prepend'>
                                    <div class='input-group-text rounded-pill-left'>@</div>
                                </div>
                                <input type='text' class='form-control rounded-pill-right' placeholder={visitors.map(v => v.email)} onInput={linkstate(this, 'to')} />
                            </div>
                        </div>
                        <div class='form'>
                            <label class='sr-only'>Subject</label>
                            <div class='input-group mb-2 mr-sm-2'>
                                <div class='input-group-prepend'>
                                    <div class='input-group-text rounded-pill-left'>S</div>
                                </div>
                                <input type='text' class='form-control rounded-pill-right' placeholder={subject} onInput={linkstate(this, 'subject')} />
                            </div>
                        </div>
                        <div class='form'>
                            <label class='sr-only'>Body</label>
                            <div class='input-group mb-2 mr-sm-2'>
                                <div class='input-group-prepend'>
                                    <div class='input-group-text rounded-pill-left'>B</div>
                                </div>
                                <textarea type='text' class='form-control rounded-pill-right' placeholder='Body' onInput={linkstate(this, 'body')} />
                            </div>
                        </div>

                        <button
                            type='button'
                            class={`btn ${invoiceSent ? 'btn-success' : 'btn-primary'} mb-2 rounded-pill`}
                            onClick={this.onClickEmailInvoice}
                            disabled={invoiceSending}
                        >
                            {invoiceSending ? <>
                                <i class='fa-solid fa-spinner-third fa-spin' /> <Text id='dyreinvoice.email-invoice-sending'>Sending email...</Text>
                            </> : <>
                                {invoiceSent ? <>
                                    <i class='fa-solid fa-check' /> <Text id='dyreinvoice.invoice-sent'>Invoice sent</Text>
                                </> : <>
                                    <i class='fa-solid fa-paper-plane' /> <Text id='dyreinvoice.email-invoice-to-address'>Send Email</Text>
                                </>}
                            </>}
                        </button>
                    </div>

                    <div class='font-weight-lighter px-3 box-header'>
                        <Text id='dyreinvoice.invoice-preview'>Invoice preview</Text>
                    </div>
                    <div class={`d-flex flex-column mx-3 px-5 py-5 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                        <div class={`d-flex flex-column px-5 py-0 box-container`}>
                            <DyrejournalSaleReceipt stores={this.props.stores} />
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default SendInvoice;
